// get reverse route from current state
export const reverse_route = {
  '/': 'home',
  '/code': 'scan_code',
  '/me': 'me'
}

const currentPage = (state = {current: reverse_route[window.location.pathname]}, action) => {
  switch (action.type) {
    case 'EDIT_CURRENT_PAGE':
      return {
          ...state,
          current: action.current
      }
    default:
      return state
  }
}

export default currentPage
