import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	cartPreviewDrawerTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
}));


export default function Wallet(props: any) {
    const classes = useStyles();
    const cart = useSelector(state => state.cart);

    return (
        <div className={classes.cartPreviewDrawerTitle}>
            <Typography variant="subtitle2">
                Portafoglio Foorban
            </Typography>
            <Typography variant="subtitle2">
                - {cart.totalToPayFromWallet} €
            </Typography>
		</div>
    );
}