import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DataProvider from '../../provider';
import { CoffeeSellingAreaConfig, CoffeeConfig, CoffeeConfirmBody, CoffeeSellingOrder } from './interfaces';
import Funnel from '../../templates/contents/Funnel';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import InternalPayments from '../../components/InternalPayments';

const StatusList = [
    'FETCH_DATA',
    'FETCHING_DATA',
    'ERROR_FETCHING_DATA',
    'FETCHED',
    'SEND_DATA',
    'SENDING_DATA',
    'ERROR_SENDING_DATA',
    'DATA_SENDED',
] as const;

type Status = typeof StatusList[number];

const LoadingStatus: Status[] = [
    'FETCH_DATA',
    'FETCHING_DATA',
    'SEND_DATA',
    'SENDING_DATA',
];

const useStyles = makeStyles((theme: Theme) => ({
    credit: {
        width: '100%',
		borderRadius: 0,
		textTransform: 'none',
		padding: theme.spacing(2, 0),
		backgroundColor: 'rgb(8, 109, 64, 0.2)'
	},
	creditTitle: {
		color: 'rgba(0, 0, 0, 0.54)',
		width: '100%',
	},
    textError: {
        padding: theme.spacing(2, 0),
    },
    row1: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        margin: theme.spacing(1, 0),
    },
    cover: {
        width: 86,
        height: 89,
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        margin: theme.spacing(0, 0, 2),
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    row2Button: {
        width: '100%',
        margin: theme.spacing(2),
    },
    cardContent1: {
        display: 'flex',
        flex: 1
    },
    cardContentLeft: {
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    cardContentRight: {
        display: 'flex',
        flex: 2,
        width: '70%',
        flexFlow: 'column',
        justifyContent: 'center'
    },
    price: {
        fontWeight: 600
    }
}));

export default function SellingArea(props: any) {
    const history = useHistory();
    const [status, setStatus] = useState<Status>('FETCH_DATA');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [configs, setConfigs] = useState<CoffeeConfig>();
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const classes = useStyles();

    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        switch (status) {
            case 'FETCH_DATA':
                setStatus('FETCHING_DATA');
                dataProvider(`b2c/coffee/selling-area/${id}/configs`)
                    .then((config: CoffeeSellingAreaConfig) => {
                        if (config.error === true) {
                            setStatus('ERROR_FETCHING_DATA');
                            setErrorMessage(config.message || '');
                            return;
                        }

                        if (config && config.configs && config.configs.shop.status === 'OPEN' && !config.configs.nickName) {
                            history.push('/coffee/collect-nick-name');
                            return;
                        }
                        if(config.configs?.catalog?.recipes) {
                            config.configs.catalog.recipes = config.configs?.catalog?.recipes.sort((a, b) => a.displayOrder - b.displayOrder);
                        }
                        setConfigs(config.configs)
                        setStatus('FETCHED');
                    })
                    .catch(e => {
                        setStatus('ERROR_FETCHING_DATA')
                    })
                break;
        }
        return () => { }
    }, [dataProvider, id, status, history])

    let title = 'Caricamento dati...';

    if (['FETCHED', 'SENDING_DATA', 'ERROR_SENDING_DATA', 'DATA_SENDED'].includes(status)) {
        title = configs?.shop.title || '';
    } else if (status === 'ERROR_FETCHING_DATA') {
        title = 'Errore caricamento dati';
    }

    function dispense(request: {
        coffeeRecipeId: string;
        price: number;
    }) {
        let totalToPay = request.price;
        let totalToPayFromWallet = 0;
        let totalToPayFromCorporateCredit = 0;

        if (totalToPay > 0 && configs?.corporateCreditWallet && configs?.corporateCreditWallet?.balance > 0 && (localStorage.getItem('corporateCreditToggle') ? (new Date()).getTime() - parseInt(localStorage.getItem('corporateCreditToggle') || '0') > 43200000 : true)) {
            totalToPayFromCorporateCredit = configs?.corporateCreditWallet?.balance > totalToPay ? totalToPay : configs?.corporateCreditWallet?.balance;
            totalToPay = parseFloat((totalToPay - totalToPayFromCorporateCredit).toFixed(2));
        }
        if (totalToPay > 0 && configs?.wallet && configs?.wallet?.balance > 0) {
            totalToPayFromWallet = configs?.wallet?.balance > totalToPay ? totalToPay : configs?.wallet?.balance;
            totalToPay = parseFloat((totalToPay - totalToPayFromWallet).toFixed(2));
        }

        if(totalToPay > 0) {
            history.push(`/coffee/insufficient-fund?return-url=${document.location.pathname}`);
            return;
        }

        setStatus('SENDING_DATA');
        const body: CoffeeConfirmBody = {
            paymentInfo: {
                totalToPayFromWallet,
                totalToPayFromCorporateCredit,
            },
            order: {
                coffeeRecipeId: request.coffeeRecipeId,
                price: request.price,
            }
        }
        dataProvider(`b2c/coffee/selling-area/${id}/confirm`, {
            method: 'POST',
            body: JSON.stringify(body),
        })
        .then((response: {
            error: true;
            message: string;
          }
        | {
            error: false;
            order: CoffeeSellingOrder;
          }) => {
              if(response.error) {
                setStatus('ERROR_SENDING_DATA')
                setErrorMessage(response.message);
              } else {
                  history.push(`/coffee/selling-order/${response.order.id}`);
              }
          })
        .catch(e => {
            setStatus('ERROR_SENDING_DATA')
            setErrorMessage("Errore durante l'invio dei dati.");
        })
    }

    return (
        <Funnel rightAction={{
			icon:'person',
			onClick: () => history.push('/me')
		}} title={title} loading={LoadingStatus.includes(status)}>
            {status === 'FETCHED' ? <InternalPayments
                showToggleWallet={false}
                showToggleCorporateCredit={true}
                corporateCreditBalance={configs && configs.corporateCreditWallet && configs.corporateCreditWallet.balance ? configs.corporateCreditWallet.balance : 0}
                walletBalance={configs && configs.wallet && configs.wallet.balance ? configs.wallet.balance : 0} />
            : null}
            <Container maxWidth="xs">
                {status === 'ERROR_FETCHING_DATA' &&
                    <div>
                        <Typography className={classes.textError} align="center" variant="subtitle1">{errorMessage || "Errore durante il caricamento dei dati."}</Typography>
                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                            setStatus('FETCH_DATA');
                            setErrorMessage('');
                        }}>ricarica</Button>
                    </div>
                }
                {status === 'ERROR_SENDING_DATA' &&
                    <div>
                        <Typography className={classes.textError} align="center" variant="subtitle1">{errorMessage || "Errore durante l'invio dei dati."}</Typography>
                        <Button fullWidth variant="contained" color="primary" onClick={() => {
                            setStatus('FETCH_DATA');
                            setErrorMessage('');
                        }}>riprova</Button>
                    </div>
                }
                {status === 'FETCHED' && configs?.shop.status === 'OPEN' && <>
                    <div className={classes.row1}>
                        <Typography variant="h6">Ciao, {configs?.nickName}</Typography>
                        <Button size="large" variant="text" color="primary" onClick={() => history.push('/coffee/collect-nick-name')}>modifica</Button>
                    </div>
                    {configs?.catalog?.recipes.map(recipe => {
                        return (
                            <Card key={recipe.recipeId} className={classes.card}>
                                <div className={classes.cardContent1}>
                                    <div className={classes.cardContentLeft}>
                                        <CardMedia
                                            className={classes.cover}
                                            image={recipe.itemSaleable.photo}
                                            title="Live from space album cover"
                                        />
                                    </div>
                                    <div className={classes.cardContentRight}>
                                        <Typography variant="subtitle1">{recipe.itemSaleable.name}</Typography>
                                        <Typography variant="h6" className={classes.price}>{recipe.price.label}</Typography>
                                    </div>
                                </div>
                                <div className={classes.row2}>
                                    <Button className={classes.row2Button} size="large" variant="contained" color="primary" onClick={() => dispense({
                                        coffeeRecipeId: recipe.recipeId,
                                        price: recipe.price.value,
                                    })}>eroga</Button>
                                </div>
                            </Card>
                        );
                    })}
                </>}
                {status === 'FETCHED' && configs?.shop.status === 'CLOSE' && <>
                    <Typography className={classes.textError} variant="subtitle1" align="center" noWrap>{configs?.shop.closePhrease}</Typography>
                </>}
            </Container>
        </Funnel>
    );
}