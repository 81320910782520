import { UPDATE_CART, UPDATE_CART_ITEM, EMPTY_CART, UPDATE_CART_TOTAL } from '../';

interface CartState {
	totalToPayFromWallet?: number;
	totalToPayFromCorporateCredit?: number;
	totalToPay?: number;
	totalWithTax: number;
	actionType: string;
	paymentUUID?: string;
    items: Array<CartStateItem>;
}

interface CartStateItem {
    id: number;
    itemSaleableId: number;
    label: string;
    quantity: number;
    totalWithTax: number;
    unitPriceWithTax: number;
}

function calculateTotal(request: {
	corporateCreditBalance: number;
	walletBalance: number;
	totalWithTax: number
}): {
	totalToPayFromCorporateCredit: number;
	totalToPayFromWallet: number;
	totalToPay: number;
} {

	const response = {
		totalToPayFromCorporateCredit: 0,
		totalToPayFromWallet: 0,
		totalToPay: request.totalWithTax,
	};

	if (request.corporateCreditBalance > 0 && (localStorage.getItem('corporateCreditToggle') ? (new Date()).getTime() - parseInt(localStorage.getItem('corporateCreditToggle') || '0') > 43200000 : true)) {
		response.totalToPayFromCorporateCredit = request.corporateCreditBalance > request.totalWithTax ? request.totalWithTax : request.corporateCreditBalance
		response.totalToPay = Math.max(parseFloat(((request.totalWithTax * 100 - request.corporateCreditBalance * 100) / 100).toFixed(2)), 0)
	}

	if(response.totalToPay > 0 && request.walletBalance > 0 && (localStorage.getItem('walletToggle') ? (new Date()).getTime() - parseInt(localStorage.getItem('walletToggle') || '0') > 43200000 : true)) {
		response.totalToPayFromWallet = request.walletBalance > response.totalToPay ? response.totalToPay : request.walletBalance;
		response.totalToPay = Math.max(parseFloat(((response.totalToPay * 100 - request.walletBalance * 100) / 100).toFixed(2)), 0)
	}

	return response;
}

export default function(state: null | CartState = null, action) {
	switch (action.type) {
	case UPDATE_CART_TOTAL: 
		if(!state) {
			return state;
		}
		const updateCartTotalWalletCalc = calculateTotal({
			walletBalance: action.walletBalance,
			totalWithTax: state.totalWithTax,
			corporateCreditBalance: action.corporateCreditBalance
		})

		state.totalToPayFromCorporateCredit = updateCartTotalWalletCalc.totalToPayFromCorporateCredit;
		state.totalToPayFromWallet = updateCartTotalWalletCalc.totalToPayFromWallet;
		state.totalToPay = updateCartTotalWalletCalc.totalToPay;
		return state;
	case EMPTY_CART: 
		return null;
	case UPDATE_CART:
		if(!action.cart || (action.cart && action.cart.lockerSellingOrder && action.cart.lockerSellingOrder.items && action.cart.lockerSellingOrder.items.length === 0)) {
			return null;
		}

		const walletCalc = calculateTotal({
			walletBalance: action.walletBalance,
			totalWithTax: action.cart.lockerSellingOrder.totalWithTax,
			corporateCreditBalance: action.corporateCreditBalance
		})

		if(action.cart.lockerSellingOrder.items && action.cart.lockerSellingOrder.items.length > 0) {
			return {
				...action.cart.lockerSellingOrder,
				actionType: action.cart.actionType,
				paymentUUID: action.cart.paymentUUID,
				...walletCalc
			}
		}
		return state;
	case UPDATE_CART_ITEM:
		if(!state || (state && state.items.length === 0)) {
			const totalWithTax = parseFloat((action.request.localPrice * action.request.quantity).toFixed(2));
			const walletCalc = calculateTotal({
				totalWithTax: totalWithTax,
				walletBalance: action.request.walletBalance,
				corporateCreditBalance: action.request.corporateCreditBalance,
			})

			return {
				totalWithTax,
				...walletCalc,
				items: [{
					id: Math.random(),
					itemSaleableId: action.request.itemSaleableId,
					label: action.request.localLabel,
					quantity: action.request.quantity,
					totalWithTax,
					unitPriceWithTax: action.request.localPrice
				}]
			}
		}
		else if(state && state.items.length > 0) {
			let items: Array<CartStateItem> = [];

			if(action.request.quantity === 0) {
				items = state.items.filter(item => {
					return item.itemSaleableId !== action.request.itemSaleableId
				});
			} else {
				var search = state.items.filter(item => {
					return item.itemSaleableId === action.request.itemSaleableId;
				});
				
				if(search.length > 0) {
					items = state.items.map(item => {
						if(item.itemSaleableId === action.request.itemSaleableId)
							return {
								...item,
								quantity: action.request.quantity
							}
						return item;
					});
				}
				else {
					items = state.items;
					items.push({
						id: Math.random(),
						itemSaleableId: action.request.itemSaleableId,
						label: action.request.localLabel,
						quantity: action.request.quantity,
						totalWithTax: parseFloat((action.request.localPrice * action.request.quantity).toFixed(2)),
						unitPriceWithTax: action.request.localPrice
					});
				}
			}

			if(items.length === 0) {
				return null;
			}
			else  {
				let totalWithTax = 0;
				items.forEach(item => {
					totalWithTax += parseFloat((item.quantity * item.unitPriceWithTax).toFixed(2))
				});
				totalWithTax = parseFloat(totalWithTax.toFixed(2));
				const walletCalc = calculateTotal({
					totalWithTax:  totalWithTax,
					walletBalance: action.request.walletBalance,
					corporateCreditBalance: action.request.corporateCreditBalance
				})

				return {
					totalWithTax,
					...walletCalc,
					items
				}
			}
		}
		else {
			return state;
		}
	default:
		return state
	}
}
