import { UPDATE_ACTIONS } from '../';

const actions = (state = null, action) => {
	switch (action.type) {
	case UPDATE_ACTIONS:
		return action.actions;
	default:
		return state
	}
}

export default actions;