import React from 'react';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentPreferencesState } from '../state/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        //justifyContent: 'center',
        alignItems: 'center'
    }
}));
export default function PaymentInstrument(props: any) {
    const classes = useStyles();
    let history = useHistory();
    const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);

    if(paymentPreferences.status === 'FETCHED' && paymentPreferences.definedAmountMethod && paymentPreferences.definedAmountMethod.type === 'pi') {
        return (
            <div style={{margin: '4px 0 4px'}}>
                <div className={classes.row}>
                    <img alt={paymentPreferences.definedAmountMethod.paymentInstrument.provider} style={{width: '40px', marginRight: '8px'}} src={`https://images.foorban.business/payment/${paymentPreferences.definedAmountMethod.paymentInstrument.provider.toLowerCase()}.svg`}></img>
                    <Typography>{paymentPreferences.definedAmountMethod.paymentInstrument.label}</Typography>
                </div>
                
                {!props.isPreview && <Button
                    onClick={() => {
                        const params = new URLSearchParams();
                        params.set('key-to-update', 'SELLING_AREA_PICKED_PI');
                        params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));

                        history.push(`/payment/payment-options/definedAmountMethod?${params.toString()}`, {
                            key_to_update: 'SELLING_AREA_PICKED_PI',
                        });
                    }}
                    fullWidth={true}
                    size="small"
                    color="primary"
                >
                    cambia metodo di pagamento
                </Button>}
        </div>)
    }

    return null;
}