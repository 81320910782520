import React, { useEffect, useState } from 'react';
import Modal from '../../../../templates/contents/Modal';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import DataProvider from '../../../../provider';
import { useHistory, useParams } from 'react-router-dom';
import { ClientToken } from 'braintree';
import { client, dataCollector } from 'braintree-web';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { loadScript } from '@paypal/paypal-js';
import Button from '@material-ui/core/Button';
import { pushTrackingEvent } from '../../../../utils/gtmDataLayer';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4, 0),
    },
    errorButton: {
      margin: theme.spacing(0, 0, 1),
    },
    errorButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      margin: theme.spacing(4, 0, 0),
    }
}));

export default (props) => {
    let history = useHistory();
    const classes = useStyles();

    const [deviceData, setDeviceData] = useState<string>();
    const [clientToken, setClientToken] = useState<ClientToken | null>(null);
    const [payPalScript, setPayPalScript] = useState<any | null>(null);
    const [status, setStatus] = useState<
      'INITIAL' |
      'LOAD_PAYPAL' |
      'LOADING_PAYPAL' |
      'ERROR_LOADING_PAYPAL'|
      'FETCH_TOKEN' |
      'FETCHING_TOKEN' |
      'ERROR_FETCHING_TOKEN' |
      'CREATE_CLIENT'|
      'ERROR_CREATING_CLIENT' |
      'CREATING_CLIENT' |
      'SEND_DEVICE_DATA' |
      'SENDING_DEVICE_DATA' |
      'ERROR_SENDING_DEVICE_DATA'|
      'DEVICE_DATA_RESPONSE' |
      'CANCEL_PAYMENT' |
      'CANCELLING_PAYMENT' |
      'ERROR_CANCELLING_PAYMENT'
    >('INITIAL');

    const params = useParams();
    let uuid = params['uuid'];
    const [dataRecived, setDataRecived] = useState<{
      actionType: 'error' | 'redirect';
      message?: string;
      redirect?: {
        type: string;
        url: string;
      };
    }>();
    useEffect(() => {
      if(status === 'INITIAL') {
        setStatus('FETCH_TOKEN');
      }
      if(status === 'FETCH_TOKEN') {
        setStatus('FETCHING_TOKEN');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/token`)
          .then(clientToken => {
            setClientToken(clientToken)
            setStatus('LOAD_PAYPAL');
          })
          .catch((e) => {
            e && pushTrackingEvent({
              type: "TrackingEvent",
              data: {
                  category:"error-paypal-device_data", action:"getting_token", label: JSON.stringify(e)
              }
            });
            setStatus('ERROR_FETCHING_TOKEN');
          })
      }
      if(status === 'LOAD_PAYPAL') {
        setStatus('LOADING_PAYPAL');
        loadScript({
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
        })
        .then((paypal) => {
            setStatus('CREATE_CLIENT');
            setPayPalScript(paypal);
        })
        .catch((e) => {
          e && pushTrackingEvent({
            type: "TrackingEvent",
            data: {
                category:"error-paypal-device_data", action:"loading_script", label: JSON.stringify(e)
            }
          });
          setStatus('ERROR_LOADING_PAYPAL');
        });
      }
      if(status === 'CREATE_CLIENT' && clientToken && payPalScript) {
        setStatus('CREATING_CLIENT');
        client.create({
          authorization: clientToken.clientToken,
        }).then((clientInstance) => {
          return dataCollector.create({
            client: clientInstance,
          });
        })
        .then((dataCollectorInstance) => {
          setDeviceData(dataCollectorInstance.deviceData);
          setStatus('SEND_DEVICE_DATA');
        })
        .catch((e) => {
          e && pushTrackingEvent({
            type: "TrackingEvent",
            data: {
                category:"error-paypal-device_data", action:"creating_client", label: JSON.stringify(e)
            }
          });
          setStatus('ERROR_CREATING_CLIENT');
        });
      }
      if(status === 'SEND_DEVICE_DATA' && deviceData) {
        setStatus('SENDING_DEVICE_DATA');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-paypal-payment/submit-device-data/${uuid}`, {
          method: 'POST',
          body: JSON.stringify({deviceData})
        })
          .then((data) => {
            if(!data || !data.actionType) {
              setStatus('DEVICE_DATA_RESPONSE')
              setDataRecived({
                actionType: 'error',
                message: "C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20147)"
              });
            } else if(data.actionType === 'redirect') {
              if(data.linkAction?.type === 'internal' && data.linkAction?.link) {
                history.replace(data.linkAction?.link);
              } else if(data.linkAction?.link){
                window.location.href = data.linkAction?.link;
              }
            } else {
              setStatus('DEVICE_DATA_RESPONSE')
              setDataRecived(data);
            }
          })
          .catch((e) => {
            e && pushTrackingEvent({
              type: "TrackingEvent",
              data: {
                  category:"error-paypal-device_data", action:"submittingDeviceData", label: JSON.stringify(e)
              }
            });
            setStatus('ERROR_SENDING_DEVICE_DATA');
          })
      }
      if(status === 'CANCEL_PAYMENT') {
        setStatus('CANCELLING_PAYMENT');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-paypal-payment/cancel/${uuid}`, {
          method: 'POST',
        })
          .then((data) => {
            if(!data || !data.actionType) {
              setStatus('DEVICE_DATA_RESPONSE')
              setDataRecived({
                actionType: 'error',
                message: "C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20148)"
              });
            } else if(data.actionType === 'redirect') {
              if(data.linkAction?.type === 'internal' && data.linkAction?.link) {
                history.replace(data.linkAction?.link);
              } else if(data.linkAction?.link){
                window.location.href = data.linkAction?.link;
              }
            } else {
              setStatus('DEVICE_DATA_RESPONSE')
              setDataRecived(data);
            }
          })
          .catch((e) => {
            e && pushTrackingEvent({
              type: "TrackingEvent",
              data: {
                  category:"error-paypal-device_data", action:"cancelling_payment", label: JSON.stringify(e)
              }
            });
            setStatus('ERROR_CANCELLING_PAYMENT');
          })
      }
    }, [history, status, clientToken, payPalScript, deviceData, uuid])

    return (
      <Modal title="Verifica PayPal" loading={
        ![
          'ERROR_LOADING_PAYPAL',
          'ERROR_FETCHING_TOKEN',
          'ERROR_CREATING_CLIENT',
          'ERROR_SENDING_DEVICE_DATA',
          'DEVICE_DATA_RESPONSE',
          'ERROR_CANCELLING_PAYMENT'
        ].includes(status)} action={history.goBack}>
          <Container className={classes.container} component="main" maxWidth="xs">
            <Alert severity={"error"}>{"DEVICE_DATA_RESPONSE" === status && dataRecived?.actionType === 'error' && dataRecived?.message ? dataRecived?.message : "C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20146)"}</Alert>
            <div className={classes.errorButtonContainer}>
                <Button color="primary" onClick={() => {
                  setStatus('INITIAL')
                }} variant="contained" size="large" className={classes.errorButton}>
                  Riprova
                </Button>
                <Button onClick={() => {
                  setStatus('CANCEL_PAYMENT')
                }} variant="contained" size="large">
                  Annulla
                </Button>
            </div>
          </Container>
      </Modal>
    );
}
