import { UPDATE_CART, UPDATE_CART_ITEM } from '../';
import DataProvider from '../../../../provider';
import { show } from './message';
import { incrementPendingRequestCounter, decrementPendingRequestCounter, updateStatus } from './index';
import { SellingAreaStatus } from '../';

export const update = (cart, walletBalance?: any, corporateCreditBalance?: number ) => ({
	type: UPDATE_CART,
	cart,
	walletBalance,
	corporateCreditBalance,
});

export const updateCartItem = request => ({
	type: UPDATE_CART_ITEM,
	request
});

export function updateItem(dispatch, request: UpdateItemRequest) {
	const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

	if(request.availableQuantity && request.availableQuantity < request.quantity) {
		show(dispatch, `${request.availableQuantity === 1 ? 'È disponibile solo' : 'Sono disponibili solo'} ${request.availableQuantity} ${request.localLabel}`);
		return null;
	}

	dispatch(updateCartItem(request));
	dispatch(incrementPendingRequestCounter());

	dataProvider(`b2c/locker/selling-order/edit-item`, {
		method: 'POST',
		body: JSON.stringify(request)
	})
	.then(data => {
		dispatch(update(data, request.walletBalance, request.corporateCreditBalance));
		dispatch(decrementPendingRequestCounter());
	})
	.catch(e => {
		show(dispatch, "Operazione non consentita");
		dispatch(decrementPendingRequestCounter());
		dispatch(updateStatus(SellingAreaStatus.FETCH_DATA))
	})
}

interface UpdateItemRequest {
	lockerId: number,
	itemSaleableId?: number, 
	itemSaleablePriceListId?: number,
	quantity: number,
	localPrice: number,
	localLabel: string;
	availableQuantity?: number,
	walletBalance: number,
	corporateCreditBalance: number,
}

interface ReloadCartRequest {
	
}