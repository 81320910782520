import React, { useState, useEffect, useRef } from 'react';
import Button from '@material-ui/core/Button';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Funnel from '../../../../templates/contents/Funnel';
import DataProvider from '../../../../provider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ThreeDSecureParametersResponse, SubmitCancel3dSecureNonceResponse } from './interfaces';
import { client, threeDSecure, ThreeDSecure, Client } from 'braintree-web';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme: Theme) => ({
    button: {
        margin: theme.spacing(1, 0, 0),
    },
    container: {
        padding: theme.spacing(4, 2),
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    text: {
        padding: theme.spacing(4, 0),
    },
}));

type ThreeDSecureStatus =
 | 'FETCH_DATA'
 | 'FETCHING_DATA'
 | 'ERROR_DATA_FETCHING'
 | 'START_VERIFY'
 | 'VERIFYING'
 | 'ERROR_VERIFYING'
 | 'CANCEL_VERIFY'
 | 'SEND_NONCE'
 | 'SENDING_NONCE'
 | 'ERROR_SENDING_NONCE'
 | 'CANCELLING_PAYMENT'
 | 'ERROR_CANCEL_PAYMENT'
 | 'AWAITING_CARDHOLDER_NAME'
 | 'SEND_CARDHOLDER_NAME'
 | 'SENDING_CARDHOLDER_NAME'
 | 'ERROR_SENDING_CARDHOLDER_NAME'
 | 'GET_IP_ADDRESS'
 | 'GETTING_IP_ADDRESS'
 | 'ERROR_GETTING_IP_ADDRESS'

const loading_status = [
  'FETCHING_DATA',
  'START_VERIFY',
  'VERIFYING',
  'CANCEL_VERIFY',
  'CANCELLING_PAYMENT',
  'SEND_NONCE',
  'SEND_CARDHOLDER_NAME',
  'SENDING_CARDHOLDER_NAME',
  'SENDING_NONCE',
  'GET_IP_ADDRESS',
  'GETTING_IP_ADDRESS',
  'ERROR_GETTING_IP_ADDRESS',
];

const retry_status = [
  'ERROR_VERIFYING',
  'ERROR_SENDING_NONCE',
  'ERROR_DATA_FETCHING',
];

const successful_status = [
  'authenticate_attempt_successful',
  'authenticate_successful',
  'authenticate_successful_issuer_not_participating',
  'authentication_unavailable',
  'lookup_bypassed',
  'lookup_error',
  'lookup_not_enrolled',
  'unsupported_card',
  'unsupported_account_type',
  'unsupported_three_d_secure_version',
  'authentication_bypassed',
  'lookup_failed_acs_error',
  'data_only_successful'
];

export default function ThreeDSecurePayment (props: any) {
    const [status, setStatus] = useState<ThreeDSecureStatus>('FETCH_DATA');	
    const [lastBrainTreeStatus, setLastBrainTreeStatus] = useState<string>('20141');
    const [threeDSResponse, setThreeDSResponse] = useState<ThreeDSecureParametersResponse>();
    const [length, setLength] = useState<number>();
    const [nonce, setNonce] = useState<string>();
    const classes = useStyles();
    const threeDSecureInstance = useRef<ThreeDSecure>();
    const clientInstance = useRef<Client>();
    const params = useParams();
    const history = useHistory();
    const [ipAddress, setIPAddress] = useState('')
    const [cardholderName, setCardholderName] = useState<string>();
    const [lastSendingCardHolderNameErrorCode, setLastSendingCardHolderNameErrorCode] = useState<string>('20141');

    let uuid = params['uuid'];
    const lookupCallback = (data, next) => {
      next();
    };
    const cancelCallback = () => {
      setStatus('CANCEL_VERIFY');
    };
    useEffect(() => {
        if(!length) {
          setLength(window.history.length);
        }
    }, [length])

    useEffect(() => {
      return () => {
        if (history.action === "POP") {
          threeDSecureInstance.current?.off('lookup-complete', lookupCallback);
          threeDSecureInstance.current?.off('customer-canceled', cancelCallback);
          clientInstance.current?.teardown(() => {});
          threeDSecureInstance.current?.teardown();
          const iframe = document.getElementById('Cardinal-ElementContainer')
          const container = document.getElementById('Cardinal-collector')
          iframe?.remove();
          container?.remove();
        }
      };
    }, [history])

    useEffect(() => {
      if(status === 'FETCH_DATA' && uuid) {
        setStatus('FETCHING_DATA');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-cc-payment/3dsecure-flow/${uuid}`)
          .then(data => {
            setThreeDSResponse(data);
            if (!data.cardholderName) {
              setStatus('AWAITING_CARDHOLDER_NAME');
            } else {
              setStatus('GET_IP_ADDRESS');
            }
          })
          .catch(e => {
            setStatus('ERROR_DATA_FETCHING');
          })
      } else if(status === 'FETCH_DATA' && !uuid) {
        setStatus('ERROR_DATA_FETCHING');
      }
    }, [status, uuid]);

    useEffect(() => {
        if (status === 'START_VERIFY' && threeDSResponse) {
            setStatus('VERIFYING');
  
            client.create({
        	    authorization: threeDSResponse.token
            })
            .then((_clientInstance: Client) => {
              clientInstance.current = _clientInstance;
              return threeDSecure.create({
                version: 2,
                client: clientInstance.current,
              });
            })
            .then((_threeDSecureInstance) => {
              threeDSecureInstance.current = _threeDSecureInstance;
              threeDSecureInstance.current?.on('lookup-complete', lookupCallback);
              threeDSecureInstance.current?.on('customer-canceled', cancelCallback);
    
              return threeDSecureInstance.current?.verifyCard({
                ...threeDSResponse.threeDSecureParameters,
                additionalInformation: threeDSResponse.threeDSecureParameters.additionalInformation ? {
                  ...threeDSResponse.threeDSecureParameters.additionalInformation,
                  ipAddress,
                } : {
                  ipAddress
                }
              });
            })
            .then((response) => {
              setLastBrainTreeStatus(response.threeDSecureInfo['status'] || '20141');
              if(successful_status.includes(response.threeDSecureInfo['status'])) {
                setNonce(response.nonce);
                setStatus('SEND_NONCE');
              } else {
                setStatus('ERROR_VERIFYING');
              }
            })
            .catch((err) => {
              setStatus('ERROR_VERIFYING');
            });
            return () => {
              threeDSecureInstance.current?.off('lookup-complete', lookupCallback);
              threeDSecureInstance.current?.off('customer-canceled', cancelCallback);
              clientInstance.current?.teardown(() => {});
              threeDSecureInstance.current?.teardown();
            }
        }
    }, [status, threeDSResponse, ipAddress]);

    useEffect(() => {
      if (status === 'SEND_NONCE' && nonce && uuid) {
        setStatus('SENDING_NONCE');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-cc-payment/3dsecure-flow/${uuid}`, {
          method: 'POST',
          body: JSON.stringify({nonce})
        })
        .then((data: SubmitCancel3dSecureNonceResponse) => {
          switch (data.actionType) {
						case 'redirect':
							if(data.linkAction?.type === 'internal' && data.linkAction?.link) {
                if(length && window.history.length > length) {
                  window.history.go(length - window.history.length - 1);
                }
                /**
                 * do this to give time to react router to update
                 */
                setTimeout(() => {
                  history.push(data.linkAction?.link || '');
                }, 50)
								break;
							} else if(data.linkAction?.link) {
								window.location.href = data.linkAction?.link;
								break;
							}
							break;
            case 'error':
						default:
							throw new Error(data.message);
					}
        });
      } else if(status === 'SEND_NONCE' && !nonce && !uuid) {
        setStatus('ERROR_SENDING_NONCE');
      }
    }, [status, nonce, uuid, history, length])

    useEffect(() => {
      if (status === 'CANCEL_VERIFY' && uuid) {
        setStatus('CANCELLING_PAYMENT');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-cc-payment/3dsecure-flow/${uuid}/cancel`)
        .then((data: SubmitCancel3dSecureNonceResponse) => {
          switch (data.actionType) {
						case 'redirect':
							if(data.linkAction?.type === 'internal' && data.linkAction?.link) {
                if(length && window.history.length > length) {
                  window.history.go(length - window.history.length - 1);
                }
                /**
                 * do this to give time to react router to update
                 */
                setTimeout(() => {
                  history.push(data.linkAction?.link || '');
                }, 50)
								break;
							} else if(data.linkAction?.link) {
								window.location.href = data.linkAction?.link;
								break;
							}
							break;
            case 'error':
						default:
              setStatus('ERROR_CANCEL_PAYMENT');
					}
        });
      } else if (status === 'CANCEL_VERIFY' && !uuid) {
        setStatus('ERROR_CANCEL_PAYMENT');
      }
    }, [status, uuid, history, length])

    useEffect(() => {
      if (status === 'SEND_CARDHOLDER_NAME') {
        setStatus('SENDING_CARDHOLDER_NAME');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-cc-payment/3dsecure-flow/${uuid}/saveCreditCardData`, {
          method: 'POST',
          body: JSON.stringify({ cardholderName })
        })
          .then((response: {
            success: boolean;
            code: string;
          }) => {
            if(response.success) {
              setStatus('FETCH_DATA');
            }
            else {
              setStatus('ERROR_SENDING_CARDHOLDER_NAME');
              setLastSendingCardHolderNameErrorCode(response.code);
            }
          })
          .catch(e => {
            setStatus('ERROR_SENDING_CARDHOLDER_NAME')
          })
      }
    }, [cardholderName, status, uuid]);
  
    useEffect(() => {
      if (status === 'GET_IP_ADDRESS') {
        setStatus('GETTING_IP_ADDRESS');
  
        fetch('https://api.ipify.org?format=json')
          .then(response => response.json())
          .then(data => {
            setIPAddress(data.ip)
            setStatus('START_VERIFY');
          })
          .catch(error => {
            setStatus('ERROR_GETTING_IP_ADDRESS')
          })
      }
    }, [status]);

    return <Funnel title="Verifica Pagamento" loading={loading_status.includes(status)}>
        <Container className={classes.container} component="main" maxWidth="xs">
            {retry_status.includes(status) && <>
                <Typography className={classes.text}>C'è stato un errore durante la verifica del pagamento (CODE: {lastBrainTreeStatus})</Typography>
                <Button
                  className={classes.button}
                  fullWidth={true}
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setStatus('CANCEL_VERIFY')
                  }}>
                    CANCELLA
                </Button>
                <Button
                  className={classes.button}
                  fullWidth={true}
				          variant="contained"
				          size="large"
				          color="primary"
                  onClick={() => {
                    setStatus('FETCH_DATA')
                  }}>
                    RIPROVA
                </Button>
            </>}
            {status === 'ERROR_CANCEL_PAYMENT' && <>
                <Typography className={classes.text}>Errore durante il caricamento</Typography> 
                <Button
                  className={classes.button}
                  fullWidth={true}
				          variant="contained"
				          size="large"
				          color="primary"
                  onClick={() => {
                    setStatus('CANCEL_VERIFY')
                  }}>
                    RIPROVA
                </Button>
            </>}
            {['AWAITING_CARDHOLDER_NAME', 'ERROR_SENDING_CARDHOLDER_NAME'].includes(status) && <>
              {status === 'AWAITING_CARDHOLDER_NAME' && <Typography className={classes.text}>Inserisci il titolare della carta {threeDSResponse && threeDSResponse.cardLabel}</Typography>}
              {status === 'ERROR_SENDING_CARDHOLDER_NAME' && <Typography className={classes.text}>C'è stato un errore durante l'aggiornamento del titolare della carta {threeDSResponse && threeDSResponse.cardLabel} (CODE: {lastSendingCardHolderNameErrorCode})</Typography>}
              <TextField
                fullWidth
                value={cardholderName}
                variant="outlined"
                placeholder={'Titolare della carta'}
                onChange={(event) => setCardholderName(event.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <CreditCardIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Button
                fullWidth={true}
                className={classes.button}
                onClick={() => {
                  setStatus('SEND_CARDHOLDER_NAME')
                }}
                variant="contained"
                color="primary">
                INVIA
              </Button>
              <Button
                className={classes.button}
                fullWidth={true}
                variant="outlined"
                color="primary"
                onClick={() => {
                  setStatus('CANCEL_VERIFY')
                }}>
                  CANCELLA
              </Button>
            </>}
        </Container>
    </Funnel>;
}
