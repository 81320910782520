
import { getAuth, signOut } from "firebase/auth";
import { CustomOptions } from "./index";

export default async (url: string, options: any = {}, customOptions?: CustomOptions) => {
	const defaultAuth = getAuth();
	const firebaseToken = defaultAuth.currentUser ? await defaultAuth.currentUser.getIdToken() : '';
	
	const token = localStorage.getItem('token') || '';

	const requestHeaders = (options.headers ||
		new Headers({
			Authorization: token,
			AuthorizationF: firebaseToken,
			'Access-Control-Request-Headers': 'AuthorizationF',
			Accept: 'application/json',
		})
	);

	if (!requestHeaders.has('Content-Type') &&
		!(options && options.body && options.body instanceof FormData)) {
		requestHeaders.set('Content-Type', 'application/json');
	}

	const response = await fetch(url, { ...options, headers: requestHeaders })
	const text = await response.text();

	const o = {
		status: response.status,
		statusText: response.statusText,
		headers: response.headers,
		body: text,
	};
	let status = o.status, statusText = o.statusText, headers = o.headers, body = o.body;
	let json;

	try {
		json = JSON.parse(body);
	} catch (e) {
		// not json, no big deal
	}

	if(customOptions && customOptions.avoidStatusChecks) {
		return Promise.resolve({
			status: response.status,
			statusText: response.statusText,
			headers: response.headers,
			json,
		});
	}

	if(status === 200 || status === 201) {
		const token_auth = headers.get('Authorization');
		if(token_auth) {
			localStorage.setItem('token', token_auth);
		}
		return Promise.resolve(json)
	}
	else if([401, 403].includes(status)) {
		const pathname = window.location.pathname.includes('login') ? '/' : window.location.pathname;
		localStorage.setItem('redirect_to', `${pathname}${window.location.search}`)
		localStorage.removeItem('token');
		await signOut(defaultAuth);
		window.location.replace('/login');
	} else if (status < 200 || status >= 300) {
		return Promise.reject(json || statusText);
	}
};