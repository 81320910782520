import React from 'react';

const iconArray = [
    'cerealsContainingGluten',
    'cerealsContainingGluten',
    'milkAndDairyProducts',
    'fruitsInShell',
    'dietVegetarian',
    'dietVegan',
] as const;

type icon = typeof iconArray[number];

export function Icon(props: {
    name: icon;
}) {
    let svgIcon: any = '';

    switch (props.name) {
        case 'cerealsContainingGluten':
            svgIcon = 
                <svg viewBox="0 0 30 30">
                    <g>
                        <path fill="#454545" d="M15.67,0A15,15,0,1,0,30,15.17,15,15,0,0,0,15.67,0ZM6.83,26.41c-.41-.29-.52-.44-.1-.83C8,24.34,9.27,23.07,10.52,21.8l.06-.05,1-1a2.31,2.31,0,0,1-.61-.77l-1,1,0,0q-2,2-4.05,4.05c-.29.29-.45.26-.73,0A13.52,13.52,0,0,1,1,14.47,14,14,0,0,1,25.07,5.21c.23.23.3.38,0,.64-1.75,1.73-3.49,3.48-5.23,5.22l-7,7a2.1,2.1,0,0,1,.77.6l6.41-6.41.05-.05c1.86-1.85,3.73-3.7,5.57-5.58.33-.34.45-.14.64.11A14,14,0,0,1,6.83,26.41Z"/>
                        <path fill="#454545" d="M18.92,18.29c0-.59,0-.63-.19-.62a3.44,3.44,0,0,0-2.93,1.11,1.46,1.46,0,0,0-.05,2,1.43,1.43,0,0,0,2,.12,3,3,0,0,0,1.17-2.57m-.42-2.6A4.89,4.89,0,0,0,19,13.4c-1,1-1.87,1.85-2.76,2.76-.11.1-.32.21-.25.38s.29.26.5.3a2,2,0,0,0,2.05-1.15m-4.83,3,6.41-6.41a.62.62,0,0,0-.17.48,6.43,6.43,0,0,1-.76,3.91c.7,0,.84.37.8.91s0,.87-.08,1.29a3.91,3.91,0,0,1-1,2.35,2.75,2.75,0,0,1-3.62.58v.62c0,1.24,0,2.47,0,3.7,0,.35,0,.72-.46.73s-.51-.38-.5-.75V21.75a2.56,2.56,0,0,1-3.07-.05c-.33-.24-.48-.16-.69.05l1-1a1.27,1.27,0,0,0,.12.1,1.47,1.47,0,0,0,2,0,1.43,1.43,0,0,0,.12-2l-.15-.2"/>
                        <path fill="#454545" d="M13.7,16.55a1.43,1.43,0,0,0,.12-2,3,3,0,0,0-2.57-1.17c-.59,0-.63,0-.62.19a3.44,3.44,0,0,0,1.11,2.93,1.46,1.46,0,0,0,2,.05m.09-4.46a1.39,1.39,0,0,0,.08-1.9c-.63-1-1.66-1.1-2.69-1.24-.21,0-.49-.15-.56.27a3.48,3.48,0,0,0,1.27,3,1.39,1.39,0,0,0,1.9-.11m3.55,1.41c-1.25.36-1.81.93-1.9,1.91l1.9-1.91m.88-1.79a3.15,3.15,0,0,0,.69-2c0-.74-.1-.89-.41-.83a5.41,5.41,0,0,0-2.16.64,1.89,1.89,0,0,0-.92,1.81,1.26,1.26,0,0,0,.72,1,1.79,1.79,0,0,0,2.08-.65m.88-3.8c.68,0,.85.14.85.82a8.56,8.56,0,0,1-.31,2.48l.23-.14-7,7a4.68,4.68,0,0,0-1.65-.37c-.59,0-.63,0-.62.2A5.21,5.21,0,0,0,11,19.94l-1,1a.47.47,0,0,0,.1-.6,6.85,6.85,0,0,1-.46-3c0-.33.1-.57.47-.61s.26-.17.15-.38a6.14,6.14,0,0,1-.63-3c0-.6,0-1.06.84-1a6.39,6.39,0,0,1-.84-3.67c0-.46.19-.69.66-.69,1.71,0,3.4.11,4.48,1.81A3.44,3.44,0,0,1,17,8.14a10.14,10.14,0,0,1,2.13-.23"/>
                        <path fill="#e3cfa8" d="M19,13.4a4.89,4.89,0,0,1-.46,2.29,2,2,0,0,1-2.05,1.15c-.21,0-.43-.11-.5-.3s.14-.28.25-.38c.89-.91,1.79-1.8,2.76-2.76"/>
                        <path fill="#e3cfa8" d="M18.73,17.67c.22,0,.22,0,.19.62a3,3,0,0,1-1.17,2.57,1.43,1.43,0,0,1-2-.12,1.47,1.47,0,0,1,.05-2,3.44,3.44,0,0,1,2.93-1.11"/>
                        <path fill="#e3cfa8" d="M18.91,9.69a3.11,3.11,0,0,1-.7,2,1.78,1.78,0,0,1-2.07.65,1.26,1.26,0,0,1-.72-1,1.89,1.89,0,0,1,.92-1.81,5.41,5.41,0,0,1,2.16-.64c.31-.06.37.09.41.83"/>
                        <path fill="#454545" d="M16.83,5.93c0,.43,0,.86,0,1.29a.49.49,0,0,1-.49.53.47.47,0,0,1-.48-.53c0-.84,0-1.68,0-2.53a.47.47,0,0,1,.49-.51c.32,0,.48.2.49.52V5.93Z"/>
                        <path fill="#e3cfa8" d="M15.44,15.41c.09-1,.65-1.55,1.9-1.91l-1.9,1.91"/>
                        <path fill="#454545" d="M15.29,6.63V7.91c0,.31-.14.52-.48.51s-.47-.2-.47-.52V5.39a.45.45,0,0,1,.48-.51c.34,0,.45.22.45.53s0,.81,0,1.22Z"/>
                        <path fill="#c4ba9e" d="M11.69,9.91a.29.29,0,0,0-.28-.32.24.24,0,0,0-.27.25c0,.18,0,.32.23.35s.29-.1.32-.28m1.46,1.9c-.05-.18-.15-.28-.34-.26a.28.28,0,0,0-.28.27c0,.2.13.29.32.29a.28.28,0,0,0,.3-.3m.72-1.62a1.42,1.42,0,0,1-2,2,3.48,3.48,0,0,1-1.27-3c.07-.42.35-.22.56-.26,1,.13,2.06.23,2.69,1.23"/>
                        <path fill="#a3a66e" d="M13.82,14.55a1.43,1.43,0,0,1-.12,2,1.47,1.47,0,0,1-2,0,3.46,3.46,0,0,1-1.11-2.93c0-.23,0-.23.62-.2a3,3,0,0,1,2.57,1.17"/>
                        <path fill="#a3a66e" d="M13.82,18.85a1.43,1.43,0,0,1-.12,2,1.47,1.47,0,0,1-2,0,1.27,1.27,0,0,1-.12-.1l2.05-2.06.15.2"/>
                        <path fill="#454545" d="M13.33,4.18c.31,0,.46.21.45.54s0,.81,0,1.22v1.3a.46.46,0,0,1-.5.5.46.46,0,0,1-.46-.48c0-.86,0-1.72,0-2.58a.45.45,0,0,1,.5-.5"/>
                        <path fill="#454545" d="M13.67,18.65l-2.05,2.06a2.44,2.44,0,0,1-.61-.77l1.89-1.89a2.21,2.21,0,0,1,.77.6"/>
                        <path fill="#454545" d="M12.8,11.55a.3.3,0,0,1,.35.26.28.28,0,0,1-.3.3.28.28,0,0,1-.32-.29.28.28,0,0,1,.27-.27"/>
                        <path fill="#a3a66e" d="M12.9,18.05,11,19.94a5.21,5.21,0,0,1-.38-2.06c0-.23,0-.23.62-.2a4.68,4.68,0,0,1,1.65.37"/>
                        <path fill="#454545" d="M11.41,9.59a.29.29,0,0,1,.28.32c0,.18-.13.3-.32.28s-.25-.17-.23-.35a.24.24,0,0,1,.27-.25"/>
                        <rect fill="none" y="-0.01" width="30" height="30.02"/>
                    </g>
                </svg>;
            break;
        case 'milkAndDairyProducts':
            svgIcon = 
                <svg viewBox="0 0 30 30">
                    <path fill="#454545" d="M25.18,5.78c.23-.23.08-.35-.08-.5a22.84,22.84,0,0,0-2.17-1.86A13,13,0,0,0,16.09,1,13.82,13.82,0,0,0,9.14,2.24,14.05,14.05,0,0,0,5.16,25c.31.31.49.34.81,0,1.41-1.44,2.83-2.85,4.26-4.26l2.25-2.24,5.36-5.31,2-1.94,5.37-5.46m-8,23.06A14,14,0,0,0,26.36,6.77c-.27-.36-.42-.42-.77-.06q-2.68,2.74-5.43,5.44l0,0-1.27,1.27-.35.36c-1.07,1.06-4.16,4.13-4.73,4.74l0,0-1.22,1.22a2.64,2.64,0,0,0-.74.75l-1.35,1.35c-.09,0-.15.13-.21.2h0l0,0C9,23.29,7.9,24.45,6.73,25.57c-.36.35-.37.5,0,.8a13.89,13.89,0,0,0,10.37,2.47M30,15A15,15,0,1,1,14.63,0,15,15,0,0,1,30,15"/>
                    <path fill="#454545" d="M18.85,13.46l1.27-1.27a1.1,1.1,0,0,0-.3.83c0,1.72,0,3.44,0,5.16s0,3.49,0,5.23a1.91,1.91,0,0,1-2,2.06c-1.72,0-3.45,0-5.17,0a1.89,1.89,0,0,1-2-2c0-.39,0-.78,0-1.17v-.06a.51.51,0,0,0,0-.21h0a.15.15,0,0,0-.09-.12.23.23,0,0,0-.14,0l1.34-1.35a1,1,0,0,0-.18.51c0,.78,0,1.56,0,2.34a1,1,0,0,0,1.09,1.1c1.71,0,3.41,0,5.11,0a1,1,0,0,0,1.09-1c0-1.17,0-2.33,0-3.5,0-.34-.14-.46-.48-.45-.88,0-1.76,0-2.64,0H13.12a1.16,1.16,0,0,0-.6.23l1.21-1.22h4.61c.26,0,.52.07.51-.37,0-1.52,0-3.23,0-4.75"/>
                    <path fill="#454545" d="M18,12.21c.91,0,1.07-.26.73-1.14a1.67,1.67,0,0,0-.42-.6c-.33-.3-.65-.63-1-.94a1.68,1.68,0,0,1-.46-2c.19-.51.42-1,.63-1.53s.07-.56-.38-.54c-1.31,0-2.62,0-3.93,0-.38,0-.41.15-.31.44.15.47.29.94.47,1.4a2,2,0,0,1-.51,2.35c-.3.28-.56.6-.84.89a1.76,1.76,0,0,0-.46,1.18c0,.32.09.42.42.42,1.06,0,2.13,0,3.2,0H18m1.79-1-2,1.95c-1.68,0-4,0-5.66,0-.44,0-.57,0-.57.49,0,1.47,0,2.95,0,4.43,0,.16-.06.41.16.45s.5.12.69-.07l-2.25,2.24a1.37,1.37,0,0,0,.43-1c0-2.35,0-4.71,0-7.07a3.57,3.57,0,0,1,1.2-3.19c.1-.07.16-.19.25-.27a1.21,1.21,0,0,0,.37-1.58,7.51,7.51,0,0,1-.58-1.94c-.08-.75.19-1.06,1-1.06h4.8c.83,0,1.14.43.88,1.23-.2.63-.39,1.25-.64,1.85A1.06,1.06,0,0,0,18.15,9a4.47,4.47,0,0,1,1.66,2.25"/>
                    <path fill="#f5edd9" d="M18.89,20c0,1.17,0,2.33,0,3.5a1,1,0,0,1-1.09,1c-1.7,0-3.4,0-5.11,0a1,1,0,0,1-1.1-1.1q0-1.17,0-2.34a1,1,0,0,1,.19-.51l.74-.75a1.22,1.22,0,0,1,.61-.23h2.65c.88,0,1.76,0,2.64,0,.34,0,.48.11.48.45"/>
                    <path fill="#d9edf0" d="M18.85,18.21c0,.44-.25.37-.51.37H13.73l0,0,4.73-4.74.35-.36c0,1.52,0,3.23,0,4.75"/>
                    <path fill="#f5edd9" d="M18.75,11.07c.34.88.18,1.14-.73,1.14H15.19c-1.07,0-2.13,0-3.2,0-.33,0-.43-.1-.42-.42A1.76,1.76,0,0,1,12,10.62c.28-.29.54-.61.84-.89a2,2,0,0,0,.51-2.35c-.18-.46-.32-.93-.47-1.4-.1-.29-.07-.44.31-.44,1.31,0,2.62,0,3.93,0,.45,0,.55.15.38.54s-.44,1-.63,1.53a1.68,1.68,0,0,0,.46,2c.33.31.64.64,1,.94a1.59,1.59,0,0,1,.42.6"/>
                    <path fill="#e8edf0" d="M18.5,13.82l-4.73,4.74c.57-.61,3.66-3.68,4.73-4.74"/>
                    <path fill="#d9edf0" d="M17.84,13.18l-5.36,5.31c-.19.19-.48.11-.69.07s-.16-.29-.16-.46c0-1.47,0-2.94,0-4.42,0-.45.13-.51.57-.49,1.68,0,4,0,5.66,0"/>
                    <path fill="#e8edf0" d="M10.65,22.05a.51.51,0,0,1,0,.21.76.76,0,0,0,0-.21"/>
                    <path fill="#e8edf0" d="M10.36,22l-.15.15h0a1,1,0,0,1,.15-.14"/>
                </svg>;
            break;
        case 'fruitsInShell':
            svgIcon = 
                <svg viewBox="0 0 30 30">
                    <path fill="#454545" d="M25,6c.33-.32.34-.5,0-.82A13.71,13.71,0,0,0,12.38,1.24,14,14,0,0,0,5.07,24.91c.39.41.65.4,1,0,.51-.57,1.06-1.08,1.6-1.62l3.9-3.87,2-2a27.28,27.28,0,0,0,2.27-2.29l2.2-2.21Q21.51,9.43,25,6M21.81,27.25a14,14,0,0,0,4.46-20.6c-.19-.24-.29-.33-.55-.07-2.07,2.07-4.15,4.13-6.36,6.32l-1.93,1.94c-.88.86-1.77,1.75-2.7,2.67h0l-1.63,1.63C11.09,21.27,9,23.33,6.9,25.43c-.62.62-.78.5.09,1.06a13.69,13.69,0,0,0,14.82.76M15,0A15,15,0,1,1,0,14.72,15,15,0,0,1,15,0"/>
                    <path fill="#454545" d="M24.37,16A4.2,4.2,0,0,1,17,19.62a3,3,0,0,0-2.07-1.07,2,2,0,0,0-1.77.6l1.63-1.63h0l.12,0,.19.05.11,0a4.37,4.37,0,0,1,2.33,1.16.46.46,0,0,1,.07.07h0l.14.16c.05.05.09.11.14.17a3.2,3.2,0,0,0,4.11.68A3.26,3.26,0,0,0,23.37,16a3.2,3.2,0,0,0-2-2c-1.17-.42-2.24.15-3.35.33,0,0,0,0,0,0l-.54.54,1.93-1.94.68,0A4.17,4.17,0,0,1,24.37,16"/>
                    <path fill="#e3cfa8" d="M20.58,19.12a.71.71,0,0,0,.11-.7c-.05-.11-.12-.23-.26-.21a.22.22,0,0,0-.19.27c.09.51-.11.56-.49.31-.12-.07-.24-.09-.33,0s0,.26.09.37a.7.7,0,0,0,.43.21.68.68,0,0,0,.64-.28M22,18.27a.69.69,0,0,0,.51-.66.71.71,0,0,0-.16-.48c-.13-.15-.31-.34-.52-.2s0,.3.11.45.14.38-.14.45-.41.08-.3.34.32.16.5.1M23.37,16A3.26,3.26,0,0,1,22,19.85a3.2,3.2,0,0,1-4.11-.68c-.05-.06-.09-.12-.14-.17l-.14-.16h0a.46.46,0,0,0-.07-.07,4.19,4.19,0,0,0-2.33-1.16l-.11,0-.2-.05-.11,0c1.13-1.11,2.13-2.15,3.18-3.18,0,0,0,0,0,0,1.11-.18,2.18-.75,3.35-.33a3.2,3.2,0,0,1,2,2"/>
                    <path fill="#454545" d="M22.53,17.61a.68.68,0,0,1-.52.66c-.17.06-.39.1-.49-.1s.12-.29.3-.34.32-.27.14-.45-.4-.25-.11-.45.39.05.52.2a.71.71,0,0,1,.16.48"/>
                    <path fill="#454545" d="M20.69,18.42a.71.71,0,0,1-.11.7.68.68,0,0,1-.64.28.7.7,0,0,1-.43-.21c-.11-.11-.19-.23-.09-.37s.21-.1.33,0c.38.25.58.21.49-.31a.22.22,0,0,1,.18-.27c.15,0,.22.1.27.21"/>
                    <path fill="#454545" d="M18.07,12.89l-2.2,2.22c.34-.38.68-.74,1-1.11a4.39,4.39,0,0,1-2.37-1.93,4.17,4.17,0,0,0-5.35-1.79,4.22,4.22,0,0,0-2.39,5.13,4.16,4.16,0,0,0,6.37,2.32l.44-.34-2,2c-.4,0-.61,0-.81,0a5.21,5.21,0,1,1,4.65-7.75,3.11,3.11,0,0,0,1.84,1.44.68.68,0,0,0,.81-.2"/>
                    <path fill="#e3cfa8" d="M8.69,14.15c.16-.22,0-.3-.22-.38s-.28-.34,0-.49.38-.17.21-.38-.37-.09-.54,0a.75.75,0,0,0-.32.6.69.69,0,0,0,.38.61c.17.08.37.2.5,0m.85,2.22c.17,0,.33-.12.3-.32s-.19-.28-.45-.19-.55-.11-.32-.53c.09-.17.21-.34,0-.46s-.39.07-.49.25a.84.84,0,0,0,1,1.25M11,15.86c.24-.06.53-.16.44-.46s-.31-.08-.48,0-.35-.06-.37-.29.12-.42-.19-.4-.29.28-.28.47a.7.7,0,0,0,.72.71l.16,0m.68-4c.14,0,.17-.15.15-.36a.65.65,0,0,0-.68-.56.65.65,0,0,0-.72.59.22.22,0,0,0,.15.29.2.2,0,0,0,.25-.11c.23-.52.42-.4.6,0a.2.2,0,0,0,.25.13m.5,4.93c0-.15,0-.31-.15-.36s-.26,0-.3.21c-.1.44-.3.45-.55.11-.1-.14-.21-.21-.35-.08s-.07.25,0,.38a.77.77,0,0,0,.67.39.72.72,0,0,0,.67-.65M16.9,14c-.35.35-.69.72-1,1.09L13.6,17.39l-.44.34a4.16,4.16,0,0,1-6.37-2.32,4.22,4.22,0,0,1,2.39-5.13,4.17,4.17,0,0,1,5.35,1.79A4.45,4.45,0,0,0,16.9,14"/>
                    <path fill="#e8edf0" d="M13.6,17.39l2.27-2.28a28.74,28.74,0,0,1-2.27,2.28"/>
                    <path fill="#454545" d="M12,16.4c.17.05.18.21.15.35a.73.73,0,0,1-.67.66.77.77,0,0,1-.67-.39c-.08-.13-.16-.26,0-.38s.26-.06.36.08c.25.34.45.33.55-.11,0-.17.13-.26.3-.21"/>
                    <path fill="#454545" d="M11.8,11.47c0,.21,0,.32-.16.36s-.19,0-.24-.13c-.18-.42-.37-.54-.6,0a.2.2,0,0,1-.25.11.22.22,0,0,1-.15-.29.65.65,0,0,1,.72-.59.65.65,0,0,1,.68.56"/>
                    <path fill="#454545" d="M11.41,15.4c.09.3-.2.4-.44.46l-.16,0a.7.7,0,0,1-.72-.71c0-.19,0-.45.28-.47s.18.23.2.4.07.43.36.29.38-.33.48,0"/>
                    <path fill="#454545" d="M9.84,16.05c0,.2-.13.28-.3.32a.84.84,0,0,1-1-1.25c.1-.18.25-.36.49-.25s.11.29,0,.46c-.23.42-.05.56.32.53s.43,0,.45.19"/>
                    <path fill="#454545" d="M8.47,13.77c.17.08.38.16.22.38s-.33.07-.5,0a.69.69,0,0,1-.38-.61.75.75,0,0,1,.32-.6c.16-.12.39-.23.54,0s0,.29-.21.38-.28.34,0,.49"/>
                    </svg>;
            break;
        case 'dietVegetarian':
            svgIcon = 
                <svg viewBox="0 0 30 30">
                    <g>
                        <path fill="#6fad30" d="M14.1,19.28a.41.41,0,0,0-.29,0,.38.38,0,0,0,0,.69l2.59.91.34.92a.37.37,0,0,0,.35.25.31.31,0,0,0,.13,0,.37.37,0,0,0,.21-.48l-.38-1a.62.62,0,0,0,0-.1L16,17.53,17.48,13a.38.38,0,0,0-.24-.48.38.38,0,0,0-.47.24l-1.2,3.7L12.89,9.16a.34.34,0,0,0-.2-.21.39.39,0,0,0-.28,0,.38.38,0,0,0-.21.49L16.1,20Z"/>
                        <path fill="#6fad30" d="M19.7,13a13.71,13.71,0,0,0-1.25-3.47A1,1,0,0,0,17.88,9a1,1,0,0,0-.78,0,.91.91,0,0,0-.39.34l-.33.41a15.64,15.64,0,0,0-4.1-3.72A1.09,1.09,0,0,0,11.51,6a1,1,0,0,0-.62.48,1.07,1.07,0,0,0-.09.19,14.45,14.45,0,0,0,0,9.54c.15.4.32.8.5,1.18h-.05l-.18,0a1,1,0,0,0-.88,1.15,1.16,1.16,0,0,0,.21.5,14.23,14.23,0,0,0,6.78,4.76,4.91,4.91,0,0,1,.47.18.41.41,0,0,0,.18.05l.11,0a.33.33,0,0,0,.22-.18A16.56,16.56,0,0,0,19.7,13Zm-7.77,5a.35.35,0,0,0,.27-.2.43.43,0,0,0,0-.35,11.44,11.44,0,0,1-.7-1.55,13.58,13.58,0,0,1,0-9l0-.06a.26.26,0,0,1,.17-.13h.06a.25.25,0,0,1,.13,0,14.46,14.46,0,0,1,4.17,3.89.37.37,0,0,0,.29.16h0a.39.39,0,0,0,.28-.13c.22-.25.44-.53.66-.81l0,0h0a.31.31,0,0,1,.11-.09.24.24,0,0,1,.2,0,.3.3,0,0,1,.17.16A13.33,13.33,0,0,1,19,13.18a15.78,15.78,0,0,1-1.31,10l-.24-.08A13.61,13.61,0,0,1,11,18.6a.34.34,0,0,1-.05-.14.28.28,0,0,1,.24-.31Z"/>
                    </g>
                    <path fill="#6fad30" d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,.92A14.08,14.08,0,1,0,29.08,15,14.1,14.1,0,0,0,15,.92Z"/>
                </svg>;
            break;
        case 'dietVegan':
            svgIcon = 
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <path fill="#6fad30" d="M20.57,5.71c0-.09-.08-.16-.22-.24h0a.54.54,0,0,0-.35.07,14.16,14.16,0,0,0-5.19,6.83,13.77,13.77,0,0,0-.43,8.42l-.83,2.3L9.19,11a.32.32,0,0,0-.46-.23.31.31,0,0,0-.22.45l4.71,13.09a.4.4,0,0,0,.37.22.28.28,0,0,0,.24-.1.15.15,0,0,0,0-.15L15,21.16l.1,0A14.26,14.26,0,0,0,20.57,5.71Zm-2,5.52a.28.28,0,0,0,0-.25.35.35,0,0,0-.22-.21.28.28,0,0,0-.13,0l-.13,0a.36.36,0,0,0-.2.22l-3,8.42a13.42,13.42,0,0,1,.63-6.81A13,13,0,0,1,19.9,6.45a13.36,13.36,0,0,1-.41,7.62,13.64,13.64,0,0,1-4,5.78Z"/>
                    <path fill="#6fad30" d="M15,30A15,15,0,1,1,30,15,15,15,0,0,1,15,30ZM15,.92A14.08,14.08,0,1,0,29.08,15,14.1,14.1,0,0,0,15,.92Z"/>
                </svg>;
            break;
        default:
            svgIcon = null;
    }

    return <div style={{marginRight: '4px', width: '25px', height: '25px'}}>{svgIcon}</div>;
}
