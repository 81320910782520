import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import DataProvider from '../../../../../provider';
import Container from '@material-ui/core/Container';
import Funnel from '../../../../../templates/contents/Funnel';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { CrossStoreType, setKey } from '../../../../../cross-store';
import { SatispayStandardInfoResponse, PreAuthorizedTokenResponse } from '../types';

type STATE =
| 'REQUEST_REDIRECT'
| 'REQUESTING_REDIRECT'
| 'ERROR_REQUESTING_REDIRECT'
| 'REQUEST_INFO'
| 'REQUESTING_INFO'
| 'ERROR_REQUESTING_INFO'
| 'REQUEST_INFO_MAX_ATTEMPT'
| 'REQUEST_CANCEL'
| 'REQUESTING_CANCEL'
| 'ERROR_REQUESTING_CANCEL'
| 'AWAITING_INFO'
| 'REDIRECT_READY';

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        margin: theme.spacing(4, 0, 2),
    },
    button: {
        margin: theme.spacing(0, 2, 1, 2),
    }
}));

export default function Add(props: any) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const id = searchParams.get('id');
    const return_url = searchParams.get('return-url');
    const key_to_update: CrossStoreType = searchParams.get('key-to-update') as CrossStoreType;

    const [state, setState] = useState<STATE>(id ? searchParams.get('block-polling') === 'true' ? 'REQUEST_INFO_MAX_ATTEMPT' : 'REQUEST_INFO' : 'REQUEST_REDIRECT');
    const history = useHistory();
    
    const [lastError, setLastError] = useState<string>();
    const [lastInfoError, setLastInfoError] = useState<string>();
    const [lastCancelError, setLastCancelError] = useState<string>();

    const [retryCount, setRetryCount] = useState<number>(0);
    const [redirect, setRedirect] = useState<string>();

    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const classes = useStyles();

    useEffect(() => {
        if(state === 'REQUEST_REDIRECT') {
            setState('REQUESTING_REDIRECT');

            dataProvider(`b2c/payment/integration/satispay/standard/pi/add`, {
                method: 'POST',
                body: JSON.stringify({
                    returnUrl: return_url,
                    keyToUpdate: key_to_update,
                })
            })
                .then((response: PreAuthorizedTokenResponse) => {
                        if(!response) {
                            throw new Error();
                        }

                        if('error' in response) {
                            setLastError(response.error);
                            setState('ERROR_REQUESTING_REDIRECT');
                            return;
                        }

                        if(!response.data || response.data.redirect.type !==  'external') {
                            throw new Error();
                        }

                        searchParams.set('id', response.data.id);
                        searchParams.set('block-polling', 'true');
                        const locationState: {
                            key_to_update?: string;
                        } = {};
                        if(history.location && history.location.state && history.location.state['key_to_update']) {
                            locationState.key_to_update = history.location.state['key_to_update'];
                        } else if(key_to_update) {
                            locationState.key_to_update = key_to_update;
                        }
                        history.replace({
                            pathname: window.location.pathname,
                            search: `?${searchParams.toString()}`,
                            state: locationState,
                        })
                        setState('REDIRECT_READY');
                        setRedirect(response.data.redirect.link);
                })
                .catch(() => {
                    setLastError('');
                    setState('ERROR_REQUESTING_REDIRECT');
                })
        }
	}, [state, dataProvider, history, return_url, key_to_update, searchParams]);

    useEffect(() => {
        if(state === 'REQUEST_CANCEL' && id) {
            setState('REQUESTING_CANCEL');
            dataProvider(`b2c/payment/integration/satispay/standard/pi/add/${id}/cancel`, {
                method: 'POST',
            })
            .then((response: SatispayStandardInfoResponse) => {
                if(!response) {
                    throw new Error();
                }

                if('error' in response) {
                    setLastCancelError(response.error);
                    setState('ERROR_REQUESTING_CANCEL');
                    return;
                }
                if(!response.data) {
                    throw new Error();
                }

                if(response.data.status === 'CANCELED') {
                    if(history.location.state && history.location.state['key_to_update']) {
                        setKey(history.location.state['key_to_update'], response.data.instrument);
                    } else if(key_to_update) {
                        setKey(key_to_update, response.data.instrument);
                        if(return_url) {
                            history.replace(decodeURIComponent(return_url));
                            return;
                        }
                    }
                    history.goBack();
                } else {
                    throw new Error();
                }
            }).catch(() => {
                setState('ERROR_REQUESTING_CANCEL')
            })
        } else if(state === 'REQUEST_CANCEL' && !id) {
            setState('ERROR_REQUESTING_CANCEL');
        }
    }, [dataProvider, history, id, key_to_update, retryCount, return_url, state]);

    useEffect(() => {
        let timeout: null | NodeJS.Timeout = null;

        if(state === 'REQUEST_INFO' && id) {
            if(retryCount < 10) {
                setState('REQUESTING_INFO');
                dataProvider(`b2c/payment/integration/satispay/standard/pi/add/${id}/info`).then((response: SatispayStandardInfoResponse) => {
                    if(!response) {
                        throw new Error();
                    }
    
                    if('error' in response) {
                        setLastInfoError(response.error);
                        setState('ERROR_REQUESTING_INFO');
                        return;
                    }

                    if(!response.data) {
                        throw new Error();
                    }

                    switch(response.data.status) {
                        case 'PENDING':
                            timeout = setTimeout(() => {
                                setRetryCount(retryCount + 1);
                                setState('REQUEST_INFO');
                            }, 1000);
                            break;
                        case 'ACCEPTED':
                            if(history.location.state && history.location.state['key_to_update']) {
                                setKey(history.location.state['key_to_update'], response.data.instrument);
                            } else if(key_to_update) {
                                setKey(key_to_update, response.data.instrument);
                                if(return_url) {
                                    history.replace(decodeURIComponent(return_url));
                                    return;
                                }
                            }
                            history.goBack();
                            break;
                        case 'CANCELED':
                            setState('ERROR_REQUESTING_INFO');
                            break;
                    }
                }).catch(() => {
                    setState('ERROR_REQUESTING_INFO')
                })
            }
            else {
                setState('REQUEST_INFO_MAX_ATTEMPT');
            }
        } else if(state === 'REQUEST_INFO' && !id) {
            setState('ERROR_REQUESTING_INFO');
        }

        return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
		}
    }, [dataProvider, history, id, key_to_update, retryCount, return_url, state]);

    useEffect(() => {
		const handleMessage = () => {
            setRetryCount(0);
            if (!document.hidden && ['REQUEST_INFO_MAX_ATTEMPT', 'AWAITING_INFO'].includes(state)){
                setState('REQUEST_INFO');
            }
            if(document.hidden && state === 'REDIRECT_READY') {
                setState('AWAITING_INFO');
            }
        }

		document.addEventListener("visibilitychange", handleMessage, false);

		return () => {
			document.removeEventListener("visibilitychange", handleMessage);
		}
	});

    if(window.opener) {
        window.close();
        return null;
    }

    return (
        <Funnel title={`Aggiungi Satispay`} loading={['REQUEST_REDIRECT', 'REQUESTING_REDIRECT', 'REQUEST_INFO', 'REQUESTING_INFO', 'REQUEST_CANCEL', 'REQUESTING_CANCEL'].includes(state)}>
            <Container component="main" maxWidth="xs">
                {state === 'REDIRECT_READY' && <>
                    <Typography align="center" className={classes.text}>Cliccando su continua sarai reindirizzato sulla pagina del nostro partner Satispay, segui le istruzioni.</Typography>
                    <Button fullWidth size="large"variant="contained" color="primary" onClick={() => window.open(redirect)}>Continua</Button>
                </>}
                {state === 'ERROR_REQUESTING_REDIRECT' && <>
                    {!lastError && <Typography  align="center" className={classes.text}>Ops! Sembra che qualcosa sia andato storto.</Typography>}
                    {lastError && <Typography  align="center" className={classes.text}>{lastError}</Typography>}
                    <Button
                        className={classes.button}
                        fullWidth
                        onClick={() => {
                            setState('REQUEST_REDIRECT');
                        }}
                        color="primary"
                        size="large"
                        variant="contained">Riprova</Button>
                </>}
                {['AWAITING_INFO', 'REQUEST_INFO_MAX_ATTEMPT'].includes(state) && <>
                    <Typography align="center" className={classes.text}>Verifica account satispay in corso...</Typography>
                    <Button
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={() => {
                            setRetryCount(0);
						    setState('REQUEST_INFO');
                        }}
                        size="large"
                        variant="contained">Ricarica</Button>
                    {state === 'REQUEST_INFO_MAX_ATTEMPT' && <Button
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={() => {
						    setState('REQUEST_CANCEL');
                        }}
                        size="large"
                        variant="outlined">Annulla</Button>}
                </>}
                {state === 'ERROR_REQUESTING_INFO' && <>
                    {!lastInfoError && <Typography  align="center" className={classes.text}>Ops! Sembra che qualcosa sia andato storto durante la richiesta.</Typography>}
                    {lastInfoError && <Typography  align="center" className={classes.text}>{lastInfoError}</Typography>}
                    {id && <Button
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={() => {
						    setState('REQUEST_INFO');
                        }}
                        size="large"
                        variant="contained">Riprova</Button>
                    }
                </>}
                {state === 'ERROR_REQUESTING_CANCEL' && <>
                    {!lastCancelError && <Typography  align="center" className={classes.text}>Ops! Sembra che qualcosa sia andato storto durante la cancellazione.</Typography>}
                    {lastCancelError && <Typography  align="center" className={classes.text}>{lastCancelError}</Typography>}
                    {id && <Button
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={() => {
						    setState('REQUEST_CANCEL');
                        }}
                        size="large"
                        variant="contained">Riprova</Button>
                    }
                </>}
                {['ERROR_REQUESTING_CANCEL', 'ERROR_REQUESTING_REDIRECT', 'ERROR_REQUESTING_INFO', 'AWAITING_INFO'].includes(state) && <Button
                        color="primary"
                        className={classes.button}
                        fullWidth
                        onClick={() => {
                            if(return_url) {
                                history.replace(decodeURIComponent(return_url));
                                return;
                            }
                            history.goBack();
                        }}
                        size="large"
                        variant="outlined">Annulla</Button>}
            </Container>
        </Funnel>
    );
}