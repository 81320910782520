import React, { useState, useEffect } from 'react';
import { PostMessage } from '../interfaces';
import { useHistory, useLocation } from 'react-router-dom';
import { Button, Typography } from '@material-ui/core';
import DataProvider from '../../../../../provider';
import Container from '@material-ui/core/Container';
import Funnel from '../../../../../templates/contents/Funnel';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { CrossStoreType, setKey } from '../../../../../cross-store';

type STATE =
| 'REQUEST_RIDIRECT'
| 'REQUESTING_RIDIRECT'
| 'ERROR_REQUESTING_RIDIRECT'
| 'READY_RIDIRECT'
| 'AUTHENTICATION_ERROR'
| 'AUTHENTICATION_START_CONFIRMATION'
| 'AUTHENTICATION_CONFIRMATION_ERROR';

const useStyles = makeStyles((theme: Theme) => ({
    text: {
        margin: theme.spacing(4, 0, 2),
    },
    button: {
        margin: theme.spacing(0, 2),
    }
}));

export default function Add(props: any) {
    const [state, setState] = useState<STATE>('REQUEST_RIDIRECT')
    const history = useHistory();
    const [redirect, setRedirect] = useState<string>('');
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const classes = useStyles();
    const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
    const return_url = searchParams.get('return-url');
    const key_to_update: CrossStoreType = searchParams.get('key-to-update') as CrossStoreType;

    useEffect(() => {
        if(state === 'REQUEST_RIDIRECT') {
            setState('REQUESTING_RIDIRECT');
            dataProvider(`b2c/payment/integration/edenred/edenred-ticket/login`)
                    .then((redirect: {
                        type: 'internal' | 'external';
                        link: string;
                      }) => {
                        if(redirect.type !== 'external' || !redirect.link) {
                            throw new Error();
                        }
                        setState('READY_RIDIRECT');
                        setRedirect(redirect.link);
                    })
                    .catch(e => {
                        setState('ERROR_REQUESTING_RIDIRECT');
                    })
        }
	}, [state, dataProvider]);

    useEffect(() => {
		const handleMessage = (event: MessageEvent) => {
            if (
                event.origin !== window.origin ||
                !event.data ||
                (event.data && event.data.key !== 'EDENRED_LOGIN') ||
                (event.data && !event.data.body)
            ) {
                return;
            }
            const postMessage: PostMessage = event.data;
            if(postMessage.body.status === 'success' && postMessage.body.code) {
                setState('AUTHENTICATION_START_CONFIRMATION')
                dataProvider(`b2c/payment/integration/edenred/edenred-ticket/login/confirm`, {
                    method: 'POST',
                    body: JSON.stringify({
                      code: postMessage.body.code,
                    })
                })
                .then((response: {
                    message: 'success' | 'error',
                    instrument: any
                  }) => {
                    if(response.message === 'error') {
                        throw new Error();
                    }
                    if(history.location.state && history.location.state['key_to_update']) {
                        setKey(history.location.state['key_to_update'], response.instrument);
                    } else if(key_to_update) {
                        setKey(key_to_update, response.instrument);
                        if(return_url) {
                            history.replace(decodeURIComponent(return_url));
                            return;
                        }
                    }
                    history.goBack();
                })
                .catch(() => {
                    setState('AUTHENTICATION_CONFIRMATION_ERROR')
                })
            } else if (postMessage.body.status === 'error') {
                setState('AUTHENTICATION_ERROR')
            }
        }

		window.addEventListener("message", handleMessage, false);

		return () => {
			window.removeEventListener("message", handleMessage);
		}
	});

    return (
        <Funnel title="Aggiungi Ticket Restaurant Edenred" loading={['REQUEST_RIDIRECT', 'REQUESTING_RIDIRECT', 'AUTHENTICATION_START_CONFIRMATION'].includes(state)}>
            <Container component="main" maxWidth="xs">
                {state === 'READY_RIDIRECT' && <>
                    <Typography className={classes.text}>Cliccando su continua sarai reindirizzato sulla pagina del nostro partner Edenred, effettua il login e segui le istruzioni.</Typography>
                    <Button fullWidth size="large"variant="contained" color="primary" onClick={() => window.open(`${redirect}`)}>Continua</Button>
                </>}
                {state !== 'READY_RIDIRECT' && <>
                    <Typography>Ops! Sembra che qualcosa sia andato storto.</Typography>
                    <Button
                        className={classes.button}
                        fullWidth
                        onClick={() => {
                            setState('REQUEST_RIDIRECT');
                        }}
                        color="primary"
                        size="large"
                        variant="contained">
                            Riprova
                    </Button>
                    <Button
                        fullWidth
                        onClick={() => {
                            history.goBack();
                        }}
                        size="small"
                        variant="contained">
                            Annulla
                    </Button>
                </>}
            </Container>
        </Funnel>
    );
}