import React, { useEffect, useState } from 'react';
import Base from '../../templates/Base';
import TopLevel from '../../templates/contents/TopLevel';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DataProvider from '../../provider';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme: Theme) => ({
	balance: {
		fontWeight: 700,
		padding: theme.spacing(4, 0),
	},
	container: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		height: '100%',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(4, 0),
	},
	terms: {
		padding: theme.spacing(0, 0, 2),
	},
	rechargeButton: {
		margin: theme.spacing(2, 0, 0),
	},
	rechargeTitle: {
		margin: theme.spacing(2, 0, 0),
	},
	buttonContainer: {
		flex: 1,
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(0, 4),
		width: '100%',
	}
}));

type WalletStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING';

export default (props: any) => {
	const classes = useStyles();
	const [walletData, setWalletData] = useState<{
		balance: number
	} | null>(null);
	const [status, setStatus] = useState<WalletStatus>('FETCH_DATA');
	const history = useHistory();

	useEffect(() => {
		if(status === 'FETCH_DATA') {
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/wallet/`)
				.then(data => {
					setStatus('DATA_FETCHED');
					setWalletData(data);
				})
				.catch(e => {
					setStatus('ERROR_DATA_FETCHING');
					console.log(e)
				})
		}
	}, [status]);

	return (
		<Base loading={false} navigation={props.navigation} >
			<TopLevel title="Portafoglio" loading={status === 'FETCHING_DATA'}>
				<Container component="main" maxWidth="xs">
					<div className={classes.container}>
						<Typography align="center" variant="subtitle1">Il credito nel tuo<br></br> Portafoglio Foorban è di:</Typography>
						{walletData !== null && <Typography align="center" className={classes.balance} color="primary" variant="h2">{walletData?.balance} €</Typography>}
						{walletData === null && <Typography align="center" className={classes.balance} color="primary" >credito non disponibile</Typography>}
						<div className={classes.buttonContainer}>
							<Button fullWidth color="primary" variant="contained" size="large"  onClick={() => {
								history.push('/wallet/recharge');
							}}>RICARICA</Button>
						</div>
					</div>
					<Typography align="center" className={classes.terms}>
						Per info e scadenza credito visita<br></br>
						<Link underline="always" href="https://www.foorban.com/termini" color='textPrimary'>Termini e Condizioni</Link>
					</Typography>
					<Typography align="center" className={classes.terms}>
						Scopri le nostre guide per<br /> l’utilizzo dei ticket<br></br>
						<Link underline="always" href="https://foorban.com/faq#fridge_6" color='textPrimary'>Scopri</Link>
					</Typography>
				</Container>
			</TopLevel>
		</Base>
	);
}