import React from 'react';
import Button from '@material-ui/core/Button';
import { useParams } from 'react-router-dom';
import DataProvider from '../../../provider';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';
import { message } from '../state/actions';
import { updateStatus } from '../state/actions';
import { SellingAreaStatus } from '../state';
import { PaymentPreferencesState } from '../state/interfaces';

export default function PayButton(props: any) {
    let history = useHistory();
    let params = useParams();
	let id = params['id'];
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const pendingRequestCounter = useSelector(state => state.pendingRequestCounter);
    const cart = useSelector(state => state.cart);
    const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);
    const sellingAreaStatus = useSelector(state => state.status);
    const dispatch = useDispatch();

    return (
        <Button
            disabled={pendingRequestCounter > 0 || sellingAreaStatus === SellingAreaStatus.FETCHING_DATA}
            onClick={() => {
                dispatch(updateStatus(SellingAreaStatus.FETCHING_DATA))
                dataProvider(`b2c/locker/selling-order/confirm`, {
                    method: 'POST',
                    body: JSON.stringify({
                        lockerId: id,
                        paymentInstrumentUUID: paymentPreferences.status === 'FETCHED' && paymentPreferences?.definedAmountMethod?.type === 'pi' && paymentPreferences?.definedAmountMethod?.paymentInstrument.uuid,
                        partialAmountPIUUID: paymentPreferences.status === 'FETCHED' && paymentPreferences?.partialAmountMethod?.type === 'pi' && paymentPreferences?.partialAmountMethod?.paymentInstrument.uuid,
                        orderSnapshot: cart,
                    })
                })
                .then(action => {
                    if(action.type === 'internal') {
                        history.push(action.link);
                    } else if(action.type === 'external') {
                        window.location.href = action.link;
                    }
                })
                .catch(e => {
                    if(e.message) {
                        if (typeof e.message === 'string') {
                            message.show(dispatch, e.message);
                        } else if (Array.isArray(e.message)) {
                            let message2Display = 'Operazione non consentita'
                            e.message.forEach(singleMessage => {
                                console.log(singleMessage.code);
                            })
                            message.show(dispatch, message2Display);
                        } else {
                            message.show(dispatch, "Operazione non consentita");
                        }
                    }
                    else {
                        message.show(dispatch, "Operazione non consentita");
                    }

                    dispatch(updateStatus(SellingAreaStatus.FETCH_DATA))
                })
            }}
            fullWidth={true}
            variant="contained"
            size='large'
            color="primary">
            {pendingRequestCounter === 0 && sellingAreaStatus !== SellingAreaStatus.FETCHING_DATA && `${cart.totalToPay > 0 ? `Paga ${cart.totalToPay} €` : 'Conferma'}`}
            {(pendingRequestCounter > 0 || sellingAreaStatus === SellingAreaStatus.FETCHING_DATA) && <CircularProgress style={{width: '28px', height: '28px'}} color="inherit"></CircularProgress>}
        </Button>
	);
}