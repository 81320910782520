import React, { useEffect, useState } from 'react';
import Modal from '../../../../templates/contents/Modal';
import Container from '@material-ui/core/Container';
import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';

import DataProvider from '../../../../provider';
import { useHistory, useLocation } from 'react-router-dom';

import dropin  from 'braintree-web-drop-in';
import { ClientToken } from 'braintree';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CrossStoreType, setKey } from '../../../../cross-store';

const DROPIN_ID = 'dropinId';

export enum StatusAddCard {
  LOADING = 'LOADING',
  ADDING_CARD = 'ADDING_CARD',
  SERVER_SYNC = 'SERVER_SYNC',
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(0, 2, 0)
    },
    addCard: {
      margin: theme.spacing(2, 0, 0)
    },
    alertContent: {
        width: '100%',
        padding: theme.spacing(2, 0, 0)
    },
    loadingPaper: {
      paddingTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      backgroundColor: 'transparent'
    },
    loading: {
      color: theme.palette.primary.main
    },
}));

export default (props: any) => {
    
    let history = useHistory();
    const classes = useStyles();

    const [clientToken, setClientToken] = useState<ClientToken | null>(null);
    const [instance, setInstance] = useState<any | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [errorDropInInstance, setErrorDropInInstance] = useState<string | null>(null);
    const [state, setState] = useState<StatusAddCard>(StatusAddCard.LOADING);
    const location = useLocation();
	  const searchParams = new URLSearchParams(location.search);
    const return_url = searchParams.get('return-url');
    const key_to_update: CrossStoreType = searchParams.get('key-to-update') as CrossStoreType;

    useEffect(() => {
      if(clientToken) {
        return undefined;
      }
      const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
      dataProvider(`b2c/payment/integration/braintree/token`)
        .then(clientToken => {
          setClientToken(clientToken)
        })
        .catch(e => {
          console.log(e)
        })
    })


    useEffect(() => {

      if(!clientToken || instance) {
        return undefined;
      }

      dropin.create({
        authorization: clientToken.clientToken,
        container: `#${DROPIN_ID}`,
        card: {
          cardholderName: {
            required: true
          }
        },
        vaultManager: true,
        locale: 'it_IT',
        translations: {
          payWithCard: " ",
        }
      }, function (createErr, dropinInstance) {

        if(createErr) {
          setErrorDropInInstance("C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20142)");
        }
        else {
          setInstance(dropinInstance);
          setErrorDropInInstance(null);
        }

        setState(StatusAddCard.ADDING_CARD);
      });
    })

    return (
      <Modal title="Aggiungi Carta" loading={false} action={() => history.goBack()}>
          <Container className={classes.container} component="main" maxWidth="xs">
            {errorDropInInstance && <div className={classes.alertContent} >
              <Alert severity="error">{errorDropInInstance}</Alert>
            </div>}
            {error && state === StatusAddCard.ADDING_CARD && <div className={classes.alertContent} >
              <Alert severity="error">{error}</Alert>
            </div>}
            {state === StatusAddCard.LOADING && <Loading></Loading>}
            {state === StatusAddCard.SERVER_SYNC ? <Loading></Loading> : <div style={{display: instance ? 'block' : 'none'}}id={DROPIN_ID}/>}
            {instance && state !== StatusAddCard.SERVER_SYNC && <Button size='large' variant="contained" color="primary" className={classes.addCard} onClick={() => {
               instance.requestPaymentMethod((err, payload)  => {
                if(err) {
                  return null;
                }

                const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
                setState(StatusAddCard.SERVER_SYNC);
                dataProvider(`b2c/payment/integration/braintree/braintree-cc-pi/add-card`, {
                  method: 'POST',
                  body: JSON.stringify({nonce: payload.nonce})
                })
                  .then((paymentInstrument) => {
                    if(history.location.state && history.location.state['key_to_update']) {
                      setKey(history.location.state['key_to_update'], paymentInstrument);
                    } else if(key_to_update) {
                      setKey(key_to_update, paymentInstrument);
                      if(return_url) {
                        history.replace(decodeURIComponent(return_url));
                        return;
                      }
                    }
                    history.goBack();
                  })
                  .catch(e => {
                    setInstance(null)
                    setError(e.message)
                    setState(StatusAddCard.LOADING);
                  })
               })
            }}> AGGIUNGI CARTA</Button>}
          </Container>
      </Modal>
    );
}

const Loading = (props: any) => {
    const classes = useStyles();
    return (
      <div className={classes.loadingPaper}>
          <CircularProgress className={classes.loading}/>
      </div>
    );
}
      