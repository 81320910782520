import { SHOW_MESSAGE, REMOVE_MESSAGE } from '../';

const message = (state = null, action) => {
	switch (action.type) {
	case SHOW_MESSAGE:
		return action.message;
	case REMOVE_MESSAGE:
		return null;
	default:
		return state
	}
}

export default message;