import React, { useState, useEffect } from 'react';
import Base from '../templates/Base';
import Container from '@material-ui/core/Container';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import TopLevel from '../templates/contents/TopLevel';
import Typography from '@material-ui/core/Typography';
import { useParams } from 'react-router-dom';
import DataProvider from '../provider';
import { CatalogItemsResponse, CatalogCategory, CatalogItem, DietType } from './menu/interfaces';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			backgroundColor: theme.palette.background.paper,
			position: 'relative',
			overflow: 'auto',
		},
		listSection: {
			backgroundColor: 'inherit',
		},
		ul: {
			backgroundColor: 'inherit',
			padding: 0,
		},
		subHeaderContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: theme.spacing(0, 0, 1),
			borderBottom: '2px solid',
			borderBottomColor: theme.palette.primary.main,
		},
		listItem: {
			flexDirection: 'row',
			backgroundColor: 'transparent',
			padding: 0,
			borderBottom: '1px solid',
			margin: theme.spacing(0, 0, 2)
		},
		listItemWithBorder: {
			flexDirection: 'row',
			backgroundColor: 'transparent',
			borderBottom: '1px solid',
			padding: 0,
			margin: theme.spacing(0, 0, 2)
		},
		
		listItemRow: {
			padding: theme.spacing(0, 0, 1),
			display: 'flex',
			flex: 1,
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center'
		},
		listItemRowText: {
			maxWidth: '280px'
		},
		listItemText: {
			flex: 1,
			padding: 0,
			margin: 0,
			maxWidth: '280px'
		},
		subHeaderContainerImg: {
			height: '40px',
			width: '40px'
		}
	}),
);


export default function Home (props: any) {
	const classes = useStyles();
	let { UUID } = useParams<any>();
	const [loading, setLoading] = useState(true);
	const [error] = useState(false);
	const [menuData, setMenuData] = useState<null | CatalogItemsResponse>(null);

	useEffect(() => {
		if(!UUID) {
			return;
		}

		const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
		dataProvider(`b2c/menu/uuid/${UUID}`)
			.then(menuData => {
				setLoading(false)

				setMenuData(menuData)
			})
			.catch(e => {
				console.log(e)
			})
	}, [setLoading, UUID]);

		let content = <TopLevel title="Menu" loading={loading}/>;
		
		if(!loading && !error && menuData) {
			content = (
				<TopLevel title="Menu" loading={loading}>
					<Container component="main" maxWidth="xs"> 
					{
						menuData.categories
							.sort((a, b) => {
								if (a.displayOrder < b.displayOrder) {
									return -1;
								}
								if (b.displayOrder < a.displayOrder) {
									return 1;
								}
								return 0;
							}
							).map((category: CatalogCategory) => {
								const _items = menuData.items
									.filter((item: CatalogItem) => {
										return item.categoryId === category.id
									})
									.filter((item: CatalogItem) => {
										return item.menuDisplayType !== 'NO'
									})
									.sort((a: any, b: CatalogItem) => {
										if (a.displayOrder < b.displayOrder) {
											return -1;
										}
										if (b.displayOrder < a.displayOrder) {
											return 1;
										}
										return 0;
									});

								if(_items.length === 0)
									return null;

								return (
									<List key={category.id} style={{backgroundColor: 'transparent'}} className={classes.root}>
										<div className={classes.subHeaderContainer}>
											<Typography color="primary" variant="h6">{category.label}</Typography>
											<img alt={category.label} className={classes.subHeaderContainerImg} src={category.icon}></img>
										</div>
										{
											_items
												.map((item: CatalogItem) => {
													if(item.menuDisplayType === 'SMALL') {
														return <MyListItem item={item} key={item.unique}></MyListItem>
													}
													return <MyListItem item={item} key={item.unique} isBig={true}></MyListItem>
												})
										}
									</List>
								)
							})}
				</Container>
			</TopLevel>
		);
	}

	if(!props.user || !props.navigation) {
			return content;
	} else  {
		return (
			<Base loading={false} navigation={props.navigation} >
				{content}
			</Base>
		);
	}
	
}

const MyListItem = (props: any) => {
	const classes = useStyles();
	return  (
	<ListItem className={props.item.edible ? classes.listItemWithBorder : classes.listItem } disableGutters={true}>
		<ListItemText>
			<div className={classes.listItemRow}>
				<Typography className={classes.listItemText}>{props.item.label}</Typography>
				<Typography>{props.item.price.label}</Typography>
			</div>
			{props.item.edible && props.item.edible.diet !== DietType["Contiene derivati della carne o pesce"] &&
				<div className={classes.listItemRow}>
					<Typography className={classes.listItemRowText}>{`${props.item.edible.diet.toUpperCase()}`}</Typography>
				</div>}
			{props.item.edible && props.item.edible.allergensString &&
				<><Typography>Allergeni:</Typography><small className={classes.listItemRowText}>{props.item.edible.allergensString}</small></>
			}
			{props.item.edible && props.item.edible.ingredients && props.isBig && 
				<><Typography>Ingredienti:</Typography><small className={classes.listItemRowText}>{props.item.edible.ingredients}</small></>
			}
			{!props.item.edible && <small className={classes.listItemRowText}>Informazioni nutrizionali assenti</small>} 
		</ListItemText>
	</ListItem>
	);
}
