import React, { useState, useEffect, Fragment } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DataProvider from '../../provider';
import Button from '@material-ui/core/Button';
import Funnel from '../../templates/contents/Funnel';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';

const useStyles = makeStyles((theme: Theme) => ({
    cardContainer: {
        padding: theme.spacing(2, 2),
    },
	card: {
		width: '100%'
	},
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        width: '100%',
    },
    orderHeader: {
        margin: theme.spacing(0, 0, 2),
    },
    divider: {
        margin: theme.spacing(2, 0),
    },
}));

export interface B2CLockerSellingOrderCart {
    sme?: boolean;
    totalWithTax: number;
    items: Array<B2CLockerSellingOrderCartItem>;
    customSuccessMessage?: string | null;
}

export interface B2CLockerSellingOrderCartItem {
    id: number;
    itemSaleableId: number;
    label: string;
    quantity: number;
    totalWithTax: number;
    unitPriceWithTax: number;
}

export enum OrderDataStatus {
	FETCH_DATA = "fetch_data",
	FETCHING_DATA = "fetching_data",
	DATA_FETCHED = "data_fetched",
	ERROR_DATA_FETCHING = "error_data_fetching",
	FETCHING_DATA_MAX_ATTEMPT = "fetching_data_max_attempt",
    REQUEST_REOPEN = "request_reopen",
}

export enum OrderActionType {
	failed = "failed",
	completed = "completed",
	openingLocker = "openingLocker",
	openingLockerError = "openingLockerError",
	awaitingPayment = "awaitingPayment"
}

export default (props: any) => {

	let history = useHistory();
	let params = useParams();
	let id = params['id'];
	const [status, setStatus] = useState<OrderDataStatus>(OrderDataStatus.FETCH_DATA);
	const [orderStatus, setOrderStatus] = useState<OrderActionType>(OrderActionType.awaitingPayment);
    const [localCart, setLocalCart] = useState<B2CLockerSellingOrderCart | null>(null);
	const [retryCount, setRetryCount] = useState<number>(0);
    const [showReopenButton, setShowReopenButton] = useState<boolean>(false);
    const classes = useStyles();

	const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

	useEffect(() => {
		let timeout: null | NodeJS.Timeout = null;
        let reopenTimeout: null | NodeJS.Timeout = null;

		if(id && OrderDataStatus.FETCH_DATA === status) {
			if(retryCount < 7) {
				setStatus(OrderDataStatus.FETCHING_DATA);

				dataProvider(`b2c/locker/selling-order/info/${id}`)
					.then(info => {
						setStatus(OrderDataStatus.DATA_FETCHED);
						if(info.actionType === OrderActionType.completed) {
							if (reopenTimeout) {
								clearTimeout(reopenTimeout);
							}
							setShowReopenButton(true);
							reopenTimeout = setTimeout(() => {
								setShowReopenButton(false);
							}, 60000);
						}
                        setOrderStatus(info.actionType);
                        setLocalCart(info.lockerSellingOrder)
                        if(info.loading) {
                            if(timeout) {
                                clearTimeout(timeout);
                            }
                            timeout = setTimeout(() => {
                                setRetryCount(retryCount + 1);
                                setStatus(OrderDataStatus.FETCH_DATA);
                            }, 2000);
                        }
					})
					.catch(e => {
						setStatus(OrderDataStatus.ERROR_DATA_FETCHING);
					})
			}
			else {
				setStatus(OrderDataStatus.FETCHING_DATA_MAX_ATTEMPT);
			}
		}

		return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
			if(reopenTimeout) {
				clearTimeout(reopenTimeout);
			}
		}
    }, [id, status, retryCount, dataProvider, history])

    let content;

    if(status === OrderDataStatus.DATA_FETCHED) {
        switch (orderStatus) {
            case OrderActionType.openingLockerError:
                content = (<Content>
                    {localCart?.customSuccessMessage && <>
                        <Text><div dangerouslySetInnerHTML={{__html:localCart.customSuccessMessage.replaceAll("amp;", "")}}/></Text>
                    </>}
                    {!localCart?.customSuccessMessage &&<>
                        <Text>Il Fridge è stato sbloccato, aprilo entro 15 secondi e ritira il tuo ordine!</Text>
                        <Text>(la maniglia è a sinistra e trovi le posate in basso <span role="img" aria-label="Fork and Knife Emoji">🍴</span><span role="img" aria-label="Winking Face Emoji">😉</span>)</Text>
                    </>}
                    <Container className={classes.cardContainer} style={{paddingTop: '16px'}} component="main" maxWidth="xs">
                        <Card className={classes.card} >
                            <CardContent>
                                <Typography className={classes.orderHeader} color='primary' align='center' gutterBottom>
                                Dettaglio ordine
                                </Typography>
                                {localCart?.items.map((row, key) => {
                                    return (
                                        <Fragment key={`order-detail-row-${key}`}>
                                            <div className={classes.cardRow}>
                                                <Typography style={{flex: 3}} variant="body2" gutterBottom>
                                                    {row.label}
                                                </Typography>
                                                <Typography align="right" style={{flex: 1}} variant="body2" gutterBottom>
                                                    x {row.quantity}
                                                </Typography>
                                            </div>
                                            {localCart?.items.length - 1 !== key && <Divider className={classes.divider} />}
                                        </Fragment>)
                                })}
                            </CardContent>
                        </Card>
                    </Container>
                    <Text>Puoi visualizzare in qualsiasi momento i dettagli dei tuoi ordini nella sezione Lista Ordini del tuo profilo personale.</Text>
                    <Button
                        onClick={() => {
                            history.replace('/lista-ordini')
                        }}
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        LISTA ORDINI
                    </Button>
                    <Text>Il Frigo non si è aperto ?</Text>
                    <Button
                        onClick={() => {
                            setStatus(OrderDataStatus.REQUEST_REOPEN);
                            dataProvider(`b2c/locker/selling-order/info/${id}/retry-opening`)
                                .then(() => {
                                    setRetryCount(0);
                                    setStatus(OrderDataStatus.FETCH_DATA);
                                })
                                .catch(() => {
                                    setStatus(OrderDataStatus.DATA_FETCHED);
                                })
                        }}
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        RIPROVA APERTURA
                    </Button>
                    <Text>Se continui ad avere problemi puoi contattarci allo <a style={{color: 'black'}}href="tel:+390289763305">0289763305</a>.</Text>
                </Content>);
                break;
            case OrderActionType.completed:
                content = (<Content>
                    {localCart?.customSuccessMessage && <>
                        <Text><div dangerouslySetInnerHTML={{__html:localCart.customSuccessMessage.replaceAll("amp;", "")}}/></Text>
                    </>}
                    {!localCart?.customSuccessMessage &&<>
                        <Text>Il Fridge è stato sbloccato, aprilo entro 15 secondi e ritira il tuo ordine!</Text>
                        <Text>(la maniglia è a sinistra e trovi le posate in basso <span role="img" aria-label="Fork and Knife Emoji">🍴</span><span role="img" aria-label="Winking Face Emoji">😉</span>)</Text>
                    </>}
                    <Container className={classes.cardContainer} style={{paddingTop: '16px'}} component="main" maxWidth="xs">
                        <Card className={classes.card} >
                            <CardContent>
                                <Typography className={classes.orderHeader} color='primary' align='center' gutterBottom>
                                Dettaglio ordine
                                </Typography>
                                {localCart?.items.map((row, key) => {
                                    return (
                                        <Fragment key={`order-detail-row-${key}`}>
                                            <div className={classes.cardRow}>
                                                <Typography style={{flex: 3}} variant="body2" gutterBottom>
                                                    {row.label}
                                                </Typography>
                                                <Typography align="right" style={{flex: 1}} variant="body2" gutterBottom>
                                                    x {row.quantity}
                                                </Typography>
                                            </div>
                                            {localCart?.items.length - 1 !== key && <Divider className={classes.divider} />}
                                        </Fragment>)
                                })}
                            </CardContent>
                        </Card>
                    </Container>
                    <Text>Puoi visualizzare in qualsiasi momento i dettagli dei tuoi ordini nella sezione Lista Ordini del tuo profilo personale.</Text>
                    <Button
                        onClick={() => {
                            history.replace('/lista-ordini')
                        }}
                        fullWidth={true}
                        variant="contained"
                        size="large"
                        color="primary"
                    >
                        LISTA ORDINI
                    </Button>
                    {showReopenButton && <>
                        <Text>Hai dimenticato di ritirare qualcosa?</Text>
                        <Button
                            onClick={() => {
                                setStatus(OrderDataStatus.REQUEST_REOPEN);
                                dataProvider(`b2c/locker/selling-order/info/${id}/retry-opening`)
                                    .then(() => {
                                        setStatus(OrderDataStatus.DATA_FETCHED);
                                    })
                                    .catch(() => {
                                        setStatus(OrderDataStatus.DATA_FETCHED);
                                    })
                            }}
                            fullWidth={true}
                            variant="contained"
                            size="large"
                            color="primary"
                        >
                            Riapri il fridge
                        </Button>
                    </>}
                </Content>);
                break;
            case OrderActionType.failed:
                content = <Text>Ordine Fallito</Text>
                break;
            case OrderActionType.openingLocker:
                content = <Content><Text>Apertura Frigo in corso</Text> <CircularProgress color="primary"></CircularProgress></Content>
                break;
            case OrderActionType.awaitingPayment:
                content = <Content><Text> Pagamento in corso </Text></Content>
                break;
        }
    }

	if(status === OrderDataStatus.FETCHING_DATA_MAX_ATTEMPT) {
        content = (<Content>
                <Text>Ordine in corso</Text>
                <Button
                    onClick={() => {
                        setRetryCount(0);
                        setStatus(OrderDataStatus.FETCH_DATA);
                    }}
                    fullWidth={true}
                    variant="contained"
                    size="large"
                    color="primary"
                >
                    RICARICA
                </Button>
            </Content>
		);
    }

    return (
		<Funnel title={"Ordine"} loading={[OrderDataStatus.FETCHING_DATA, OrderDataStatus.FETCH_DATA, OrderDataStatus.REQUEST_REOPEN].includes(status)}>
			<Container component="main" maxWidth="xs">
                {content}
            </Container>
		</Funnel>
	);
}

function Content(prosp: any) {
    return <div style={{width: '100%', minHeight: '200px', padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        {prosp.children}
    </div>
}

function Text(prosp: any) {
    return (<Typography style={{padding: '16px', width: '100%', textAlign: 'center'}}>
        {prosp.children}
    </Typography>)
}