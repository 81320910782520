import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { PostMessage } from '../interfaces';
import FullPageLoading from '../../../../../components/FullPageLoading';

type STATE =
| 'SEND_CODE'
| 'ERROR_CODE'
| 'ERROR_FLOW';

export default function AddConfirm(props: any) {
    const location = useLocation();
    const edenredSearchParams = new URLSearchParams(location.search);
    const code = edenredSearchParams ? edenredSearchParams.get('code') : null
    const [state, setState] = useState<STATE>(code ? 'SEND_CODE' : 'ERROR_CODE');

    useEffect(() => {
        if(state === 'SEND_CODE' && code && window.opener) {
            const postMessage: PostMessage = {
                key: 'EDENRED_LOGIN',
                body: {
                    code,
                    status: 'success'
                }
            };
            window.opener.postMessage(postMessage, window.origin)
            window.close();
        } else if(state === 'ERROR_CODE' && window.opener) {
            const postMessage: PostMessage = {
                key: 'EDENRED_LOGIN',
                body: {
                    status: 'error'
                }
            };
            window.opener.postMessage(postMessage, window.origin)
            window.close();
        } else if(!window.opener) {
            setState('ERROR_FLOW');
        }
    }, [state, code]);

    switch (state) {
        case 'ERROR_FLOW':
        case 'ERROR_CODE':
            return (
                <>
                    <Typography>Ops! Sembra che qualcosa sia andato storto.</Typography>;
                    <Button
                            onClick={() => {
                                window.close();
                            }}
                            variant="contained"
                            size="large"
                            color="primary"
                        >
                            CHIUDI
                    </Button>
                </>
            );
        case 'SEND_CODE':
        default:
            return <FullPageLoading />;
            
    }
}