import React  from 'react';
import Hidden from '@material-ui/core/Hidden';
import { CartPreviewDrawer } from './CartPreview';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useWindowSize } from '../../../utils';
import { useSelector } from 'react-redux';
import { drawerWidth } from '../Index';
import CartComponent from './Cart';

const useStyles = (size) => makeStyles((theme: Theme) => ({
	drawer: {
		position: 'fixed',
		right: 0,
		bottom: 0,
		backgroundColor: 'white',
		boxShadow: '-4px 0px 5px 0px rgba(217,217,217,1)',
		height: `${size.height - 64}px`,
		zIndex: 10,
	},
	cartContainer: {
		width: drawerWidth,
		height: `${size.height - 64}px`,
		paddingBottom: '180px',
		overflow: 'scroll'
	}
}));

export default function Cart() {
	const size = useWindowSize();
	const classes = useStyles(size)();
	const cart = useSelector(state => state.cart);

	if(!cart)
		return null;

	return (
		<Hidden only={['xs', 'sm']}>
			<div className={classes.drawer}>
				<div className={classes.cartContainer}>
					<CartComponent></CartComponent>
				</div>
				<CartPreviewDrawer></CartPreviewDrawer>
			</div>
		</Hidden>
	);
}