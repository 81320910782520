import { Link } from './link';

export enum HomeDataSectionType {
	SHOP = "shop",
	LINK = "link",
	SHOP_PREVIEW = "shop_preview"
}

export interface HomeDataSection {
	type: HomeDataSectionType,
	data: any
}

export interface ShopDataSection extends HomeDataSection {
	type: HomeDataSectionType.SHOP,
	data: {
		title: string,
		shops: Link[]
	}
}

export interface LinkSection extends HomeDataSection {
	type: HomeDataSectionType.LINK,
	data: {
		text: string
		link: Link
	}
}
export interface ShopPreviewDataSection extends HomeDataSection {
	type: HomeDataSectionType.SHOP_PREVIEW;
	data: {
	  title: string;
	  cta: string;
	  shop: Link;
	};
}
export type SectionType =
	| ShopPreviewDataSection
	| ShopDataSection
	| LinkSection;

export interface HomeData {
	sections: SectionType[];
}

export interface CorporateCustomer {
	id: string,
	name: string,
}

export interface CustomJWT {
	id: number,
	email: string,
	mainUserId: number,
	iat: number,
	exp: number,
	corporateCustomers: CorporateCustomer[]
};