export const PushDataKeyList = [
	"b2cImpulseCatalog",
	"trackingEvent",
	"event",
	"appBuildNum"
];

export type PushDataKey = typeof PushDataKeyList[number];

export function pushData(key: PushDataKey, data: any) {
	if(window['dataLayer'] === undefined) {
		window['dataLayer'] = [];
	}

	window['dataLayer'].push({
		[key]: undefined
	})

	window['dataLayer'].push({
		[key]: data
	})
}

export const PushEventTypeList = ["TrackingEvent", "ViewItemList"];
export type PushEventType = typeof PushEventTypeList[number];

export const PushEventDataCategoryList = ["Error"];
export type PushEventDataCategory = typeof PushEventDataCategoryList[number];

export const PushEventDataActionList = ["FirebaseAuth"];
export type PushEventDataAction = typeof PushEventDataActionList[number];

export function pushEvent(eventData: {
	type: PushEventType,
	data: any,
	dataLabel: PushDataKey,
}) {
	pushData(eventData.dataLabel, eventData.data);
	pushData("event", eventData.type);
}

export function pushTrackingEvent(eventData: {
	type: PushEventType,
	data: {
		category: PushEventDataCategory,
		action: PushEventDataAction,
		label: string
	},
}) {
	eventData.data.label = eventData.data.label.substring(0, 490);
	pushData("trackingEvent", eventData.data);
	pushData("event", eventData.type);
}