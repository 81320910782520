import React, { useEffect, useState, useCallback } from 'react';
import { useHistory } from "react-router-dom";
import { Box, Typography, Button, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataProvider from '../provider';
import { reverse_route } from '../reducers/currentPage';
import { useDispatch } from 'react-redux'
import { updateCurrent } from '../actions';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';

type TRadio = 'YES' | 'NO' | 'NOT_SELECTED'
type TStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' |'SEND_DATA' | 'SENDING_DATA' | 'ERROR_SELECTED'

const useStyles = makeStyles((theme) => ({
  main : {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(7),
  },
  link : {
      color : "black",
      fontWeight: "bold"
  },
  formeLabel: {
    marginRight: 'auto',
  },
  circularProgress: {
    width : "25px",
    height: "25px"
  },
  radioGroup : {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  radioGroupProfiling:  {
    paddingTop: theme.spacing(1), 
    paddingBottom: theme.spacing(1),
  }
}));


function PrivacyBanner(props: any) {
      const history = useHistory();
	    const classes = useStyles();
      const dispatch = useDispatch();

      const [status, setStatus] = useState<TStatus>('FETCH_DATA');
      const [acceptProfilingData, setAcceptProfilingData] = useState<TRadio>('NOT_SELECTED');
      const [acceptMarketingData, setAcceptMarketingData] = useState<TRadio>('NOT_SELECTED');

      const handleRedirect = useCallback(() => {
        let redirect = window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/";
        if (reverse_route[redirect]) {
            dispatch(updateCurrent(reverse_route[redirect]));
          }
        window.localStorage.removeItem('redirect_to') 
        history.replace(redirect);
      }, [dispatch, history])

      useEffect(() =>{
        if(status === 'FETCH_DATA') {
          setStatus('FETCHING_DATA')
          const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
          dataProvider(`b2c/privacy-policy`, {
            method: 'GET',
          }).then((data: {
            success: boolean;
            data: {
              acceptMarketingData: boolean;
              acceptProfilingData: boolean;
            }
          }) => {
            if(data.success) {
              typeof data.data.acceptMarketingData === 'boolean' && setAcceptMarketingData(data.data.acceptMarketingData ? 'YES' : 'NO')
              typeof data.data.acceptProfilingData === 'boolean' && setAcceptProfilingData(data.data.acceptProfilingData ? 'YES' : 'NO')
            }
            setStatus('DATA_FETCHED');
          }).catch(() => {
            setStatus('DATA_FETCHED');
          })
        }

        if(status === 'SEND_DATA') {
          if(acceptMarketingData === 'NOT_SELECTED' || acceptProfilingData === 'NOT_SELECTED') {
            setStatus('ERROR_SELECTED')
          } else {
            setStatus('SENDING_DATA')
            const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
              dataProvider(`b2c/privacy-policy`, {
                method: 'POST',
                body: JSON.stringify({
                  data: {
                    acceptProfilingData: acceptProfilingData === 'YES' ? true : false,
                    acceptMarketingData: acceptMarketingData === 'YES' ? true : false,
                  }
                })
              }).then(() => {
                handleRedirect()
              }).catch(() => {
                handleRedirect()
              })
          }
          
          }
      }, [status, acceptProfilingData, acceptMarketingData, handleRedirect]);

  const isFetching = ['FETCH_DATA', 'FETCHING_DATA', 'SEND_DATA', 'SENDING_DATA'].includes(status);

	return (
    <Container className={classes.main} maxWidth="xs">
        <Typography gutterBottom variant='h4'>Gestisci i consensi</Typography>
        <Typography variant='body2'>
            Scegli come farci utilizzare i tuoi dati personali (lo faremo sempre a norma di legge).
            Se vuoi saperne di più, consulta <a className={classes.link} href='https://www.foorban.com/privacy'> l'informativa privacy</a>: è un documento un po' tecnico, ma ci trovi scritto tutto.
        </Typography>
        <Box padding={2} marginTop={2} marginBottom={2} borderRadius={4} boxShadow={1}>
          <Typography variant='body2'>
            ll Consumatore dichiara di avere preso visione dell’Informativa sul Trattamento dei Dati Personali effettuata da Foorban S.r.l. e di accettare i termini e condizioni generali di Foorban S.r.l.
          </Typography>
          <RadioGroup 
            className={classes.radioGroup}  
            value={acceptMarketingData}
            onChange={(event) => setAcceptMarketingData(event.target.value as TRadio)} 
            >
            <Typography className={classes.formeLabel} variant='body2'><strong>Attività di marketing</strong></Typography>
            <FormControlLabel disabled={isFetching} value="YES" control={<Radio color='primary' />} label="Si" />
            <FormControlLabel disabled={isFetching} value="NO" control={<Radio color='primary'  />} label="No" />
          </RadioGroup>
          {status === 'ERROR_SELECTED' && acceptMarketingData === 'NOT_SELECTED' && (
            <Typography variant='body2' color='error'>
              Devi fare una scelta.
            </Typography>
          )}
          <Typography variant='body2'>
            L’Utente presta separato ed espresso consenso opzionale al trattamento dei dati personali da parte di Foorban S.r.l. per le finalità promozionali, commerciali e di marketing illustrate nell’Informativa, paragrafo 3, lettera b);
          </Typography>
          <RadioGroup 
            className={`${classes.radioGroup} ${classes.radioGroupProfiling}`}  
            value={acceptProfilingData}
            onChange={(event) => setAcceptProfilingData(event.target.value as TRadio)} 
            >
            <Typography  className={classes.formeLabel} variant='body2'><strong>Profilazione per  <br /> attività di marketing</strong></Typography>
            <FormControlLabel disabled={isFetching} value="YES" control={<Radio color='primary' />} label="Si" />
            <FormControlLabel disabled={isFetching} value="NO" control={<Radio color='primary' />} label="No" />
          </RadioGroup>
          {status === 'ERROR_SELECTED' && acceptProfilingData === 'NOT_SELECTED' && (
            <Typography variant='body2' color='error'>
              Devi fare una scelta.
            </Typography>
          )}
          <Typography variant='body2'>L’Utente presta separato ed espresso consenso opzionale al trattamento dei dati personali da parte di Foorban S.r.l. per le finalità di analisi statistica e profilazione delle proprie abitudini di consumo illustrate nell’Informativa, paragrafo 3, lettera d).</Typography>
        </Box>
        <Button disabled={isFetching}  onClick={() => {setStatus('SEND_DATA')}} variant='contained' color='primary' fullWidth>{isFetching ? <CircularProgress size="small" className={classes.circularProgress} color="inherit" /> : "Prosegui"}</Button>
    </Container>
	);
}


export default PrivacyBanner;