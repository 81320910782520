import { UPDATE_WALLET_BALANCE } from '../';

const wallet = (state = null, action) => {
	switch (action.type) {
	case UPDATE_WALLET_BALANCE:
		return {
			balance: action.balance
		};
	default:
		return state
	}
}

export default wallet;