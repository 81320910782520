import React from 'react';
import { useSelector } from 'react-redux';
import { BannerState } from './state/reducers/banner';
import { Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useHistory, useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';

const useStyles = makeStyles((theme) => ({
	baseTitle: {
	},
	lightOrangeTitle: {
		color: 'rgb(232, 84, 21)',
	},
	lightGreenTitle: {
		color: 'rgb(8, 109, 65)',
	},
	darkOrangeTitle: {
		color: 'white',
	},
	baseContainer: {
		borderRadius: '4px',
		margin: theme.spacing(2, 2, 0),
		padding: theme.spacing(1, 2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	lightOrangeContainer: {
		backgroundColor: 'rgba(232, 84, 21, 0.15)',
	},
	lightGreenContainer: {
		backgroundColor: 'rgba(8, 109, 65, 0.15)',
	},
	darkOrangeContainer: {
		backgroundColor: 'rgb(232, 84, 21)',
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1)
	},
	cardRoot: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	cardMedia: {
		[theme.breakpoints.down('sm')]: {
			width: '0',
		},
		[theme.breakpoints.up('sm')]: {
			width: '296px',
		},
	},
	cardContent: {
		display: 'flex',
		width: '100%',
		flexFlow: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '40px',
	},
	cardTitle: {
		width: '100%'
	},
	descriptionHtml: {
		width: '100%',
		flex: '1 0 auto',
		height: '0px',
		overflow: 'scroll',
		margin: theme.spacing(1, 0, 2),
	},
	baseButton: {
		lineHeight: 1.5,
	}
}));

function Banner(props: any) {
	const banner: BannerState = useSelector((state: any) => state.banner);
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const mySearchParams = new URLSearchParams(location.search);

	if (!banner || (banner && banner.status === 'OFF')) {
		return null;
	}

	if (banner && ['ONLY-TITLE', 'ON'].includes(banner.status)) {
		return <>
			<div onClick={() => {
				banner.status === 'ON' && history.push('?banner=open');
			}} className={`${classes.baseContainer} ${{
				'LIGHT_ORANGE': classes.lightOrangeContainer,
				'LIGHT_GREEN': classes.lightGreenContainer,
				'DARK_ORANGE': classes.darkOrangeContainer,
			}[banner.style]}`}>
				<Typography className={`${classes.baseTitle} ${{
					'LIGHT_ORANGE': classes.lightOrangeTitle,
					'LIGHT_GREEN': classes.lightGreenTitle,
					'DARK_ORANGE': classes.darkOrangeTitle,
				}[banner.style]}`}><strong>{banner.title}</strong></Typography>
				{banner.status === 'ON' && banner.content && <Typography variant="button" className={`${classes.baseButton} ${{
					'LIGHT_ORANGE': classes.lightOrangeTitle,
					'LIGHT_GREEN': classes.lightGreenTitle,
					'DARK_ORANGE': classes.darkOrangeTitle,
				}[banner.style]}`}>SCOPRI DI PIÙ</Typography>}
			</div>
			{banner.status === 'ON' && banner.content && <Dialog fullScreen={isWidthDown('xs', props.width)} fullWidth={true} onClose={history.goBack} open={mySearchParams.get('banner') === 'open'}>
				<DialogContent style={{ width: '100%' }}>
					<Card className={classes.cardRoot}>
						<IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={history.goBack}>
							<CloseIcon />
						</IconButton>
						<CardContent className={classes.cardContent}>
							<Typography className={classes.cardTitle} component="h5" variant="h5" color="primary">
								{banner.title}
							</Typography>
							<div className={classes.descriptionHtml}>
								<div dangerouslySetInnerHTML={{ __html: banner.content }} />
							</div>
						</CardContent>
					</Card>
				</DialogContent>
			</Dialog>}
		</>
	}

	return null;
}
export default withWidth()(Banner);

const DialogContent = withStyles((theme) => ({
	root: {
		height: '393px',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		[theme.breakpoints.up('md')]: {
			width: '645px',
		},
		padding: theme.spacing(0),
		"&:first-child": {
			paddingTop: 0,
		}
	}
}))(MuiDialogContent);