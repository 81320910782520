import React, { useState, useEffect, useRef, useCallback } from 'react';
import { IconButton, Radio, Typography, Button, Dialog, DialogActions, DialogContent, Box, Snackbar, Select, MenuItem } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';
import withWidth, { isWidthDown, WithWidth } from '@material-ui/core/withWidth';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { SellingAreaState, DietPreference, Allergen } from '../state';
import { AllergensListLabel, DietAction, ALLERGEN_TYPE_VALUES, AllergenTypeLabel, DIET_VALUES, DietLabel } from '../state/reducers/diet';
import CloseIcon from '@material-ui/icons/Close';
import DataProvider from '../../../provider';
import Alert from '@material-ui/lab/Alert';
import { BaseResponse } from '../../../interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    span: {
        backgroundColor: theme.palette.error.light,
        color: theme.palette.text.primary,
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(0.5)
    },
	closeButton: {
		position: 'absolute',
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1)
	},
    selectBox: {
        marginLeft: "auto",
        minWidth: '200px',
        maxWidth: '200px'
    },
    textPadding: {
        padding: `${theme.spacing(0.75)}px 0`
    }
}));

const defaultDietPreference: DietPreference & {
    allergens: Allergen
} = {
    allergens: {},
    diet: undefined,
};

Object.keys(AllergensListLabel).forEach(key => {
    defaultDietPreference.allergens[key] = "Presente";
});

function DialogDietPreference(props: WithWidth) {
    const history = useHistory();
    const location = useLocation();
    const mySearchParams = new URLSearchParams(location.search);
    const [state, setState] = useState<'AWAIT_DATA' | 'SEND_DATA' | 'SENDING_DATA' | 'ERROR_SENDING_DATA' | 'DELETE_ALL_PREFERENCE'>('AWAIT_DATA')
    const [snapShot, setSnapShot] = useState<DietPreference>(defaultDietPreference)
    const classes = useStyles();
    const diet: DietPreference | null = useSelector((state: SellingAreaState) => state.diet);
    const dispatch = useDispatch();
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const prevDietPreference = useRef<string>(mySearchParams.get('diet_preference') || 'not_show');

    useEffect(() => {
        const diet_preference = mySearchParams.get('diet_preference') || 'not_show';
        if(diet_preference !== prevDietPreference.current) {
            setSnapShot(diet ? JSON.parse(JSON.stringify(diet)) : defaultDietPreference);
        }
        prevDietPreference.current = mySearchParams.get('diet_preference') || 'not_show';
    }, [diet, mySearchParams]);

    const sendData = useCallback((deleteData?: boolean) => {
        setState('SENDING_DATA');
        dataProvider(`b2c/diet-preference`, {
            method: 'POST',
            body: JSON.stringify(deleteData ? {
                diet: null,
                allergens: null,
            } : snapShot)
        })
        .then((data: BaseResponse) => {
            if(data.success) {
                const updateDietPreference: DietAction = deleteData ? {
                    type: 'DELETE_DIET_PREFERENCE',
                } : {
                    type: 'UPDATE_DIET_PREFERENCE',
                    data: snapShot,
                };
                dispatch(updateDietPreference);
                setState('AWAIT_DATA');
                history.goBack();
            } else {
                throw new Error();
            }
        }).catch(() => {
            setSnapShot(diet ? JSON.parse(JSON.stringify(diet)) : defaultDietPreference);
            setState('ERROR_SENDING_DATA');
            setTimeout(() => {
                setState('AWAIT_DATA');
            }, 2000)
        });
    }, [dataProvider, diet, dispatch, history, snapShot]);

    useEffect(() => {
        switch (state) {
            case 'SEND_DATA':
                sendData();
                break;
            case 'DELETE_ALL_PREFERENCE':
                sendData(true);
                break;
        }
    }, [state, sendData]);

    return (
        <Dialog disableBackdropClick disableEscapeKeyDown fullScreen={isWidthDown('xs', props.width)} fullWidth={true} open={mySearchParams.get('diet_preference') === 'show'}>
            <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={history.goBack}>
				<CloseIcon />
			</IconButton>
            <DialogContent >
                <Typography gutterBottom variant="h6" align="center">Filtro preferenze</Typography>
                <Typography gutterBottom variant="subtitle1" component="div">
                    Evidenzieremo in <span className={classes.span}>rosso</span> tutti prodotti che non corrispondono alle tue preferenze. Per la tua sicurezza ricordati sempre di controllare gli ingredienti e gli allergeni sulla confezione.
                </Typography>
                <Box style={['DELETE_ALL_PREFERENCE', 'SEND_DATA', 'SENDING_DATA'].includes(state) ? {opacity: 0.4, pointerEvents: 'none'}: {}} marginTop="8px" marginBottom="8px">
                    <Typography variant='subtitle2'>Preferenze</Typography>
                    {DIET_VALUES.map((value) => {
                        return <Box onClick={() => {
                            if(snapShot) {
                                setSnapShot({ ...snapShot, diet: value });
                            } else {
                                setSnapShot({
                                    diet: value,
                                } as any);
                            }  
                        }} key={value} display="flex" alignItems='center'>
                            <Radio
                                size="small"
                                color='primary'
                                checked={snapShot?.diet === value}
                            />
                            <Typography variant='subtitle2'>{DietLabel[value]}</Typography>
                        </Box>
                    })}
                </Box>
                <Typography variant='subtitle2' gutterBottom>
                    Quali di questi allergeni vuoi mangiare ?
                </Typography>
                {Object.keys(AllergensListLabel).map((key) => {
                    return (
                        <Box key={key} display={"flex"} alignItems={"center"} style={['DELETE_ALL_PREFERENCE', 'SEND_DATA', 'SENDING_DATA'].includes(state) ? {opacity: 0.4, pointerEvents: 'none'}: {}} marginBottom="8px">
                            <Typography className={classes.textPadding} variant='subtitle2'>{AllergensListLabel[key]}:</Typography>
                            <Select
                                className={classes.selectBox}
                                value={snapShot?.allergens ? snapShot?.allergens[key] : ''}
                                onChange={(event) => {
                                    setSnapShot({ ...snapShot, allergens: { ...snapShot?.allergens, [key]: event.target.value } })
                                }}
                            >
                            {ALLERGEN_TYPE_VALUES.map((value) => {
                                return <MenuItem key={key + value} value={value}>{<Typography noWrap variant='subtitle2'>{AllergenTypeLabel[value]}</Typography>}</MenuItem>
                            })
                            }
                            </Select>
                        </Box>
                    )
                })}
            </DialogContent>
            <DialogActions style={['DELETE_ALL_PREFERENCE', 'SEND_DATA', 'SENDING_DATA'].includes(state) ? {opacity: 0.4, pointerEvents: 'none'}: {}}>
                <Button disabled={!snapShot || !diet} color="primary" variant="outlined" onClick={() => {
                    setState('DELETE_ALL_PREFERENCE');
                }}>Cancella preferenze</Button>
                <Button disabled={!snapShot} color="primary" variant="contained" onClick={() => {
                    setState('SEND_DATA');
                }}>Applica</Button>
            </DialogActions>
            <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} open={state === 'ERROR_SENDING_DATA'}>
                <Alert severity="warning">
                    Errore durante l'invio dei dati.
                </Alert>
			</Snackbar>
        </Dialog>
    );
}

export default withWidth()(DialogDietPreference);
