import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Modal from '../../templates/contents/Modal';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DataProvider from '../../provider';
import { InfoError } from './interfaces';

const StatusList = [
    'FETCH_DATA',
    'FETCHING_DATA',
    'ERROR_FETCHING_DATA',
    'FETCHED',
    'SEND_DATA',
    'SENDING_DATA',
    'ERROR_SENDING_DATA',
    'DATA_SENDED',
    'VALIDATION_ERROR'
] as const;

type Status = typeof StatusList[number];

const StatusListLoading: Status[] = [
    'FETCH_DATA',
    'FETCHING_DATA',
    'SEND_DATA',
    'SENDING_DATA',
];

export interface B2CUserInfo {
    email: string;
    nickName?: string;
}
 
const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    text: {
        margin: theme.spacing(4, 0)
    },
    textError: {
        margin: theme.spacing(1, 0),
        color: 'red',
    }
}));

export default function CollectNickName(props: any)  {
    const history = useHistory();
    const [status, setStatus] = useState<Status>('FETCH_DATA');
    const [nickName, setNickName] = useState<string>('');
    const classes = useStyles();
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

    useEffect(() => {
        switch (status) {
            case 'FETCH_DATA':
                setStatus('FETCHING_DATA');
                dataProvider(`b2c/info`)
                    .then((rawInfo: B2CUserInfo | InfoError) => {
                        if((rawInfo as InfoError).error) {
                            setStatus('ERROR_FETCHING_DATA');
                            return;
                        }
                        const info: B2CUserInfo = rawInfo as B2CUserInfo;
                        if(info.nickName) {
                            setNickName(info.nickName);
                        }
                        setStatus('FETCHED');
                    })
                    .catch(e => {
                        setStatus('ERROR_FETCHING_DATA')
                    })
                break;
            case 'SEND_DATA':
                if(nickName.length === 0) {
                    setStatus('VALIDATION_ERROR');
                    break;
                }
                setStatus('SENDING_DATA');
                dataProvider(`b2c/info`, {
                    method: 'POST',
                    body: JSON.stringify({nickName})
                })
                .then((rawInfo: B2CUserInfo | InfoError) => {
                    if((rawInfo as InfoError).error) {
                        setStatus('ERROR_SENDING_DATA')
                        return;
                    }
                    const info: B2CUserInfo = rawInfo as B2CUserInfo;
                    if(info.nickName !== nickName) {
                        setStatus('ERROR_SENDING_DATA')
                    }
                    setStatus('DATA_SENDED'); 
                })
                .catch(e => {
                    setStatus('ERROR_SENDING_DATA')
                })
                break;
            case 'DATA_SENDED':
                history.goBack();
                break;
        }
        return () => {}
    }, [status, nickName, history, dataProvider])

    return (
        <Modal title="Modifica Nome" loading={StatusListLoading.includes(status)} action={history.goBack}>
            <Container className={classes.container} maxWidth="xs">
                <Typography variant="h6">Come ti chiami?</Typography>
                <TextField autoFocus error={status=== 'VALIDATION_ERROR'} fullWidth label="Nome" value={nickName} onChange={(event) => {
                    if(event.target.value.length > 10) {
                        setStatus('VALIDATION_ERROR');
                    } else {
                        setStatus('FETCHED');
                    }
                    let value = '';
                    if (event.target.value.length > 0) {
                        value = event.target.value[0].toUpperCase() + event.target.value.substring(1);
                    }
                    setNickName(value)
                }} />
                {status === 'VALIDATION_ERROR' && <Typography className={classes.textError} variant="subtitle1">Lunghezza massima consentita 10 caratteri.</Typography>}
                {status === 'ERROR_FETCHING_DATA' && <Typography className={classes.textError} variant="subtitle1">Errore caricamento dati.</Typography>}
                {status === 'ERROR_SENDING_DATA' && <Typography className={classes.textError} variant="subtitle1">Errore invio dati.</Typography>}
                <Typography className={classes.text} variant="subtitle1">Visualizzerai il tuo nome sulla macchina del caffé.</Typography>
                <Button color="primary" variant="contained" size="large" disabled={status === 'VALIDATION_ERROR'} onClick={() => {
                    setStatus('SEND_DATA');
                }}>SALVA</Button>
            </Container>
        </Modal>
    );
}