import React, { Suspense } from 'react';
import { Order } from './interfaces';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { useLocation, useHistory } from 'react-router-dom';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import moment from 'moment';
import 'moment/locale/it';
import { ErrorBoundary } from './FullOrderErrorBoundary';
import Modal from '../../templates/contents/Modal';
const FullOrder = React.lazy(() => import('./FullOrder'));

const useStyles = makeStyles((theme: Theme) => ({
	cardAction: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
        padding: theme.spacing(0, 0, 1),
	},
	buttonBaseContainer: {
        width: '100%',
		margin: theme.spacing(0, 0, 2),
	},
	card: {
		width: '100%'
	},
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        width: '100%',
    },
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & { children?: React.ReactElement },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});
  
export function OrderListItem(props: {
	order: Order;
    email: string;
}) {
	const classes = useStyles();
    const location = useLocation();
	const history = useHistory();
	const mySearchParams = new URLSearchParams(location.search);

    const calculatedQuantity = props.order.rows.map(row => row.quantity).reduce((acc, curr) => {
        return acc + curr;
    });
    return (
        <>
        <ButtonBase onClick={() => {
            if(location.search.length > 0) {
                history.push(`${location.search}&order=${props.order.id}`)
            }
            else {
                history.push(`?order=${props.order.id}`)
            }
        }}className={classes.buttonBaseContainer}>
            <Card className={classes.card} >
                <CardContent>
                    <div className={classes.cardRow}>
                        <Typography gutterBottom>
                            {moment(props.order.created_at).format('lll')}
                        </Typography>
                        <Typography gutterBottom color={props.order.status === 'COMPLETED' ? 'primary' : 'textSecondary'}>
                            {props.order.status === 'COMPLETED' ? 'Completato' : 'In Attesa'}
                        </Typography>
                    </div>
                    <div className={classes.cardRow}>
                        {calculatedQuantity === 1 && <Typography align="left" style={{flex: 3}} variant="body2" gutterBottom>
                        {props.order.rows[0].label}
                        </Typography>}
                        {calculatedQuantity > 1 && <Typography align="left" style={{flex: 3}} variant="body2" gutterBottom>
                            {`${calculatedQuantity} prodotti`}
                        </Typography>}
                        <Typography align="right" style={{flex: 1}}  variant="body2" gutterBottom>
                            {props.order.totalWithTax} €
                        </Typography>
                    </div>
                </CardContent>
                <CardActions className={classes.cardAction}>
                    <Typography variant="overline" color="primary">VEDI ORDINE</Typography>
                </CardActions>
            </Card>
        </ButtonBase>
        <Dialog fullScreen={true} TransitionComponent={Transition} onClose={history.goBack} aria-labelledby="customized-dialog-title" open={mySearchParams.get('order') === props.order.id.toString()}>
            <DialogContent style={{padding: 0}}>
                <ErrorBoundary goBack={history.goBack}>
                    <Suspense fallback={<Modal title="Caricamento Ordine" loading={true} action={history.goBack}></Modal>}>
                        <FullOrder {...props} />
                    </Suspense>
                </ErrorBoundary>
            </DialogContent>
		</Dialog>
        </>
    );
}