import { combineReducers } from 'redux'
import cart from './cart';
import catalog from './catalog';
import status from './status';
import actions from './actions';
import data from './data';
import message from './message';
import pendingRequestCounter from './pendingRequestCounter';
import wallet from './wallet';
import corporateCredit from './corporateCredit';
import paymentPreferences from './paymentPreferences';
import banner from './banner';
import diet from './diet';

export default combineReducers({
	cart,
	catalog,
	status,
	actions,
	data,
	message,
	pendingRequestCounter,
	wallet,
	corporateCredit,
	paymentPreferences,
	banner,
	diet,
});