import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import { useHistory, useLocation } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import MuiDialogContent from '@material-ui/core/DialogContent';

const CustomDialogContent = withStyles((theme) => ({
  root: {
    height: '393px',
    [theme.breakpoints.down('md')]: {
      width: '100%'
    },
    [theme.breakpoints.up('md')]: {
      width: '645px',
    },
    padding: theme.spacing(0),
    "&:first-child": {
      paddingTop: 0,
    }
  }
}))(MuiDialogContent);

const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(0),
    top: theme.spacing(0),
    padding: theme.spacing(1)
  },
  cardRoot: {
    display: 'flex',
    height: '100%',
    width: '100%'
  },
  cardContent: {
    display: 'flex',
    width: '100%',
    flexFlow: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '40px',
  },
  cardTitle: {
    width: '100%'
  },
  descriptionHtml: {
    width: '100%',
    flex: '1 0 auto',
    height: '0px',
    overflow: 'scroll',
    margin: theme.spacing(1, 0, 2),
  },
}));

function UnsellableItemInfoPopup(props: any) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const mySearchParams = new URLSearchParams(location.search);

  return (
    <Dialog
      fullScreen={isWidthDown('xs', props.width)}
      fullWidth={true}
      onClose={history.goBack}
      open={mySearchParams.get('unsellable-item-info-banner') === 'open'}
    >
      <CustomDialogContent style={{ width: '100%' }}>
        <Card className={classes.cardRoot}>
          <IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={history.goBack}>
            <CloseIcon />
          </IconButton>
          <CardContent className={classes.cardContent}>
            <Typography className={classes.cardTitle} component="h5" variant="h5" color="primary">
              {"Non acquistabile"}
            </Typography>
            <div className={classes.descriptionHtml}>
              <div dangerouslySetInnerHTML={{
                __html: "Desideriamo informarti che l'acquisto di questo prodotto è temporaneamente e preventivamente sospeso a causa di un difetto nel packaging riscontrato durante il controllo qualità sui nostri campioni in magazzino."
              }} />
            </div>
          </CardContent>
        </Card>
      </CustomDialogContent>
    </Dialog>)
}

export default withWidth()(UnsellableItemInfoPopup);