import React, { Component, ErrorInfo } from 'react';
import Modal from '../../templates/contents/Modal';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { pushTrackingEvent } from '../../utils/gtmDataLayer';

interface IProps {
  goBack: () => void;
}

interface IState {
    hasError: boolean;
}

export class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: IProps) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError() {
      return { hasError: true };
    }
  
    componentDidCatch(error: Error, errorInfo: ErrorInfo) {
      let errorInfoString = '';
      try {
        errorInfoString = JSON.stringify(errorInfo,null,2);
      } catch {}
       pushTrackingEvent({
          type: "TrackingEvent",
          data: {
              category:"Error", action:"FullOrder", label: `${error.toString()} ${errorInfoString}`
          }
      });
    }
  
    render() {
      if (this.state.hasError) {
        return <Modal title="Errore caricamento Ordine" loading={false} action={this.props.goBack}>
            <Container component="main" maxWidth="xs">
                <Box paddingTop={2}>
                  <Typography gutterBottom align="center" variant="subtitle1">Ops.. C'è stato un errore durante il caricamento dell'ordine.</Typography>
                  <Button fullWidth color="primary" variant="contained" size="large" onClick={this.props.goBack}>INDIETRO</Button>
                </Box>
            </Container>
        </Modal>
      }
  
      return this.props.children;
    }
}