export interface CatalogItem {
    unique: number;
    id: number;
    shortDescription: string;
    description : string;
    price: CatalogItemPrice;
    label: string;
    categoryId : number;
    image: string;
    displayOrder: number;
    edible?: any;
    new: boolean;
    showInImpulse: boolean;
    showInScreenMenu: string;
    menuDisplayType: string;
    tag?: string;
}

export interface CatalogItemPrice {
    label: string;
    value: number;
}

export interface CatalogCategory {
    id: number;
    label: string;
    image: string
    icon: string;
    displayOrder: number;
}

export interface CatalogItemsResponse {
    items: Array<CatalogItem>;
    categories: Array<CatalogCategory>;
}

export enum DietType {
    "Contiene derivati della carne o pesce" = "Contiene derivati della carne o pesce",
    Pescetariano = "Pescetariano",
    Vegetariano = "Vegetariano",
    Vegano = "Vegano"
}