import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Switch from '@material-ui/core/Switch';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
		borderRadius: 0,
		textTransform: 'none',
		padding: theme.spacing(2, 3),
		backgroundColor: 'rgba(8, 109, 65, 0.15)'
	},
	title: {
		color: 'rgba(0, 0, 0, 0.54)',
		width: '100%',
	},
    divider: {
		margin: theme.spacing(0, 0, 1),
	},
    credit: {
        display: 'flex',
        alignItems: 'center',
    }
}));

export default function InternalPayments(props: {
    showToggleWallet: boolean;
    showToggleCorporateCredit: boolean;
    corporateCreditBalance: number;
    walletBalance: number;
    updateCartTotal?: (checked: boolean) => void
}) {
    const classes = useStyles();

    const [checked, setChecked] = useState<boolean>(localStorage.getItem('corporateCreditToggle') ? (new Date()).getTime() - parseInt(localStorage.getItem('corporateCreditToggle') || '0') > 43200000 : true);
    const [walletChecked, setWalletChecked] = useState<boolean>(localStorage.getItem('walletToggle') ? (new Date()).getTime() - parseInt(localStorage.getItem('walletToggle') || '0') > 43200000 : true);

    if (props.corporateCreditBalance === 0 && props.walletBalance === 0) {
        return null;
    }

    return <div className={classes.container}>
            {props.corporateCreditBalance > 0 ? <div className={classes.credit}>
                <Typography className={classes.title}>Buono aziendale: <strong>{props.corporateCreditBalance} €</strong></Typography>
                {props.showToggleCorporateCredit && <Switch
                    defaultChecked
                    checked={checked}
                    color='primary'
                    onChange={(e) => {
                        if(e.target.checked) {
                            localStorage.removeItem('corporateCreditToggle');
                            setChecked(true);
                        } else {
                            localStorage.setItem('corporateCreditToggle', `${(new Date()).getTime()}`);
                            setChecked(false);
                        }
                        props.updateCartTotal && props.updateCartTotal(e.target.checked)
                    }}
                    name="corporateCredit"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
            </div> : null}
            {props.corporateCreditBalance > 0 && props.walletBalance > 0 ?  <Divider className={classes.divider} light /> : null}
            {props.walletBalance > 0 ? <div className={classes.credit}>
                <Typography className={classes.title}>Portafoglio: <strong>{props.walletBalance} €</strong></Typography>
                {props.showToggleWallet && <Switch
                    defaultChecked
                    checked={walletChecked}
                    color='primary'
                    onChange={(e) => {
                        if(e.target.checked) {
                            localStorage.removeItem('walletToggle');
                            setWalletChecked(true);
                        } else {
                            localStorage.setItem('walletToggle', `${(new Date()).getTime()}`);
                            setWalletChecked(false);
                        }
                        props.updateCartTotal && props.updateCartTotal(e.target.checked)
                    }}
                    name="wallet"
                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                />}
            </div> : null}
    </div>;
}
