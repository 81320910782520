import React, { useEffect, useState, useRef } from 'react';
import Modal from '../../../../templates/contents/Modal';
import Container from '@material-ui/core/Container';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import DataProvider from '../../../../provider';
import { useHistory, useLocation } from 'react-router-dom';
import { ClientToken } from 'braintree';
import { client, paypalCheckout } from 'braintree-web';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { CrossStoreType, setKey } from '../../../../cross-store';
import { loadScript } from '@paypal/paypal-js';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { pushTrackingEvent } from '../../../../utils/gtmDataLayer';

const BUTTON_ID = 'paypal-button';

export enum StatusAddCard {
  LOADING = 'LOADING',
  ADDING_CARD = 'ADDING_CARD',
  SERVER_SYNC = 'SERVER_SYNC',
}

const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(4, 0),
      height: '100%',
      width: '100%'
    },
    buttonPayPal: {
      width: '70%',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonPayPalPlaceHolder: {
      width: '70%'
    },
    text: {
      margin: theme.spacing(0, 0, 4),
    },
    errorButton: {
      margin: theme.spacing(0, 0, 1),
    },
    errorButtonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '70%',
      margin: theme.spacing(4, 0, 0),
    }
}));

export default (props) => {
    
    let history = useHistory();
    const classes = useStyles();

    const [nonce, setNonce] = useState<string>();
    const [clientToken, setClientToken] = useState<ClientToken | null>(null);
    const [payPalScript, setPayPalScript] = useState<any | null>(null);
    const [status, setStatus] = useState<
      'INITIAL' |
      'LOAD_PAYPAL' |
      'LOADING_PAYPAL' |
      'ERROR_LOADING_PAYPAL'|
      'FETCH_TOKEN' |
      'FETCHING_TOKEN' |
      'ERROR_FETCHING_TOKEN' |
      'CREATE_CLIENT'|
      'ERROR_CREATING_CLIENT' |
      'CREATING_CLIENT' |
      'SEND_NONCE' |
      'SENDING_NONCE' |
      'ERROR_SENDING_NONCE' |
      'CLIENT_CREATED' |
      'ERROR_CREATING_CLIENT_ON_ERROR'
    >('INITIAL');
    const [errorSendingNonce, setErrorSendingNonce] = useState<string>("C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20142)");
    const payPalButton = useRef(null);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const return_url = searchParams.get('return-url');
    const key_to_update: CrossStoreType = searchParams.get('key-to-update') as CrossStoreType;

    useEffect(() => {
      if(status === 'INITIAL') {
        setStatus('FETCH_TOKEN');
      }
      if(status === 'FETCH_TOKEN') {
        setStatus('FETCHING_TOKEN');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/token`)
          .then(clientToken => {
            setClientToken(clientToken)
            setStatus('LOAD_PAYPAL');
          })
          .catch((e) => {
            e && pushTrackingEvent({
              type: "TrackingEvent",
              data: {
                  category:"error-paypal-add", action:"getting_token", label: JSON.stringify(e)
              }
            });
            setStatus('ERROR_FETCHING_TOKEN');
          })
      }
      if(status === 'LOAD_PAYPAL') {
        setStatus('LOADING_PAYPAL');
        loadScript({
          "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || '',
          vault: true,
          intent: 'tokenize'
        })
        .then((paypal) => {
            setStatus('CREATE_CLIENT');
            setPayPalScript(paypal);
        })
        .catch((e) => {
          e && pushTrackingEvent({
            type: "TrackingEvent",
            data: {
                category:"error-paypal-add", action:"loading_script", label: JSON.stringify(e)
            }
          });
          setStatus('ERROR_LOADING_PAYPAL');
        });
      }
      if(status === 'CREATE_CLIENT' && clientToken && payPalScript) {
        setStatus('CREATING_CLIENT');
        client.create({
          authorization: clientToken.clientToken,
        }).then((clientInstance) => {
          return paypalCheckout.create({
            client: clientInstance
          });
        })
        .then((paypalCheckoutInstance) => {
          return payPalScript.Buttons({
            fundingSource: payPalScript.FUNDING.PAYPAL,
            createBillingAgreement: () => {
              return paypalCheckoutInstance.createPayment({
                // @ts-ignore
                flow: 'vault', 
                billingAgreementDescription: 'Acquisti su Foorban',
                enableShippingAddress: false,
                vault: true,
              });
            },
            onApprove: function (data, actions) {
              return paypalCheckoutInstance.tokenizePayment(data).then(function (payload) {
                setNonce(payload.nonce)
                setStatus('SEND_NONCE');
              });
            },
            onCancel: () => {
              history.goBack();
            },
            onError: function (e) {
              e && pushTrackingEvent({
                type: "TrackingEvent",
                data: {
                    category:"error-paypal-add", action:"creating_button", label: JSON.stringify(e)
                }
              });
              setStatus('ERROR_CREATING_CLIENT_ON_ERROR');
            }
          }).render(`#${BUTTON_ID}`);
        })
        .then(() => {
          setStatus('CLIENT_CREATED');
        })
        .catch((e) => {
          e && pushTrackingEvent({
            type: "TrackingEvent",
            data: {
                category:"error-paypal-add", action:"creating_client", label: JSON.stringify(e)
            }
          });
          setStatus('ERROR_CREATING_CLIENT');
        });
      }
      if(status === 'SEND_NONCE' && nonce) {
        setStatus('SENDING_NONCE');
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
        dataProvider(`b2c/payment/integration/braintree/braintree-paypal-pi/add-account`, {
          method: 'POST',
          body: JSON.stringify({nonce})
        })
          .then((paymentInstrument) => {
            if(history.location.state && history.location.state['key_to_update']) {
              setKey(history.location.state['key_to_update'], paymentInstrument);
            } else if(key_to_update) {
              setKey(key_to_update, paymentInstrument);
              if(return_url) {
                history.replace(decodeURIComponent(return_url));
                return;
              }
            }
            history.goBack();
          })
          .catch((e) => {
            e && pushTrackingEvent({
              type: "TrackingEvent",
              data: {
                  category:"error-paypal-add", action:"sending_nonce", label: JSON.stringify(e)
              }
            });
            if(e && e.message) {
              setErrorSendingNonce(e.message)
            }
            setStatus('ERROR_SENDING_NONCE');
          })
      }
    }, [history, status, clientToken, payPalScript, nonce, searchParams, key_to_update, return_url])

    return (
      <Modal title="Collega PayPal" action={history.goBack}>
          <Container className={classes.container} component="main" maxWidth="xs">
            {[
              'ERROR_SENDING_NONCE'
            ].includes(status) && <Alert severity="error">{errorSendingNonce}</Alert>}
            {[
              'ERROR_FETCHING_TOKEN',
              'ERROR_LOADING_PAYPAL',
              'ERROR_CREATING_CLIENT',
              'ERROR_CREATING_CLIENT_ON_ERROR',
            ].includes(status) && <Alert severity="error">C'è stato un errore. Riprova o contattaci al +390289763305 (Gateway error: 20144)</Alert>}
            {[
              'ERROR_FETCHING_TOKEN',
              'ERROR_LOADING_PAYPAL',
              'ERROR_CREATING_CLIENT',
              'ERROR_SENDING_NONCE',
              'ERROR_CREATING_CLIENT_ON_ERROR',
            ].includes(status) && <div className={classes.errorButtonContainer}>
              <Button color="primary" className={classes.errorButton} onClick={() => {
                if(payPalButton.current) {
                  // @ts-ignore
                  payPalButton.current.innerHTML = "";
                }
                setStatus('INITIAL')
              }} variant="contained" size="large">
                Riprova
              </Button>
              <Button variant="outlined" onClick={history.goBack}>indietro</Button>
            </div>}
            {[
              'INITIAL',
              'FETCH_TOKEN',
              'FETCHING_TOKEN',
              'LOAD_PAYPAL',
              'LOADING_PAYPAL',
              'CREATE_CLIENT',
              'CREATING_CLIENT',
              'CLIENT_CREATED',
              'SENDING_NONCE'
            ].includes(status) && <Typography align="center" className={classes.text}>
              Clicca qui per aggiungere PayPal al tuo profilo Foorban.
            </Typography>}
            <div ref={payPalButton} style={{display: status === 'CLIENT_CREATED' ? 'flex' : 'none' }} className={classes.buttonPayPal} id={BUTTON_ID}>
            </div>
            {[
              'INITIAL',
              'FETCH_TOKEN',
              'FETCHING_TOKEN',
              'LOAD_PAYPAL',
              'LOADING_PAYPAL',
              'CREATE_CLIENT',
              'CREATING_CLIENT',
              'SENDING_NONCE'
            ].includes(status) && <Button className={classes.buttonPayPalPlaceHolder} variant="contained" size="large">
              <CircularProgress style={{width: '28px',height: '28px'}} color="inherit"></CircularProgress>
            </Button>}
          </Container>
      </Modal>
    );
}
