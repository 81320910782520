import React from 'react';
import { useSelector } from 'react-redux';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { PaymentPreferencesState } from '../state/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
    row: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    }
}));

export default function PaymentInstrumentPA() {
    const classes = useStyles();
    const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);

    if(paymentPreferences.status === 'FETCHED' && paymentPreferences.partialAmountMethod && paymentPreferences.partialAmountMethod.type === 'pi') {
        return (
            <div style={{margin: '0 0 8px'}}>
                <div className={classes.row}>
                    <img alt={paymentPreferences.partialAmountMethod.paymentInstrument.label} style={{width: '40px', marginRight: '8px'}} src={`https://images.foorban.business/payment/${paymentPreferences.partialAmountMethod.paymentInstrument.provider.toLowerCase()}.svg`}></img>
                    <div className={classes.column}>
                        <Typography variant="body1">{paymentPreferences.partialAmountMethod.paymentInstrument.label}</Typography>
                        <Typography color="textSecondary" variant="body2">
                            {paymentPreferences.partialAmountMethod.paymentInstrument.sublabel}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    return null;
}