import { UPDATE_CATALOG, UPDATE_ACTIONS, UPDATE_STATUS, UPDATE_DATA, INCREMENT_PENDING_REQUEST_COUNTER, DECREMENT_PENDING_REQUEST_COUNTER, UPDATE_WALLET_BALANCE, UPDATE_CORPORATE_CREDIT_BALANCE, EMPTY_CART, EMPTY_CORPORATE_CREDIT_BALANCE, UPDATE_BANNER } from '../';
import { update as updateCart, updateItem as updateCartItem} from './cart';
import { show } from './message';
export type { DietAction } from '../reducers/diet';

export const updateCatalog = catalog => ({
	type: UPDATE_CATALOG,
	catalog
});

export const updateActions = actions => ({
	type: UPDATE_ACTIONS,
	actions
});

export const updateStatus = status => ({
	type: UPDATE_STATUS,
	status
});

export const updateData = data => ({
	type: UPDATE_DATA,
	data
});

export const cartAction = {
	updateCart,
	updateCartItem,
	emptyCart: () => ({
		type: EMPTY_CART,
	})
}

export const message = {
	show
}

export const incrementPendingRequestCounter = () => ({
	type: INCREMENT_PENDING_REQUEST_COUNTER
});

export const decrementPendingRequestCounter = () => ({
	type: DECREMENT_PENDING_REQUEST_COUNTER
});

export const updateWalletBalance  = balance => ({
	type: UPDATE_WALLET_BALANCE,
	balance
});

export const updateCorporateCreditBalance  = balance => ({
	type: UPDATE_CORPORATE_CREDIT_BALANCE,
	balance
});

export const emptyCorporateCreditBalance  = () => ({
	type: EMPTY_CORPORATE_CREDIT_BALANCE,
});

export const updateBanner = data => ({
	type: UPDATE_BANNER,
	data
});