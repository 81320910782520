import { UPDATE_DATA } from '../';

const data = (state = null, action) => {
	switch (action.type) {
	case UPDATE_DATA:
		return action.data;
	default:
		return state
	}
}

export default data;