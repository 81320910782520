import React, { useRef } from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';

//import CssBaseline from '@material-ui/core/CssBaseline';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import AppBar from '@material-ui/core/AppBar';
import Icon from '@material-ui/core/Icon'
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import FullPageLoading from '../components/FullPageLoading';
import FoorbaSubheader from '../components/FoorbaSubheader';
import { fade } from '@material-ui/core/styles/colorManipulator';

import Hidden from '@material-ui/core/Hidden';
import { useHistory, useLocation } from "react-router-dom";


import { getAuth, signOut } from "firebase/auth";

import DataProvider from '../provider';
const drawerWidth = 280;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		appBar: {
			top: 'auto',
			bottom: 0,
		},
		loadingRoot: {
			display: 'flex',
			flexDirection: 'column',
			minHeight: '100vh',
		},
		root: {
			paddingLeft: drawerWidth,
			[theme.breakpoints.down('xs')]: {
				paddingLeft: 0,
			}
		},
		paper: {
			marginTop: theme.spacing(8),
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center'
		},
		menuButton: {
			marginRight: theme.spacing(2),
		  },
		title: {
			flexGrow: 1,
		},
		drawer: {
			width: drawerWidth,
			flexShrink: 0,
		  },
		  drawerPaper: {
			width: drawerWidth,
		  },
		  drawerContainer: {
			overflow: 'auto',
		},
		listRoot: {
			width: '100%',
			backgroundColor: theme.palette.background.paper,
		},
		childrenWrap: {
			marginBottom: "56px"
		},
		version: {
			position: 'absolute',
			margin: theme.spacing(1, 1, 0),
			bottom: theme.spacing(1)
		},
		selectedListItem: {
			backgroundColor: `${fade(theme.palette.primary.main, 0.2)} !important`,
			color: theme.palette.primary.main,
		},
		selectedListItemIcon: {
			color: theme.palette.primary.main
		}
	}),
);


export default function Base(props: any) {
	const history = useHistory();
	const location = useLocation();
	const classes = useStyles();
	const inputEl = useRef(null);
	let currentPage = null;
	let currentId = null;

	if(location.pathname && location.pathname !== '/me') {
		let pages = props.navigation.links && props.navigation.links.filter((link) => {
			return link.link === location.pathname
		});
		if(pages.length > 0) {
			props.navigation.bottomLink.forEach((id, index) => {
				if(id === pages[0].id) {
					currentPage = index;
				}
			})
			props.navigation.links.forEach((link, index) => {
				if(link.id === pages[0].id) {
					currentId = pages[0].id;
				}
			})
		}
	}
	else if (location.pathname && location.pathname === '/me') {
		currentPage = props.navigation.bottomLink.length;
	}

	const handleChange = (event: React.ChangeEvent<{}>, newValue) => {
		if(inputEl.current === event.currentTarget) {
			history.push('/me');
		}
		else {
			const navigationItem = props.navigation.links.filter((link) => {
				return link.id === props.navigation.bottomLink[newValue]
			});
			if(navigationItem.length === 0) {
				return null;
			}

			history.push(navigationItem[0].link);
				
		}
		
	};
	
	return (
		<div className={classes.root}>
		<Hidden only={['xs']}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerContainer}>
          <List subheader={<FoorbaSubheader subTitle={props.navigation.user.email}></FoorbaSubheader>}>
		  	{false && <ListItem button onClick={() => {
				  console.log('here');
			  }}>
                <ListItemIcon><Icon>note_add</Icon></ListItemIcon>
                <ListItemText primary={"Aggiungi al tuo desktop"} />
            </ListItem>}
            {props.navigation.links.map((link, index) => (
              <ListItem classes={{ selected: classes.selectedListItem }} selected={link.id === currentId} button key={link.id} onClick={() => {
				if(link.type === 'internal') {
					history.push(link.link);
				}
				else {
					window.location.href = link.link;
				}
			  }}>
                <ListItemIcon className={link.id === currentId ? classes.selectedListItemIcon : undefined}><Icon>{link.icon}</Icon></ListItemIcon>
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
			<ListItem button onClick={() => {
				const auth = getAuth();
				signOut(auth)
					.then(() => {
						const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
						return dataProvider(`b2c/auth/logout`);
					})
					.then(() => {
						localStorage.removeItem('token');
						history.replace('/login');
					})
					.catch(() => {})
			  }}>
                <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
                <ListItemText primary={"Logout"} />
            </ListItem>
          </List>
		  <small className={classes.version}>version 0.0.1<span style={{color: process.env.REACT_APP_ENV === 'production' ? 'white': 'green'}}> {process.env.REACT_APP_ENV}</span></small>
        </div>
      </Drawer>
	  </Hidden>
	  		{props.loading ? <FullPageLoading /> : <div className={classes.childrenWrap}>{props.children}</div>}
			<Hidden only={['lg', 'xl', 'md', 'sm']}>
				<AppBar position="fixed" color="primary" className={classes.appBar}>
					<BottomNavigation
						value={currentPage}
						onChange={handleChange}
						showLabels
					>
						{props.navigation.bottomLink.map(bottomLinkItem => {
							const navigationItem = props.navigation.links.filter((link) => {
								return link.id === bottomLinkItem
							});
							if(navigationItem.length === 0) {
								return null;
							}
							return <BottomNavigationAction key={navigationItem[0].id} label={navigationItem[0].short_label} icon={<Icon>{navigationItem[0].icon}</Icon>} />
						}	
						)}
						<BottomNavigationAction label={'Me'} ref={inputEl} icon={<Icon>account_box</Icon>} />
					</BottomNavigation>
				</AppBar>
			</Hidden>
		</div>
	);
}