import { PostMessage as GenericPostMessage} from '../../../../../interfaces/PostMessage';
  
export interface PostMessage extends GenericPostMessage {
    key: 'EDENRED_LOGIN';
    body: {
        status: 'success' | 'error';
        code?: string;
    };
}

export interface Voucher {
    value: number;
    count: number;
}

export const PaymentAttemptStatusList = [
	'COMPLETED',
	'SKIPPED',
	'NOT_FOUND',
	'NEED_LOGIN',
	'AWAITING_CONFIRM',
	'NO_VOUCHERS',
	'ERROR',
	'EMPTY_WALLET',
  'LOADING',
] as const;

export type PaymentAttemptStatus = typeof PaymentAttemptStatusList[number];

export interface InfoResponse {
  status: PaymentAttemptStatus;
  vouchers?: Voucher[];
  total?: number;
  failureReasons?: string;
  redirect?: {
    type: string;
    url: string;
  };
  username?: string;
  skipOption?: 'CAN-SKIP' | 'NO-SKIP';
}