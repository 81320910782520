import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Funnel from '../../../../templates/contents/Funnel';
import DataProvider from '../../../../provider';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import { PellegriniTicketPaymentAttemptInfo } from './interfaces';
import Alert from '@material-ui/lab/Alert';

const useStyles = makeStyles((theme: Theme) => ({
	input: {
		padding: theme.spacing(2, 0, 0),
	},
	button: {
		margin: theme.spacing(2, 0, 0),
	},
	container: {
		padding: theme.spacing(4, 2),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	text: {
		padding: theme.spacing(4, 0),
	},
}));

type PellegriniPayStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'SUBMIT_DATA' | 'SUBMITTING' | 'VALIDATION_ERROR' | 'SUBMITTING_ERROR';

export default function PellegriniPay (props: any) {
    const [status, setStatus] = useState<PellegriniPayStatus>('FETCH_DATA');
	const [token, setToken] = useState<string>('');
	const [info, setInfo] = useState<PellegriniTicketPaymentAttemptInfo | null>(null);
	const [submitFeedBack, setSubmitFeedBack] = useState<string>('');
	const [submitTextError, setSubmitTextError] = useState<string>('Campo obbligatorio');
    const history = useHistory();
	const classes = useStyles();
	
	let params = useParams();
	let uuid = params['uuid'];

    useEffect(() => {
		if(status === 'FETCH_DATA' && uuid) {
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment/integration/pellegrini/pellegrini-ticket/${uuid}`)
				.then((data: PellegriniTicketPaymentAttemptInfo) => {
					if(data.status === 'AWAITING_COMPLETION') {
						throw new Error();
					} else if (['COMPLETED', 'CONFIRMED'].includes(data.status)) {
						if(!data.redirect) {
							throw new Error();
						}
						if(data.redirect.type === 'internal') {
							history.replace(data.redirect.url);
						} else if(data.redirect.type === 'external') {
							window.location.href = data.redirect.url;
						}
					} else {
						setStatus('DATA_FETCHED');
						setInfo(data);
					}
				})
				.catch(e => {
					setStatus('ERROR_DATA_FETCHING');
					console.log(e)
				})
		}
	}, [history, status, uuid]);

	useEffect(() => {
		if(status === 'SUBMIT_DATA') {
			if(!token) {
				setStatus('VALIDATION_ERROR');
				setSubmitTextError('Codice obbligatorio');
				return;
			} else if(token.length !== 7) {
				setStatus('VALIDATION_ERROR');
				setSubmitTextError('Codice non valido. Lunghezza corretta 7 cifre');
				return;
			} else if(token[0] !== '5') {
				setStatus('VALIDATION_ERROR');
				setSubmitTextError('Codice non valido. Il codice deve iniziare con il numero 5');
				return;
			}
			setStatus('SUBMITTING');
			setSubmitTextError('');	
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/payment/integration/pellegrini/pellegrini-ticket/${uuid}`, {
					method: 'POST',
					body: JSON.stringify({
					  token: token
					})
				})
				.then((data: PellegriniTicketPaymentAttemptInfo) => {
					if(data.status === 'DRAFT') {
						if(!data.message) {
							throw new Error();
						}
						setStatus('SUBMITTING_ERROR');
						setSubmitFeedBack(data.message)
					}
					else if (['COMPLETED', 'CONFIRMED'].includes(data.status)) {
						if(!data.redirect) {
							throw new Error();
						}
						if(data.redirect.type === 'internal') {
							history.replace(data.redirect.url);
						} else if(data.redirect.type === 'external') {
							window.location.href = data.redirect.url;
						}
					}
					else {
						throw new Error();
					}
				})
				.catch(e => {
					setStatus('SUBMITTING_ERROR');
					setSubmitFeedBack('Errore durante il caricamento')
				})
		}
	}, [history, status, token, uuid]);

    return <Funnel title="Ricarica Pellegrini" loading={[
		'FETCH_DATA',
		'FETCHING_DATA',
		'SUBMIT_DATA',
		'SUBMITTING'
	].includes(status)}>
        <Container className={classes.container} component="main" maxWidth="xs">
			{status === 'ERROR_DATA_FETCHING' && <>
				<Typography className={classes.text}>Errore durante il caricamento</Typography> 
				<Button size='large' onClick={() => {
					setStatus('FETCH_DATA')
				}} variant="contained" color="primary">
					Riprova
				</Button>
			</>}
			{status === 'SUBMITTING_ERROR' && <Alert severity="error">{submitFeedBack}</Alert>}
			{status === 'DATA_FETCHED' && info?.status === 'COMPLETED' && <Typography className={classes.text}>Transazione completata</Typography>}
			{status === 'DATA_FETCHED' && info?.status === 'CANCEL' && <Typography className={classes.text}>Transazione annullata</Typography>}
			{['VALIDATION_ERROR', 'DATA_FETCHED', 'SUBMITTING_ERROR'].includes(status) && info?.status === 'DRAFT' && <>
			<Typography align='center' className={classes.text}>Apri l'app Pellegrini,<br></br>premi "Spendi", seleziona quanti ticket<br></br>caricare e inserisci qui il codice generato</Typography> 
			<TextField
				type='tel'
				fullWidth
				value={token}
				error={status === 'VALIDATION_ERROR'}
				helperText={status === 'VALIDATION_ERROR' ? submitTextError : null}
				variant="outlined"
				placeholder={'Codice Pellegrini'}
				onChange={(event) => setToken(event.target.value)}
				InputProps={{
					startAdornment: (
						<InputAdornment position="start">
							<CodeIcon />
						</InputAdornment>
					)
				}}
			/>
			<Button className={classes.button} size='large' onClick={() => {
					setStatus('SUBMIT_DATA')
				}} variant="contained" color="primary">
					Conferma
			</Button>
			</>}
			
        </Container>
    </Funnel>;
}
