import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { useDispatch, useSelector } from 'react-redux';
import { cartAction } from '../state/actions';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import IconButton from '@material-ui/core/IconButton';
import InfoIcon from '@material-ui/icons/Info';
import { ProductionCardDescription } from '../components/ProductCardDescription';
import CloseIcon from '@material-ui/icons/Close';
import { SellingAreaStatus } from '../state';

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		height: '100%',
		width: '100%'
	},
	cardMedia: {
		[theme.breakpoints.down('sm')]: {
			width: '0',
		},
		[theme.breakpoints.up('sm')]: {
			width: '296px',
		},
	},
	cartTitle: {
		width: '100%'
	},
	descriptionHTML: {
		display: 'flex',
		width: '100%',
		flex: '1 0 auto',
		height: '0px',
		overflow: 'scroll',
		margin: theme.spacing(1, 0),
	},

//aggiunta oggi
	cardContent: {
		display: 'flex',
		width: '100%',
		flexFlow: 'column',
		justifyContent: 'center',
		alignItems: 'center',
		marginTop: '40px',
	},

//vecchia logica
	quantityRow: {
		display: 'flex',
		flexDirection: 'row',
		margin: theme.spacing(0, 2),
	},
	quantityRowText: {
		fontSize: '0.875rem',
		textAlign: 'center',
		color: 'white',
		padding: '6px 50px',
		[theme.breakpoints.down('xs')]: {
			padding: '6px 43px',
		}
	},
	iconButton: {
		color: 'white',
		margin: theme.spacing(0, -2),
	},
	iconButtonColored: {
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
	},
	iconButtonColoredDisabled: {
		backgroundColor: 'rgb(224, 224, 224)',
		"&:hover": {
			backgroundColor: 'rgb(224, 224, 224)',
		},
	},
	quantityRowTextColored: {
		backgroundColor: theme.palette.primary.main,
	},
	quantityRowTextColoredDisabled: {
		backgroundColor: 'rgb(224, 224, 224)',
	},
	cardButtons: {
		display: 'flex',
		width: '100%',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		padding: theme.spacing(0, 2),
		[theme.breakpoints.down('sm')]: {
			padding: theme.spacing(0, 0),
		},
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1)
	},
}));

export default function ProductCardBig(props) {
	const classes = useStyles();
	const dispatch = useDispatch();
	let params = useParams();
	const cart = useSelector(state => state.cart);
	const wallet = useSelector(state => state.wallet);
	const corporateCredit = useSelector(state => state.corporateCredit);
	let history = useHistory();
	let location = useLocation();
	const sellingAreaStatus = useSelector(state => state.status);
	const mySearchParams = new URLSearchParams(location.search);

	let id = params['id'];
	let quantity = 0;

  	const openUnsellableItemInfoPopup = () => {
		mySearchParams.delete("description");
		mySearchParams.append("unsellable-item-info-banner", "open");
		history.replace({ pathname: location.pathname, search: mySearchParams.toString() });
	};

	if(cart && props.showButton) {
		cart.items.forEach(cartItem => {
			if(props.item.id === cartItem.itemSaleableId)
				quantity = cartItem.quantity;
		});
	}

  	return (
		<Card className={classes.root}>
			<IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={history.goBack}>
				<CloseIcon />
			</IconButton>
			<CardMedia
				className={classes.cardMedia}
				image={props.item.image}
				title={props.item.label}
				/>
			<CardContent className={classes.cardContent}>
				<Typography className={classes.cartTitle} component="h5" variant="h5" color="primary">
					{props.item.label}
				</Typography>

				<ProductionCardDescription 
					hasDietaryOrAllergenRestrictions={props.hasDietaryOrAllergenRestrictions}
					item={props.item}
					edible={props.item.edible}
				/>

				<div className={classes.cardButtons}>
					{props.showPrice && <Typography variant="h6" color="textPrimary">{props.item.price.label}</Typography>}
					{mySearchParams.get('mode') !== 'preview' && 
					 quantity === 0 && 
					 props.showButton && 
					<Button 
						size="medium" 
						disabled={
						sellingAreaStatus === SellingAreaStatus.FETCHING_DATA
						}
						endIcon={props.item.expired ? <InfoIcon /> : null}
						onClick={() => {
						if (props.item.expired) {
							openUnsellableItemInfoPopup()
						} else {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.id, 
								itemSaleablePriceListId: props.item.unique,
								quantity: quantity + 1,
								localPrice: props.item.price.value,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							}
						)}
						}}
						color={props.item.expired ? "default": "primary"}
						variant="contained">{
						`${props.item.expired ? 'NON ACQUISTABILE' : 'LO VOGLIO'}`
						}
					</Button>
					}
					{mySearchParams.get('mode') !== 'preview' && quantity > 0 && props.showButton && <QuantityRow
						disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA}
						className={classes.quantityRow}
						quantity={quantity}
						remove={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.id, 
								itemSaleablePriceListId: props.item.unique,
								quantity: quantity - 1,
								localPrice: props.item.price.value,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}}
						add={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.id, 
								itemSaleablePriceListId: props.item.unique,
								quantity: quantity + 1,
								localPrice: props.item.price.value,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}}
					></QuantityRow>}
					{mySearchParams.get('mode') === 'preview' && <Button size="medium" onClick={() => {
						if(location.search.length > 0) {
							history.push(`${location.search}&leave=need-confirm`)
						}
						else {
							history.push(`?leave=need-confirm`)
						}
					}} color="primary" variant="contained">{'LO VOGLIO'}</Button>}
				</div>
			</CardContent>
		</Card>
	);
}

export function QuantityRow(props: any) {
	const classes = useStyles();

	return (
		<div className={classes.quantityRow}>
			<IconButton disableRipple size="small" onClick={props.disabled ? null : props.remove} className={`${classes.iconButton} ${props.disabled ? classes.iconButtonColoredDisabled : classes.iconButtonColored}`}>
				<RemoveIcon fontSize="small" />
			</IconButton>  
			<Typography className={`${classes.quantityRowText} ${props.disabled ? classes.quantityRowTextColoredDisabled : classes.quantityRowTextColored}`}  variant="subtitle1" color="textPrimary">{props.quantity}</Typography>
			<IconButton disableRipple size="small"onClick={props.disabled ? null : props.add} className={`${classes.iconButton} ${props.disabled ? classes.iconButtonColoredDisabled : classes.iconButtonColored}`}>
				<AddIcon fontSize="small" />
			</IconButton>
		</div>)
}