const CrossStoreKeyList = [
    'SELLING_AREA_PICKED_PI',
    'PAYMENT_PICKED_PI',
    'PAYMENT_OPTIONS_ADDED_PI',
    'INSTRUMENTS_ADDED_PI',
    'RECHARGE_PICKED_PI'
] as const;

export type CrossStoreType = typeof CrossStoreKeyList[number];

export function getKey(key: CrossStoreType) {
    let value = sessionStorage.getItem(key);

    if(value) {
        sessionStorage.removeItem(key);
        let json = JSON.parse(value);
        if(json && (!json.expireIn || json.expireIn > (new Date()).getTime())) {
            return json.value;
        }
    }
    return null;
}

export function setKey(key: CrossStoreType, value: any, expire: number = 1000) {
    sessionStorage.setItem(key, JSON.stringify({
        value,
        expireIn: expire > 0 ? ((new Date()).getTime() + expire) : null
    }));
}

export function checkKey(key: CrossStoreType) {
    let value = sessionStorage.getItem(key);
    if(value) {
        if(JSON.parse(value)) {
            return true;
        }
    }
    return false;
}