import React from 'react';

import { useDispatch, useSelector } from 'react-redux';
import CommonInternalPayments from '../../components/InternalPayments';
import { UPDATE_CART_TOTAL } from './state';

export default function InternalPayments(props: any) {
    const corporateCredit = useSelector(state => state.corporateCredit);
	const wallet = useSelector(state => state.wallet);
	
	const dispatch = useDispatch();
	
	return <CommonInternalPayments 
		showToggleWallet={true}
		showToggleCorporateCredit={true}
		corporateCreditBalance={corporateCredit && corporateCredit.balance ? corporateCredit.balance : 0}
		walletBalance={wallet && wallet.balance ? wallet.balance : 0}
		updateCartTotal={() => {
			dispatch(
				{
					type: UPDATE_CART_TOTAL,
					walletBalance: wallet && wallet.balance ? wallet.balance : 0,
					corporateCreditBalance: corporateCredit && corporateCredit.balance ? corporateCredit.balance : 0,
				},
			);
		}}
	/>
}