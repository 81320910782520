import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Icon } from './ProductCardDescriptionIcon';
import { NutritionalValues } from './NutritionalValues';
import { IconFromDiet, DietType} from './Icons';
import Box from '@material-ui/core/Box';
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';

const useStyles = makeStyles((theme) => ({
	description: {
		//display: 'flex',
		width: '100%',
		flex: '1 0 auto',
        //flexDirection: 'row',
		height: '0px',
		overflow: 'scroll',
		margin: theme.spacing(1, 0, 2),
	},
    subtitle2: {
        fontWeight: 900,
        padding: theme.spacing(0, 0, 2),
    },
    body2: {
        padding: theme.spacing(0, 0, 2),
    }
}));

export function ProductionCardDescription(props : {
    item: {
        description: string;
    };
    edible?: any; 
    hasDietaryOrAllergenRestrictions: boolean;
}) {
    const classes = useStyles();
    return (
        <div className={classes.description}>
            {props.hasDietaryOrAllergenRestrictions && <DietaryOrAllergenRestrictions />}
            {props.edible && <RowDietShortAllergens edible={props.edible}/>} 
            {props.edible && <Typography variant="subtitle2" color="primary">{((props.edible.kcal * props.edible.netWeight)/100).toFixed(0)} Kcal</Typography>}
            <div dangerouslySetInnerHTML={{__html: props.item.description}} />
            {props.edible && <>
            <Typography className={classes.subtitle2} variant="subtitle2">
                Ingredienti:
            </Typography>
            <Typography className={classes.body2} variant="body2">
            {props.edible.ingredients}
            </Typography>
            </>}
            {props.edible && <>
            <Typography className={classes.subtitle2} variant="subtitle2">
                Allergeni:
            </Typography>
            <Typography className={classes.body2} variant="body2">
                {props.edible.allergensString}
            </Typography>
            </>}
            {props.edible && <>
            <Typography className={classes.subtitle2} variant="subtitle2">
                Valori Nutrizionali:
            </Typography>
            <NutritionalValues edible={props.edible} />
            </>}
        </div>
    );
}
function RowDietShortAllergens(props: {
    edible: any;
}) {
    return (
        <div style={{
            display: 'flex',
            width: '100%',
            alignItems: 'center',
            flexDirection: 'row',
            padding: '8px 0'
        }}>
            <ShortAllergens edible={props.edible}/>
            <RowDiet diet={props.edible.diet}/>
        </div>
    )
}


function ShortAllergens(props: {
    edible: any;
}) {
    const icons: any[] = [];
    if(props.edible.milkAndDairyProducts === "Assente") {
        icons.push(<Icon key="milkAndDairyProducts" name="milkAndDairyProducts"/>);
    }
    if(props.edible.cerealsContainingGluten === "Assente") {
        icons.push(<Icon key="cerealsContainingGluten" name="cerealsContainingGluten"/>);
    }

    icons.push(<IconFromDiet size="small" key="diet" diet={props.edible.diet} />);

    return <>{icons}</>;
}

function RowDiet(props: {
    diet: DietType;
}) {
    return <Typography variant="button" style={{fontSize: '0.7rem', fontWeight: 900, marginLeft: '4px'}}>{props.diet.toUpperCase()}</Typography>;
}

const useDietaryOrAllergenRestrictionsStyle = makeStyles((theme: Theme) => ({
    icon: {
        color: "#ff0000",
        borderRadius: "50%",
        border: "1px solid",
        fontSize: "1.45rem",
    },
    label: {
        fontSize: '0.7rem',
        fontWeight: 900,
        marginLeft: '4px',
        color: '#ff0000',
    },
}));

function DietaryOrAllergenRestrictions() {
    const classes = useDietaryOrAllergenRestrictionsStyle();
    return (
        <Box display='flex' alignItems='center' padding= '8px 0'>
            <PriorityHighIcon className={classes.icon} />
            <Typography variant="button"className={classes.label}>PRODOTTO ESCLUSO DAL TUO FILTRO</Typography>
        </Box>
    );
}