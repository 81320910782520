export interface PaymentMethod {
    category: PaymentCategory;
    provider: PaymentProvider;
    label: string;
    sublabel?: string;
}

export interface PaymentInstrument {
    id: number;
    created_at: Date;
    updated_at: Date;
    status: string;
    provider: PaymentProvider;
    type: Integration;
    category: PaymentCategory;
    label: string;
    sublabel: string;
    default: boolean;
    expirationDate: Date;
    uuid: string;
}
export type PaymentInstrumentStatus = 
  | 'active'
  | 'locked'
  | 'expired'
  | 'draft';

export type Integration = 'braintree_cc' | 'pellegrini_ticket';
export type PaymentCategory = 'cc' | 'ticket';

export type AmountType =
  | 'DefinedAmount'
  | 'MaxAmount'
  | 'TargetAmount'
  | 'UnknowAmount';

export type PaymentProvider =
  | 'visa'
  | 'amex'
  | 'mastercard'
  | 'pellegrini'
  | 'edenred'
  | 'sodexo'
  | 'day';

export const PaymentContextArray = ['definedAmountMethod', 'walletRechargeMethod', 'partialAmountMethod'] as const;
export type PaymentContext = typeof PaymentContextArray[number];

interface PaymentPreferenceBase {
  paymentContext: PaymentContext;
  type: 'pm' | 'pi';
}

interface PaymentPreferencePI extends PaymentPreferenceBase {
  type: 'pi';
  paymentInstrument: PaymentInstrument;
}

interface PaymentPreferencePM extends PaymentPreferenceBase {
  type: 'pm';
  paymentMethod: PaymentMethod;
}

export type PaymentPreference =
  | PaymentPreferencePI
  | PaymentPreferencePM;

export interface PaymentOption {
  label: string;
  category: PaymentCategory;
  amount: AmountType;
  canCreatePI: 'Yes' | 'No';
  provider?: PaymentProvider;
  createPIPath?: string;
  icon?: string;
}
