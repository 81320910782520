import React from 'react';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { ListSubheader } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
            padding: theme.spacing(2),
        },
        rootMobile: {
            padding: theme.spacing(2),
            backgroundColor: theme.palette.primary.main,
        },
        title: {
            color: 'black',
            flexGrow: 1,
            fontWeight: 700,
            padding: theme.spacing(0, 0, 1),
        },
        titleMobile: {
            color: 'white',
            flexGrow: 1,
            fontWeight: 700,
            padding: theme.spacing(0, 0, 1),
        },
        email: {
            color: 'black',
        },
        emailMobile:{
            color: 'white',
        }
	})
);

export default (props) => {
    const classes = useStyles();

    return (
        <div>
            <ListSubheader className={props.mobile ? classes.rootMobile : classes.root}>
                <Typography className={props.mobile ? classes.titleMobile : classes.title} variant="h4" noWrap>FOORBAN</Typography>
                {props.subTitle && <Typography className={props.mobile ? classes.emailMobile : classes.email} noWrap>{props.subTitle}</Typography>}
            </ListSubheader>
        </div>
    );
}