import React from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import { makeStyles, Theme } from '@material-ui/core/styles';

import { SellingAreaStatus } from './state';

import { useSelector  } from 'react-redux';
  
const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent',
		padding: theme.spacing(16, 0, 6)
	},
	text: {
		textAlign: 'center'
	},

}));

export default function Feedback(props: any) {
    const status = useSelector(state => state.status);
    const catalog = useSelector(state => state.catalog);
    const classes = useStyles();

    if(status === SellingAreaStatus.NOT_FOUND) {
        return (
            <Container component="main" maxWidth="xs" >
                 <div className={classes.paper}>
                     <Typography className={classes.text}>Area non trovata</Typography>
                 </div>
            </Container>
        )
    }
	if(status === SellingAreaStatus.NOT_ACTIVE) {
        return (
            <Container component="main" maxWidth="xs" >
                 <div className={classes.paper}>
                     <Typography className={classes.text}>Area non abilitata</Typography>
                 </div>
            </Container>
        )
    }

	if(catalog && catalog.shopStatus === 'Close') {
		return (
            <Container component="main" maxWidth="xs" >
                 <div className={classes.paper}>
                     <Typography className={classes.text}>{catalog.closePhrase ? catalog.closePhrase : 'Negozio momentaneamente chiuso'}</Typography>
                 </div>
            </Container>
        )
	}

    return null;
}