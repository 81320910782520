import { UPDATE_BANNER } from '../';
export interface BannerState {
	content?: string;
	icon?: string;
	title?: string;
	status: "ONLY-TITLE" | "ON" | "OFF";
	style: "LIGHT_ORANGE" | "LIGHT_GREEN" | "DARK_ORANGE";
}
export default function(state: BannerState = {
	status: 'OFF',
	style: 'LIGHT_ORANGE'
}, action: {
	type: "UPDATE_BANNER",
	data: BannerState,
}) {
	switch (action.type) {
	case UPDATE_BANNER:
		if(!state) {
			return state;
		}

		return {
			content: action.data.content,
			icon: action.data.icon,
			title: action.data.title,
			status: action.data.status,
			style: action.data.style
		};
	default:
		return state
	}
}
