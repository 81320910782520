import React, { useEffect, useState } from 'react';

import { getAuth, sendPasswordResetEmail, fetchSignInMethodsForEmail, signInWithEmailAndPassword } from "firebase/auth";
import Button from '@material-ui/core/Button';
import MUIContainer from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { reverse_route } from '../reducers/currentPage';
import { updateCurrent } from '../actions';
import Loading from '../Loading';
import DataProvider from '../provider';
import Alert from '@material-ui/lab/Alert';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { pushTrackingEvent } from '../utils/gtmDataLayer';
import { linkCredential } from '../utils/firebase';
import { setCookie } from '../utils/auth';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        flex: 1,
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        backgroundColor: 'white'
    },
    paper: {
        paddingTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent'
    },
    sub_paper: {
        padding: theme.spacing(8, 2, 0, 2),
        display: 'flex',
        width: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        backgroundColor: 'transparent'
    },
    title: {
        color: theme.palette.primary.main,
        flexGrow: 1,
        textAlign: 'center',
        fontWeight: 700
    },
    action: {
        margin: theme.spacing(2, 0, 0),
    },
    email: {
        margin: theme.spacing(0, 0, 1),
    },
	errorSettingCookie: {
		padding: theme.spacing(0, 0, 4),
	}
}));

const loading_status_list = [
    'AWAITING_USER_CHECK',
    'REGISTERING_NEW_USER',
    'REGISTER_NEW_USER',
    'END_LOGIN_WITH_EMAIL',
    'CHECK_USER_DOMAIN',
    'CHECKING_USER',
    'CHECK_USER',
    'SETTING_COOKIE',
];

export default function LoginWithEmailPassword(props: any) {
    const [email, setEmail] = useState(new URLSearchParams(window.location.search).get("email") || "");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [unManagedProvider, setUnManagedProvider] = useState("");
    const [status, setStatus] = useState<
        | 'AWAITING_USER_CHECK'
        | 'CHECKING_USER'
        | 'START_LOGIN'
        | 'ERROR_START_LOGIN'
        | 'AWAITING_PASSWORD'
        | 'REGISTER_NEW_USER'
        | 'REGISTERING_NEW_USER'
        | 'ERROR_REGISTERING_NEW_USER'
        | 'USER_REGISTERED'
        | 'EMAIL_RESET_PASSWORD_SENDED'
        | 'ERROR_EMAIL_RESET_PASSWORD'
        | 'ERROR_AWAITING_PASSWORD'
        | 'DO_REDIRECT'
        | 'AWATING_FIREBASE_LOGIN'
        | 'END_LOGIN_WITH_EMAIL'
        | 'CHECK_USER'
        | 'CHECK_USER_DOMAIN'
        | 'UNMANAGED_PROVIDER'
        | 'INVALID_PASSWORD'
        | 'ERROR_AWAIT_USER'
        | 'ERROR_VERIFY_LOGIN'
        | 'ERROR_DURING_VERIFY_LOGIN'
        | 'SETTING_COOKIE'
        | 'ERROR_SETTING_COOKIE'
    >('START_LOGIN');
    const [method, setMethod] = useState('');
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const auth = getAuth();

    useEffect(() => {
        const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

        if (['END_LOGIN_WITH_EMAIL'].includes(status)) {
            setStatus('SETTING_COOKIE');
            setCookie()
                .then(() => {
                    let redirect = window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/";
                    if (reverse_route[redirect]) {
                        dispatch(updateCurrent(reverse_route[redirect]));
                    }
                    window.localStorage.removeItem('redirect_to') 
                    history.replace(redirect);
                })
                .catch(() => {
                    setStatus('ERROR_SETTING_COOKIE');
                });
        }

        // if(status === 'AWAITING_USER' && !props.loading && !props.user) {
        //     setStatus('ERROR_AWAIT_USER');
        // }

        if (status === 'REGISTER_NEW_USER') {
            setStatus('REGISTERING_NEW_USER')
            dataProvider(`b2c/register`, {
                method: 'POST',
                body: JSON.stringify({
                    email
                })
            })
                .then((response: {
                    success: boolean;
                    message: string;
                }) => {
                    if (response.success) {
                    return sendPasswordResetEmail(auth, email, {
                            url: `${window.location.protocol}//${window.location.host}${window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/"}`,
                        });
                    } else {
                        throw new Error();
                    }
                })
                .then(() => {
                    setStatus('USER_REGISTERED')
                })
                .catch(e => {
                    e && pushTrackingEvent({
                        type: "TrackingEvent",
                        data: {
                            category:"Error", action:"FirebaseAuth", label: `login-email-password${JSON.stringify({message: e.message,code: e.code,email: e.customData.email})}`
                        }
                    });
                    setStatus('ERROR_REGISTERING_NEW_USER')
                })
        }
        if(status === 'CHECK_USER_DOMAIN') {
            setStatus('CHECKING_USER');
            dataProvider(`b2c/check-domain/${email}`)
            .then(info => {
                switch (info.type) {
                    case "google":
                        history.replace(`/login?email=${email}&provider=google`);
                        break;
                    case "microsoft":
                        history.replace(`/login?email=${email}&provider=microsoft`);
                        break;
                    case "email":
                    default:
                        setStatus('CHECK_USER');
                        break;
                }
            })
            .catch(e => {
                setStatus('ERROR_START_LOGIN');
            })
        }
        if(status === 'CHECK_USER') {
            setStatus('CHECKING_USER');
            fetchSignInMethodsForEmail(auth, email).then((response: string[]) => {
                if (response.length > 0 && response.includes('password')) {
                    setStatus('AWAITING_PASSWORD');
                } else if (response.length > 0 && (response.includes('microsoft.com') || response.includes('emailLink') || response.includes('google.com'))) {  
                    sendPasswordResetEmail(auth,email, {
                        url: `${window.location.protocol}//${window.location.host}${window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/"}`,
                    })
                        .then(() => {
                            setStatus('EMAIL_RESET_PASSWORD_SENDED');
                        })
                        .catch((e) => {
                            e && pushTrackingEvent({
                                type: "TrackingEvent",
                                data: {
                                    category:"Error", action:"FirebaseAuth", label: `login-email-password${JSON.stringify({message: e.message,code: e.code,email: e.customData.email})}`
                                }
                            });
                            setStatus('ERROR_EMAIL_RESET_PASSWORD');
                        });
                } else if(response.length === 0) {
                    setStatus('REGISTER_NEW_USER');
                } else if(response.length > 0) {
                    setUnManagedProvider(response.join(','))
                    setStatus('UNMANAGED_PROVIDER');
                }
            }).catch(e => {
                e && pushTrackingEvent({
                    type: "TrackingEvent",
                    data: {
                        category:"Error", action:"FirebaseAuth", label: `login-email-password${JSON.stringify({message: e.message,code: e.code,email: e.customData.email})}`
                    }
                });
                setStatus('ERROR_START_LOGIN')
            })
        }
        return () => { }
    }, [dispatch, email, history, props.loading, props.user, status, auth]);

    if (loading_status_list.includes(status)) {
        return <Loading />;
    }

    if(status === 'UNMANAGED_PROVIDER') {
        return (
            <Container maxWidth="xs">
                <Typography align="center">Provider non riconosciuto: {unManagedProvider}</Typography>
                <Button
                    className={classes.action}
                    onClick={() => {
                        history.replace('/login');
                    }}
                    size="small"
                    variant="outlined"
                >Scegli un altro metodo per autenticarti</Button>
            </Container>
        );
    }

    if(['EMAIL_RESET_PASSWORD_SENDED', 'USER_REGISTERED'].includes(status)) {
         return (
            <Container maxWidth="xs">
                <Typography align="center">Ti abbiamo inviato un'email all'indirizzo<br></br><strong>{email}</strong><br></br>{status === 'EMAIL_RESET_PASSWORD_SENDED' ? 'per scegliere una nuova password' : ''}{status === 'USER_REGISTERED' ? 'per scegliere la tua password' : ''}.</Typography>
                <Button
                    className={classes.action}
                    onClick={() => {
                        history.replace('/login');
                    }}
                    size="small"
                    variant="outlined"
                >Scegli un altro metodo per autenticarti</Button>
            </Container>
        );
    }

    if (['ERROR_AWAIT_USER', 'ERROR_REGISTERING_NEW_USER', 'ERROR_EMAIL_RESET_PASSWORD', 'AWAITING_PASSWORD', 'ERROR_AWAITING_PASSWORD', 'AWATING_FIREBASE_LOGIN', 'INVALID_PASSWORD', 'ERROR_DURING_VERIFY_LOGIN'].includes(status)) {
        return (<Container maxWidth="xs">
            {['ERROR_AWAIT_USER', 'ERROR_DURING_VERIFY_LOGIN'].includes(status) &&
                <Alert style={{ marginBottom: '8px' }} severity="warning">
                    Abbiamo riscontrato un problema con il nostro provider
                </Alert>
            }
            {status === 'ERROR_AWAITING_PASSWORD' &&
                <Alert style={{ marginBottom: '8px' }} severity="warning">
                    L'email o la password inserita sono errate
                </Alert>
            }
            {status === 'ERROR_EMAIL_RESET_PASSWORD' &&
                <Alert style={{ marginBottom: '8px' }} severity="warning">
                    Abbiamo riscontrato un problema durante il reset della password
                </Alert>
            }
            {status === 'ERROR_REGISTERING_NEW_USER' &&
                <Alert style={{ marginBottom: '8px' }} severity="warning">
                    Abbiamo riscontrato un problema durante la creazione del nuovo utente
                </Alert>
            }
            <TextField disabled className={classes.email} inputProps={{
                autoComplete: 'email',
                type: 'email',
            }} fullWidth label="Email" value={email} />
            <TextField
                helperText={status === 'INVALID_PASSWORD' ? 'Lunghezza minima 6 caratteri': ''}
                error={status === 'INVALID_PASSWORD'}
                autoFocus
                InputProps={{
                    autoComplete: 'current-password',
                    type: showPassword ? 'text' : 'password',
                    endAdornment: <InputAdornment position="end">
                        <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => {setShowPassword(!showPassword)}}
                        edge="end"
                        >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }}
                fullWidth label="Password" value={password} onChange={(event) => {
                setPassword(event.target.value);
            }} />
            <Button
                fullWidth
                className={classes.action}
                disabled={status === 'AWATING_FIREBASE_LOGIN'}
                onClick={() => {
                    if(password.length < 6) {
                        setStatus('INVALID_PASSWORD')
                    } else {
                        setStatus('AWATING_FIREBASE_LOGIN')
                        signInWithEmailAndPassword(auth, email, password)
                            .then((response) => {
                                return linkCredential(response);
                            })
                            .then(() => {
                                setStatus('END_LOGIN_WITH_EMAIL');
                            })
                            .catch((e) => {
                                if(e && e.code && e.code === 'auth/account-exists-with-different-credential') {
                                    window.localStorage.setItem('error.credential', JSON.stringify(e.credential));
                                    window.localStorage.setItem('error.credential.email', e.customData.email);
                                    fetchSignInMethodsForEmail(auth, e.customData.email).then(methodsResponse => {
                                        if(methodsResponse.length === 0) {
                                            throw new Error();
                                        }
                                        const methodResponse = methodsResponse[0];
                                        if(methodResponse === 'password') {
                                            throw new Error();
                                        }
                                        setMethod(methodResponse);
                                        setStatus('ERROR_VERIFY_LOGIN');
                                    })
                                    .catch(() => {
                                        window.localStorage.removeItem('error.credential');
                                        window.localStorage.removeItem('error.credential.email');
                                        setStatus('ERROR_DURING_VERIFY_LOGIN');
                                    });
                                } else {
                                    e && pushTrackingEvent({
                                        type: "TrackingEvent",
                                        data: {
                                            category:"Error", action:"FirebaseAuth", label: `login-email-password${JSON.stringify({message: e.message,code: e.code,email: e.customData.email})}`
                                        }
                                    });
                                    setPassword("");
                                    setStatus('ERROR_AWAITING_PASSWORD');
                                }
                            })
                    }
                }}
                color="primary"
                size="large"
                variant="contained"
            >login</Button>
            <Button
                fullWidth
                className={classes.action}
                onClick={() => {
                    history.replace('/login');
                }}
                size="large"
                variant="contained"
            >Annulla</Button>
            <Button
                fullWidth
                className={classes.action}
                onClick={() => {
                    sendPasswordResetEmail(auth, email, {
                        url: `${window.location.protocol}//${window.location.host}${window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/"}`,
                    })
                        .then(() => {
                            setStatus('EMAIL_RESET_PASSWORD_SENDED');
                        })
                        .catch((e) => {
                            e && pushTrackingEvent({
                                type: "TrackingEvent",
                                data: {
                                    category:"Error", action:"FirebaseAuth", label: `login-email-password${JSON.stringify({message: e.message,code: e.code,email: e.customData.email})}`
                                }
                            });
                            setStatus('ERROR_EMAIL_RESET_PASSWORD');
                        });
                }}
                size="small"
                variant="text"
            >RECUPERA PASSWORD</Button>
        </Container>)
    }

    if(['ERROR_VERIFY_LOGIN'].includes(status)) {
        return (
           <Container maxWidth="xs">
               <Typography align="center">Per ragioni di sicurezza clicca sul tasto "Continua" e rieffettua l'accesso tramite il login via {{
                    'microsoft.com': 'Microsoft',
                    'google.com': 'Google',
                }[method]}</Typography>
               <Button
                    className={classes.action}
                    onClick={() => {
                        if(method === 'google.com') {
                            history.replace(`/login?email=${email}&provider=google`);
                        } else if(method === 'microsoft.com') {
                            history.replace(`/login?email=${email}&provider=microsoft`);
                        }
                    }}
                    size="large"
				    variant="contained"
				>continua</Button>
           </Container>
       );
    }

    if(['ERROR_SETTING_COOKIE'].includes(status)) {
        return (
           <Container maxWidth="xs">
               <Typography className={classes.errorSettingCookie} align="center">Abbiamo riscontrato un problema durante il processamento della richiesta (021).</Typography>
               <Button
                    fullWidth
                    color="primary"
                    onClick={() => {
                        setStatus('START_LOGIN');
                    }}
                    size="large"
				    variant="contained"
				>riprova</Button>
           </Container>
       );
    }

    return (
        <Container maxWidth="xs">
            <TextField autoFocus inputProps={{
                autoComplete: 'email',
                type: 'email',
            }} fullWidth label="Email" value={email} onChange={(event) => {
                setEmail(event.target.value.replace(/\s+/g, ''));
            }} />
            <Button
                fullWidth
                className={classes.action}
                disabled={status === 'CHECKING_USER'}
                onClick={() => {
                    const serachParams = new URLSearchParams(window.location.search);
                    const skip = serachParams.get("skip");
                    serachParams.delete("skip");
                    if(skip === 'domain') {
                        setStatus('CHECK_USER');
                    } else {
                        setStatus('CHECK_USER_DOMAIN');
                    }
                }}
                color="primary"
                size="large"
                variant="contained"
            >continua</Button>
            <Button
                fullWidth
                className={classes.action}
                onClick={() => {
                    history.replace('/login');
                }}
                size="large"
                variant="contained"
            >Annulla</Button>
        </Container>
    );
}

export function Container(props: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <MUIContainer component="main" maxWidth={props.maxWidth} >
                <div className={classes.paper}>
                    <Typography className={classes.title} variant="h4" noWrap>FOORBAN</Typography>
                    <div className={classes.sub_paper}>
                        {props.children}
                    </div>
                </div>
            </MUIContainer>
        </div>
    );
}