import React from 'react';

import AppBar from '../../components/AppBar';
import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: '64px',
        [theme.breakpoints.down('xs')]: {
			marginTop: '56px',
		}
    },
    paper: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <AppBar rightAction={props.rightAction} disableGutters={props.disableBack ? false : true} position="fixed" enableBack={props.disableBack ? false : true} title={props.title} filter={props.filter}></AppBar>
            {props.loading ? <div className={classes.paper}><CircularProgress  /></div> : props.children}
        </div>
    );
}