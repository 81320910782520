import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

const useStyles = makeStyles({
  table: {
    width: '100%'
  },
});

const rows = {
  'kcal': {
    label: 'Energia',
    uom: 'Kcal'
  },
  'fats': {
    label: 'Grassi',
    uom: 'gr'
  },
  'fatsSaturated': {
    label: 'Grassi saturi',
    uom: 'gr'
  },
  'carbohydrates': {
    label: 'Carboidrati',
    uom: 'gr'
  },
  'sugar': {
    label: 'Zuccheri',
    uom: 'gr'
  },
  'fibers': {
    label: 'Fibre',
    uom: 'gr'
  },
  'proteins': {
    label: 'Proteine',
    uom: 'gr'
  },
  'salt': {
    label: 'Sale',
    uom: 'gr'
  },
};

function NutritionalValuesComponent(props : {
  edible: any; 
  width: any
}) {
  if (isWidthUp('xs', props.width)) {
		return <NutritionalValuesBig edible={props.edible}/> ;
	} else {
    return <NutritionalValuesSmall edible={props.edible}/> ;
  }
}

function NutritionalValuesSmall(props : {
  edible: any; 
}) {
  const classes = useStyles();

  return (
    <>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">x porzione ({props.edible.netWeight}gr)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((row) => (
            <TableRow key={`${row}-portion`}>
              <TableCell component="th" scope="row">
                {rows[row].label}
              </TableCell>
              <TableCell align="right">{((props.edible[row] * props.edible.netWeight)/100).toFixed(0)}{rows[row].uom}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">x 100gr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((row) => (
            <TableRow key={`${row}-100`}>
              <TableCell component="th" scope="row">
                {rows[row].label}
              </TableCell>
              <TableCell align="right">{props.edible[row]}{rows[row].uom}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </>
  );
}

function NutritionalValuesBig(props : {
  edible: any; 
}) {
  const classes = useStyles();

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="right">x porzione ({props.edible.netWeight}gr)</TableCell>
            <TableCell align="right">x 100gr</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(rows).map((row) => (
            <TableRow key={row}>
              <TableCell component="th" scope="row">
                {rows[row].label}
              </TableCell>
              <TableCell align="right">{((props.edible[row] * props.edible.netWeight)/100).toFixed(0)}{rows[row].uom}</TableCell>
              <TableCell align="right">{props.edible[row]}{rows[row].uom}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}


export const NutritionalValues = withWidth()(NutritionalValuesComponent);