import { DietPreference } from './reducers/diet';
export type { DietPreference, Allergen } from './reducers/diet';

export const UPDATE_CART = 'UPDATE_CART';
export const UPDATE_CART_ITEM = 'UPDATE_CART_ITEM';
export const UPDATE_CATALOG = 'UPDATE_CATALOG';
export const UPDATE_STATUS = 'UPDATE_STATUS';
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS';
export const UPDATE_DATA = "UPDATE_DATA";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const REMOVE_MESSAGE = "REMOVE_MESSAGE";
export const INCREMENT_PENDING_REQUEST_COUNTER = "INCREMENT_PENDING_REQUEST_COUNTER";
export const DECREMENT_PENDING_REQUEST_COUNTER = "DECREMENT_PENDING_REQUEST_COUNTER";
export const UPDATE_WALLET_BALANCE = "UPDATE_WALLET_BALANCE";
export const UPDATE_CORPORATE_CREDIT_BALANCE = "UPDATE_CORPORATE_CREDIT_BALANCE";
export const EMPTY_CART = 'EMPTY_CART';
export const EMPTY_CORPORATE_CREDIT_BALANCE = 'EMPTY_CORPORATE_CREDIT_BALANCE';
export const UPDATE_CART_TOTAL = 'UPDATE_CART_TOTAL';
export const UPDATE_BANNER = 'UPDATE_BANNER';

export enum SellingAreaStatus {
    FETCHING_DATA = "fetching_data",
    FETCH_DATA = "fetch_data",
    DATA_FETCHED = "data_fetched",
    NOT_FOUND = "not_found",
    NOT_ACTIVE = "not_active"
}

export interface SellingAreaState {
	cart: any;
	catalog: any;
	status: any;
	actions: any;
	data: any;
	message: any;
	pendingRequestCounter: any;
	wallet: any;
	corporateCredit: any;
	paymentPreferences: any;
	banner: any;
	diet: DietPreference;
}