import React from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Funnel from '../../templates/contents/Funnel';
 
const useStyles = makeStyles((theme: Theme) => ({
    container: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
      padding: theme.spacing(2)
    },
    text: {
        margin: theme.spacing(4, 0)
    },
}));

export default function CoffeeInsufficientFund(props: any)  {
    const history = useHistory();
    const classes = useStyles();
    let location = useLocation();
    const mySearchParams = new URLSearchParams(location.search);

    return (
        <Funnel title="Credito insufficiente" loading={false} action={history.goBack}>
            <Container className={classes.container} maxWidth="xs">
                <Typography align="justify" className={classes.text} variant="subtitle1">Ops. Purtroppo hai finito il credito. Ricarica per continuare. Il credito è utilizzabile anche sul Foorban Fridge.</Typography>
                <Button fullWidth color="primary" variant="contained" size="large"  onClick={() => {
                    history.replace(`/wallet/recharge${mySearchParams.get('return-url') ? `?return-url=${mySearchParams.get('return-url')}` : ''}`);
                }}>RICARICA WALLET</Button>
            </Container>
        </Funnel>
    );
}