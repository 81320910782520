import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { getAuth, signInWithRedirect, GoogleAuthProvider, OAuthProvider, getRedirectResult, fetchSignInMethodsForEmail, AuthCredential } from "firebase/auth";
import CircularProgress from '@material-ui/core/CircularProgress';
import { useHistory } from "react-router-dom";
import { useDispatch } from 'react-redux'
import Typography from '@material-ui/core/Typography';
import MailOutlineIcon from '@material-ui/icons/MailOutline';

import { reverse_route } from './reducers/currentPage';
import { updateCurrent } from './actions';
import { Google as GoogleIcon, Microsoft as  MicrosoftIcon } from './Icons';
import { pushTrackingEvent } from './utils/gtmDataLayer';
import { linkCredential } from './utils/firebase';
import { setCookie } from './utils/auth';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flex: 1,
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		backgroundColor: theme.palette.primary.main
	},
	paper: {
		paddingTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent'
	},
	button: {
		margin: theme.spacing(0, 0, 2),
		padding: theme.spacing(1, 0),
		justifyContent: 'left',
		backgroundColor: 'white',
		textTransform: 'none',
		maxWidth: '300px'
	},
	loading: {
		color: 'white'
	},
	title: {
		color: 'white',
		flexGrow: 1,
		textAlign: 'center',
		fontWeight: 700
	},
	loginText: {
		color: 'white',
		margin: theme.spacing(6, 0, 4),
	},
	label: {
		textAlign: 'center',
		width: '100%'
	},
	buttonContainer: {
		margin: theme.spacing(0, 0, 0),
		display: 'flex',
    	flexDirection: 'column',
    	alignItems: 'center'
	},
	startIconCustom: {
		padding: theme.spacing(0, 4, 0),
		margin: theme.spacing(0, 0, 0),
	},
	errorSettingCookie: {
		color: 'white',
		padding: theme.spacing(4, 0),
	}
}));

export default function SignIn(props: any) {
	const history = useHistory();
	const dispatch = useDispatch();
    const [status, setStatus] = useState<
		| 'AWAITING_USER'
		| 'CHECKING_REDIRECT_RESULT'
		| 'CHECK_REDIRECT_RESULT'
		| 'DO_LOGIN'
		| 'ERROR_SIGN_WITH_REDIRECT'
		| 'MICROSOFT_ERROR'
		| 'ERROR_VERIFY_LOGIN'
		| 'DOING_LOGIN'
		| 'END_LOGIN'
		| 'SETTING_COOKIE'
        | 'ERROR_SETTING_COOKIE'
	>('CHECK_REDIRECT_RESULT')
	const [email, setEmail] = useState('');
	const [method, setMethod] = useState('');
	const classes = useStyles();
	const auth = getAuth();

	useEffect(() => {
		if(![
			'ERROR_VERIFY_LOGIN',
			'MICROSOFT_ERROR',
			'ERROR_SIGN_WITH_REDIRECT',
			'CHECK_REDIRECT_RESULT',
			'CHECKING_REDIRECT_RESULT',
			'DOING_LOGIN',
			'END_LOGIN'
		].includes(status) && props.user === null && props.loading === false) {
			setStatus('DO_LOGIN');
		}

        if ([ 'END_LOGIN'].includes(status)) {
			setStatus('SETTING_COOKIE');
			setCookie()
				.then(coookieResponse => {
					if (coookieResponse) {
						if(coookieResponse.redirect.type === 'internal') {
							history.replace(coookieResponse.redirect.link);
						}
						else {
							window.location.href = coookieResponse.redirect.link;
						}
					} else {
						let redirect = window.localStorage.getItem('redirect_to') || new URLSearchParams(window.location.search).get("redirect_to") || "/";
						if (reverse_route[redirect]) {
							dispatch(updateCurrent(reverse_route[redirect]));
						}
						window.localStorage.removeItem('redirect_to') 
						history.replace(redirect);
					}
				})
				.catch(() => {
					setStatus('ERROR_SETTING_COOKIE');
				});
        }

		if(status === 'CHECK_REDIRECT_RESULT') {
			setStatus('CHECKING_REDIRECT_RESULT');
			const searchParams = new URLSearchParams(window.location.search);
			const emailParam = searchParams.get("email");
			const providerParam = searchParams.get("provider");

			if(emailParam && providerParam === 'google') {
				window.history.replaceState({}, document.title, "/login");

				const googleOAuthProvider = new GoogleAuthProvider();
				googleOAuthProvider.setCustomParameters({
					prompt: 'select_account',
					login_hint: emailParam,
				});
				signInWithRedirect(auth, googleOAuthProvider)
					.catch(e => {
						e && pushTrackingEvent({
							type: "TrackingEvent",
							data: {
								category:"Error", action:"FirebaseAuth", label: JSON.stringify({
									message: e.message,
									code: e.code,
									email: e.customData.email,
								})
							}
						});
						setStatus('ERROR_SIGN_WITH_REDIRECT');
					})
				return () => {};
			} else if(emailParam && providerParam === 'microsoft') {
				window.history.replaceState({}, document.title, "/login");

				const microsoftOAuthProvider = new OAuthProvider('microsoft.com');

				microsoftOAuthProvider.setCustomParameters({
					prompt: 'select_account',
					login_hint: emailParam,
				});

				signInWithRedirect(auth, microsoftOAuthProvider)
					.catch(e => {
						e && pushTrackingEvent({
							type: "TrackingEvent",
							data: {
								category:"Error", action:"FirebaseAuth", label: JSON.stringify({
									message: e.message,
									code: e.code,
									email: e.customData.email,
								})
							}
						});
						setStatus('ERROR_SIGN_WITH_REDIRECT');
					})
				return () => {};
			} else {
				getRedirectResult(auth)
				.then(response => {
					if(!response || !response.user) {
						setStatus('DO_LOGIN')
					} else if (response) {
						let credential: AuthCredential | null;
						if(response.providerId === 'google.com') {
							credential = GoogleAuthProvider.credentialFromResult(response)
						} else {
							credential = OAuthProvider.credentialFromResult(response)
						}
						if(credential) {
							return linkCredential(response)
								.then(() => {
									setStatus('END_LOGIN');
								})
						} else {
							setStatus('DO_LOGIN')
						}
					}
				})
				.catch(e => {
					if(e && e.code && e.code === 'auth/account-exists-with-different-credential') {
						window.localStorage.setItem('error.credential', JSON.stringify(OAuthProvider.credentialFromError(e)));
						window.localStorage.setItem('error.credential.email', e.customData.email);

						fetchSignInMethodsForEmail(auth, e.customData.email).then(methodsResponse => {
							if(methodsResponse.length === 0) {
								throw new Error('no methods founds');
							}
							setEmail(e.customData.email);
							setMethod(methodsResponse[0]);
							setStatus('ERROR_VERIFY_LOGIN');
						})
						.catch(() => {
							window.localStorage.removeItem('error.credential');
							window.localStorage.removeItem('error.credential.email');
							setStatus('ERROR_SIGN_WITH_REDIRECT');
						})
					} else {
						e && pushTrackingEvent({
							type: "TrackingEvent",
							data: {
								category:"Error", action:"FirebaseAuth", label: JSON.stringify({
									message: e.message,
									code: e.code,
									email: e.customData.email,
								})
							}
						});
						setStatus('ERROR_SIGN_WITH_REDIRECT');
					}
				})
			}
		}
	}, [dispatch, history, props, props.loading, props.user, status, auth])

	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs" >
				<div className={classes.paper}>
					<Typography className={classes.title} variant="h4" noWrap>FOORBAN</Typography>
					{[
						'CHECKING_REDIRECT_RESULT',
						'CHECK_REDIRECT_RESULT',
						'AWAITING_USER',
						'DOING_LOGIN',
						'END_LOGIN',
						'SETTING_COOKIE',
					].includes(status) && <div className={classes.paper}>
						<CircularProgress className={classes.loading}/>
					</div>}
					{['ERROR_SETTING_COOKIE'].includes(status) && <>
						<Typography className={classes.errorSettingCookie} align="center">Abbiamo riscontrato un problema durante il processamento della richiesta (020).</Typography>
						<Button
							fullWidth
							onClick={() => {
								setStatus('DO_LOGIN');
							}}
							size="large"
							variant="contained"
						>riprova</Button>
					</>}
					{['ERROR_VERIFY_LOGIN'].includes(status) && <div>
						<Typography className={classes.loginText} align="center">Per ragioni di sicurezza clicca sul tasto "Continua" e rieffettua l'accesso tramite il login via {{
							password: 'Email e Password',
							'microsoft.com': 'Microsoft',
							'google.com': 'Google',
							'emailLink': 'Email e Password.',
						}[method]}</Typography>
						<div className={classes.buttonContainer}>
						<Button
							fullWidth
							onClick={() => {
								setStatus('DOING_LOGIN');
								switch (method) {
									case 'password':
									case 'emailLink':
										history.replace(`/login-with-email?email=${email}&skip=domain`);
										break;
									case 'microsoft.com': 
										const microsoftOAuthProvider = new OAuthProvider('microsoft.com');

										microsoftOAuthProvider.setCustomParameters({
											prompt: 'select_account',
											login_hint: email,
										});

										
										signInWithRedirect(auth, microsoftOAuthProvider)
											.catch(e => {
												e && pushTrackingEvent({
													type: "TrackingEvent",
													data: {
														category:"Error", action:"FirebaseAuth", label: JSON.stringify({
															message: e.message,
															code: e.code,
															email: e.customData.email,
														})
													}
												});
												setStatus('ERROR_SIGN_WITH_REDIRECT');
											})
										break;
									case 'google.com': 
										const googleOAuthProvider = new GoogleAuthProvider();

										googleOAuthProvider.setCustomParameters({
											prompt: 'select_account',
											login_hint: email,
										});

										signInWithRedirect(auth, googleOAuthProvider)
											.catch(e => {
												e && pushTrackingEvent({
													type: "TrackingEvent",
													data: {
														category:"Error", action:"FirebaseAuth", label: JSON.stringify({
															message: e.message,
															code: e.code,
															email: e.customData.email,
														})
													}
												});
												setStatus('ERROR_SIGN_WITH_REDIRECT');
											})
										break;
								}
							}}
							size="large"
							variant="contained"
						>continua</Button>
						</div>
					</div>}
					{['MICROSOFT_ERROR', 'ERROR_SIGN_WITH_REDIRECT'].includes(status) && <div>
						{status === 'MICROSOFT_ERROR' && <Typography className={classes.loginText} align="center">Ops... l'email utilizzata è già registrata, usa il login via email e password per continuare. Se riscontri problemi chiama il <a style={{color: 'white'}}href='tel:+390289763305'>+39 0289763305</a></Typography>}
						{status === 'ERROR_SIGN_WITH_REDIRECT' && <Typography className={classes.loginText} align="center">Abbiamo riscontrato un problema temporaneo con i nostri provider. Se riscontri problemi chiama il <a style={{color: 'white'}}href='tel:+390289763305'>+39 0289763305</a></Typography>}
						<div className={classes.buttonContainer}>
							<Button
								classes={{
									startIcon: classes.startIconCustom
								}}
								className={classes.button}
								onClick={() => {
									history.replace("/login-with-email");
								}}
								fullWidth
								size="large"
								variant="contained"
								startIcon={<MailOutlineIcon />}
								>Accedi con Email</Button>
						</div>
					</div>}
					{status === 'DO_LOGIN' && <div>
						<Typography className={classes.loginText} align="center"><strong>Benvenuto su Foorban!</strong> Accedi con Google (es. Gmail), Microsoft (es. Outlook, Hotmail) oppure accedi con email per gli altri providers.<br />Se riscontri problemi chiama il <a style={{color: 'white'}}href='tel:+390289763305'>+39 0289763305</a></Typography>
						<div className={classes.buttonContainer}>
							<Button
								classes={{
									startIcon: classes.startIconCustom
								}}
								className={classes.button}
								onClick={() => {
									setStatus('DOING_LOGIN');
									const microsoftOAuthProvider = new OAuthProvider('microsoft.com');

									microsoftOAuthProvider.setCustomParameters({
										prompt: 'select_account'
									});

									
									signInWithRedirect(auth, microsoftOAuthProvider)
										.catch(e => {
											e && pushTrackingEvent({
												type: "TrackingEvent",
												data: {
													category:"Error", action:"FirebaseAuth", label: JSON.stringify({
														message: e.message,
														code: e.code,
														email: e.customData.email,
													})
												}
											});
											setStatus('ERROR_SIGN_WITH_REDIRECT');
										})
								}}
								fullWidth
								size="large"
								variant="contained"
								startIcon={<MicrosoftIcon></MicrosoftIcon>}
								>Accedi con Microsoft</Button>
							<Button
								classes={{
									startIcon: classes.startIconCustom
								}}
								className={classes.button}
								onClick={() => {
									setStatus('DOING_LOGIN');
									const googleOAuthProvider = new GoogleAuthProvider();

									googleOAuthProvider.setCustomParameters({
										prompt: 'select_account'
									});
									
									signInWithRedirect(auth, googleOAuthProvider)
										.catch(e => {
											e && pushTrackingEvent({
												type: "TrackingEvent",
												data: {
													category:"Error", action:"FirebaseAuth", label: JSON.stringify({
														message: e.message,
														code: e.code,
														email: e.customData.email,
													})
												}
											});
											setStatus('ERROR_SIGN_WITH_REDIRECT');
										})
									}}
								fullWidth
								size="large"
								variant="contained"
								startIcon={<GoogleIcon></GoogleIcon>}
								>Accedi con Google</Button>
							<Button
								classes={{
									startIcon: classes.startIconCustom
								}}
								className={classes.button}
								onClick={() => {
									history.replace("/login-with-email");
								}}
								fullWidth
								size="large"
								variant="contained"
								startIcon={<MailOutlineIcon />}
								>Accedi con Email</Button>
						</div>
					</div>}
				</div>
			</Container>
		</div>
	);
}