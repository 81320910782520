import React from 'react';

//import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
//import Button from '@material-ui/core/Button';
//import * as firebase from 'firebase/app';
//import firebaseConfig from './firebaseConfig';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flex: 1,
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh',
		backgroundColor: theme.palette.primary.main
	},
	paper: {
		paddingTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent'
	},
	loading: {
		color: 'white'
	},
	title: {
		color: 'white',
		flexGrow: 1,
		textAlign: 'center',
		fontWeight: 700
	},
}));

export default function Loading() {
	const classes = useStyles();
	return (
		<LoadingContainer>
			<CircularProgress className={classes.loading}/>
		</LoadingContainer>
	);
}

export function LoadingContainer(props) {
	const classes = useStyles();
	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs" >
				<div className={classes.paper}>
					<Typography className={classes.title} variant="h4" noWrap>FOORBAN</Typography>
					<div className={classes.paper}>
						{props.children}
					</div>
				</div>
            </Container>
		</div>
	);
}