import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: '100%',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        // alignItems: 'center',
        padding: 0,
        marginTop: '64px',
        [theme.breakpoints.down('xs')]: {
			marginTop: '56px',
		}
    },
    menuButton: {
        marginRight: theme.spacing(2),
      },
    paper: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

export default (props) => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <AppBar color="inherit" position="fixed">
                <Toolbar>
                    {props.action && <IconButton onClick={props.action} edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                        <Close />
                    </IconButton>}
                    <Typography variant="h6" noWrap>{props.title}</Typography>
                </Toolbar>
            </AppBar>
            {props.loading ? <div className={classes.paper}><CircularProgress  /></div> : props.children}
        </div>
    );
}