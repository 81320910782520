import { UPDATE_CORPORATE_CREDIT_BALANCE, EMPTY_CORPORATE_CREDIT_BALANCE } from '../';

const corporateCredit = (state = null, action) => {
	switch (action.type) {
	case UPDATE_CORPORATE_CREDIT_BALANCE:
		return {
			balance: action.balance
		};
	case EMPTY_CORPORATE_CREDIT_BALANCE:
		return {
			balance: 0
		};
	default:
		return state
	}
}

export default corporateCredit;