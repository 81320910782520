import React, { useState, useEffect, Dispatch, SetStateAction} from 'react';
import Container from '@material-ui/core/Container';
import Funnel from '../../templates/contents/Funnel';
import { OrderListResponse } from './interfaces';
import DataProvider from '../../provider';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { OrderListItem } from './OrderListItem';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(3, 2, 0),
	}
}));

type OrderListStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING';

export default function OrderList(props: any) {
    const [status, setStatus] = useState<OrderListStatus>('FETCH_DATA');
    const [orderList, setOrderList] = useState<OrderListResponse | null>(null);
    const classes = useStyles();

	useEffect(() => {
		if('FETCH_DATA' === status ) {
			setStatus('FETCHING_DATA');
            const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
            dataProvider(`b2c/selling-order/list?take=100`)
                .then((data: OrderListResponse) => {
                    setOrderList(data);
                    setStatus('DATA_FETCHED');
                })
                .catch(e => {
                    setStatus('ERROR_DATA_FETCHING');
                    console.log(e)
                })
        }
	}, [status]);
	return (
		<Funnel title="Lista Ordini" loading={status === 'FETCHING_DATA'}>
			<Container className={classes.container} component="main" maxWidth="xs">
				<OrderListContent
					email={props.navigation.user.email}
					list={orderList}
					status={status}
					changeStatus={setStatus}
				/>
			</Container>
		</Funnel>
	);
}

function OrderListContent(props: {
	list: OrderListResponse | null;
	status: OrderListStatus;
	email: string;
	changeStatus: Dispatch<SetStateAction<OrderListStatus>>;
}) {
	switch (props.status) {
		case 'DATA_FETCHED':
			if(!props.list?.data.length) {
				return <Typography>Non hai ancora nessun ordine</Typography>
			}
			return <>{props.list?.data.map((order, key) => {
				return (
					<OrderListItem key={`order-item-${key}`} order={order} email={props.email}/>
				)
			})}</>
		case 'ERROR_DATA_FETCHING':
			return (
				<>
					<Typography>Errore durente il caricamento</Typography>
					<Button
						onClick={() => {
							props.changeStatus('FETCH_DATA');
						}}
						fullWidth={true}
						variant="contained"
						size="large"
						color="primary"
					>
						RIPROVA
					</Button>
				</>
			);
		case 'FETCH_DATA':
		case 'FETCHING_DATA':
		default:
			return null;
	}
}