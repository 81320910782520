import { UPDATE_STATUS, SellingAreaStatus } from '../';

const status = (state = SellingAreaStatus.FETCH_DATA, action) => {
	switch (action.type) {
	case UPDATE_STATUS:
		return action.status;
	default:
		return state
	}
}

export default status;