import React, { useState, useEffect, Dispatch, SetStateAction } from 'react';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Funnel from '../../../templates/contents/Funnel';
import DataProvider from '../../../provider';
import { WalletRechargeInfo } from '../interfaces';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) => ({
	input: {
		padding: theme.spacing(2, 0, 0),
	},
	button: {
		margin: theme.spacing(2, 0, 0),
	},
	container: {
		padding: theme.spacing(4),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	text: {
		padding: theme.spacing(2, 0),
	},
	paper: {
        width: '100%',
        height: '70vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    }
}));

type WalletRechargeInfoStatus = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'FETCHING_DATA_MAX_ATTEMPT';

export default function WalletRecharge (props: any) {
    const [status, setStatus] = useState<WalletRechargeInfoStatus>('FETCH_DATA');
	const [walletRechargeInfo, setWalletRechargeInfo] = useState<WalletRechargeInfo | null>(null);
	const [retryCount, setRetryCount] = useState<number>(0);
	const classes = useStyles();
	
	let params = useParams();
	let uuid = params['uuid'];
    useEffect(() => {
		let timeout: null | NodeJS.Timeout = null
		if(uuid && 'FETCH_DATA' === status ) {
			if(retryCount < 6) {
				setStatus('FETCHING_DATA');
				const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
				dataProvider(`b2c/wallet-recharge/${uuid}`)
					.then((data: WalletRechargeInfo) => {
						switch (data.actionType) {
							case 'loading':	
								if(timeout) {
									clearTimeout(timeout);
								}
								timeout = setTimeout(() => {
									setRetryCount(retryCount + 1);
									setStatus('FETCH_DATA');
								}, 2000);
								break;
							case 'redirect':
							case 'cancelled':
							case 'error':
							default:
								break;
						}
						setWalletRechargeInfo(data);

						if(data.actionType !== 'loading') {
							setStatus('DATA_FETCHED');
						}
					})
					.catch(e => {
						setStatus('ERROR_DATA_FETCHING');
						console.log(e)
					})
			} else {
				setStatus('FETCHING_DATA_MAX_ATTEMPT');
			}
		}
		return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
		}
	}, [status, uuid, retryCount]);

    return <Funnel title="Ricarica Wallet" loading={['FETCH_DATA', 'FETCHING_DATA'].includes(status)}>
        <Container className={classes.container} component="main" maxWidth="xs">
			<WalletRechargeInfoComponent
				data={walletRechargeInfo}
				status={status}
				changeStatus={setStatus}
				setRetryCount={setRetryCount}
			/>
        </Container>
    </Funnel>
}

function WalletRechargeInfoComponent(props: {
	data: WalletRechargeInfo | null;
	status: WalletRechargeInfoStatus;
	changeStatus: Dispatch<SetStateAction<WalletRechargeInfoStatus>>;
	setRetryCount: Dispatch<SetStateAction<number>>;
}) {
	const history = useHistory();
	const classes = useStyles();

	switch (props.status) {
		case 'DATA_FETCHED':
			switch (props.data?.actionType) {
				case 'redirect':
					if(props.data?.linkAction?.type === 'internal') {
						history.replace(props.data?.linkAction?.link);
					} else if(props.data?.linkAction?.type === 'external') {
						window.location.href = props.data?.linkAction?.link;
					}
					return null;
				case 'loading':
					return null;
				case 'cancelled':
					return (
						<>
							<Typography className={classes.text}>{props.data.message ? props.data.message : 'Ricarica cancellata'}</Typography>
						</>
					);
				case 'error':
					return (
						<>
							<Typography className={classes.text}>{props.data.message ? props.data.message : 'Errore durante il caricamento'}</Typography>
							<Button
								onClick={history.goBack}
								fullWidth={true}
								variant="contained"
								size="large"
								color="primary"
							>
								RIPROVA
							</Button>
						</>
					);
				default:
					return null;
			}
		case 'ERROR_DATA_FETCHING':
			return (
				<>
					<Typography className={classes.text}>Errore durente il caricamento</Typography>
					<Button
						onClick={() => {
							props.changeStatus('FETCH_DATA');
						}}
						fullWidth={true}
						variant="contained"
						size="large"
						color="primary"
					>
						RIPROVA
					</Button>
				</>
			);
		case 'FETCHING_DATA_MAX_ATTEMPT':
			return (
				<>
					<Typography className={classes.text}>Ricarica in corso</Typography>
					<Button
						onClick={() => {
							props.setRetryCount(0);
							props.changeStatus('FETCH_DATA');
						}}
						fullWidth={true}
						variant="contained"
						size="large"
						color="primary"
					>
						RIPROVA
					</Button>
				</>
			);
		case 'FETCH_DATA':
		case 'FETCHING_DATA':
		default:
			return null;
	}
}