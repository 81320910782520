import React, { useState, useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import QrReader from 'react-qr-reader'
import { makeStyles, Theme } from '@material-ui/core/styles';

const STATUS = {
	LOADING: "LOADING",
	QRCODE_NOT_SUPPORTED: 'QRCODE_NOT_SUPPORTED',
	PERMISSION_DENIED: 'PERMISSION_DENIED',
	SHOW_BUTTON: 'SHOW_BUTTON',
	SHOW_READER: 'SHOW_READER'
}

const useStyles = makeStyles((theme: Theme) => ({
	qrWrapper: {
		width: "100%"
	},
	divider: {
		padding: theme.spacing(4, 0, 2)
	},
	containerCode: {
		width: '100%',
    	flex: 1,
    	flexDirection: 'column',
    	justifyContent: 'center',
    	alignItems: 'center',
    	display: 'flex'
	},
	container: {
		padding: theme.spacing(2, 0, 0)
	}
}));

let hidden: any = null;
let visibilityChange: any = null;
if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support 
  hidden = 'hidden';
  visibilityChange = 'visibilitychange';
} else if (typeof document['msHidden'] !== 'undefined') {
  hidden = 'msHidden';
  visibilityChange = 'msvisibilitychange';
} else if (typeof document['webkitHidden'] !== 'undefined') {
  hidden = 'webkitHidden';
  visibilityChange = 'webkitvisibilitychange';
}

export default (props: any) => {
	const classes = useStyles();
	const [closedTab, setClosedTab] = useState<boolean>(false);
	const [status, setStatus] = useState(
		navigator &&
		navigator.mediaDevices &&
		navigator.mediaDevices.getSupportedConstraints &&
		navigator.mediaDevices['enumerateDevices'] &&
		navigator.mediaDevices.getSupportedConstraints().facingMode ? STATUS.LOADING : STATUS.QRCODE_NOT_SUPPORTED);
	
	useEffect(() => {
		if(status === STATUS.LOADING) {
			navigator.mediaDevices.enumerateDevices().then(devices => {
				let show = true;
				//let devicesKinds = 0
				devices.forEach(device => {
					if(device.kind === "videoinput" ) {
						//devicesKinds ++;
					}

					if(device.kind === "videoinput" && device.label.length > 0) {
						show = false;
					}
	
				})
				/*if(devicesKinds < 2) {
					setStatus(STATUS.QRCODE_NOT_SUPPORTED);   
				}
				else */if(show) {
					setStatus(STATUS.SHOW_BUTTON);
				} else {
					setStatus(STATUS.SHOW_READER);
				}
			})
		}
	}, [status, setStatus]);

	useEffect(() => {
		const handleVisibilityChange = () => {
			setClosedTab(document[hidden]);
		}

		document.addEventListener(visibilityChange, handleVisibilityChange, false);

		return () => {
			document.removeEventListener(visibilityChange, handleVisibilityChange);
		}
	});

	if(STATUS.LOADING === status) {
		return <CircularProgress />
	}

	if(STATUS.QRCODE_NOT_SUPPORTED === status) {
		return <div className={classes.container}><Typography>Lettore QR-Code non supportato</Typography></div>
	}

	if(STATUS.PERMISSION_DENIED === status) {
		return <div className={classes.container}><Typography>Permessi non validi lettore QR-Code</Typography></div>
	}

	if(STATUS.SHOW_BUTTON === status || closedTab) {
		return <div className={classes.container}><Button variant="contained" color="primary" onClick={() => { setStatus(STATUS.SHOW_READER)} }>Abilita il lettore QR-Code</Button></div>
	}

	if(STATUS.SHOW_READER === status) {
		return <div className={classes.containerCode}>
			<QrReader
					// style={{
					// 	boxSizing: 'border-box',
					// 	border: '50px solid rgba(8, 109, 64, 0.4)',
					// 	boxShadow: 'rgba(8, 109, 64, 0.5) 0px 0px 0px 5px inset',
					// }}
					showViewFinder={false}
					constraints={{facingMode: { exact: "environment" }}}
					className={classes.qrWrapper}
					delay={300}
					onError={(e) => {
						if(e instanceof DOMException && e.message === "Permission denied") {
							setStatus(STATUS.PERMISSION_DENIED)
						}
						else {
							setStatus(STATUS.QRCODE_NOT_SUPPORTED)
						}
					}}
					onScan={(scanValue) => {

						if(!scanValue)
							return;

						const results = /https:\/\/[a-z.]*foorban\.business\/code-landing\/([a-z0-9]*)(?:\?)?/gm.exec(scanValue);

						if(results && results.length > 1) {
							props.goTo(results[1]);
						}

					}}
				/>
				<Typography className={classes.divider}>oppure</Typography>
			</div>
		;
	}
	return null;
}