import React from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { useDispatch, useSelector } from 'react-redux';
import { cartAction } from '../state/actions';
import { useParams } from 'react-router-dom';
import { SellingAreaStatus } from '../state';

const useStyles = makeStyles((theme: Theme) => ({
	cardActions: {
		display: 'flex',
		flexDirection: 'column',
		padding: theme.spacing(2),
		alignItems: 'flex-start',
	},
	title: {
		width: '100%'
	},
	iconButton: {
		color: 'white',
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.primary.main
		}
	},
	quantity: {
		padding: theme.spacing(0, 1),
	},
	card: {
		margin: theme.spacing(1, 0, 0)
	},
	editItem: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'space-between'
	},
	removeItem: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'center'
	},
}));

export default function ProductCardCart(props) {
	const classes = useStyles();
	let params = useParams();
	let id = params['id'];
	const wallet = useSelector(state => state.wallet);
	const corporateCredit = useSelector(state => state.corporateCredit);
	const sellingAreaStatus = useSelector(state => state.status);

	const dispatch = useDispatch();

  	return (
		<Card className={classes.card}>
			<CardActions disableSpacing={true} className={classes.cardActions}>
				<Typography variant="subtitle1" color="textPrimary">{props.item.label}</Typography>
				<div className={classes.editItem}>
					<Typography className={classes.title} variant="subtitle1" color="textPrimary">{`${parseFloat((props.item.unitPriceWithTax * props.item.quantity).toFixed(2))} €`}</Typography>
					<IconButton disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA} color="primary" size="small" onClick={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.itemSaleableId, 
								quantity: props.item.quantity - 1,
								localPrice: props.item.unitPriceWithTax,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity
								,walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}} className={classes.iconButton}>
							<RemoveIcon fontSize="small" />
					</IconButton>    
					<Typography className={classes.quantity} variant="subtitle1" color="textPrimary">{props.item.quantity}</Typography>
					<IconButton disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA} color="primary" size="small" onClick={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.itemSaleableId, 
								quantity: props.item.quantity + 1,
								localPrice: props.item.unitPriceWithTax,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}} className={classes.iconButton} >
							<AddIcon fontSize="small" />
					</IconButton>
				</div>
				<div className={classes.removeItem}>
					<Button disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA} onClick={() => {
						cartAction.updateCartItem(dispatch, {
							lockerId: id,
							itemSaleableId: props.item.itemSaleableId, 
							quantity: 0,
							localPrice: props.item.unitPriceWithTax,
							localLabel: props.item.label,
							availableQuantity: props.item.availableQuantity,
							walletBalance: wallet?.balance || 0,
							corporateCreditBalance: corporateCredit?.balance || 0,
						})
					}} color="primary" size="small">{'RIMUOVI'}</Button>
				</div>
			</CardActions>
		</Card>
  );
}