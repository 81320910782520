import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { CardActionArea } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import ProductCardBig from '../productCard/ProductCardBig';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InfoIcon from '@material-ui/icons/Info';
import { cartAction } from '../state/actions';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import LabelsCard from '../components/LabelsCard';
import LabelPreference from '../components/LabelPreference';
import { SellingAreaStatus } from '../state';
import { Diet } from '../components/Icons';
import { SellingAreaState, DietPreference } from '../state';
import { AllergensListLabel } from '../state/reducers/diet';

const useStyles = makeStyles((theme) => ({
	cardMedia: {
		paddingTop: '100%'
	},
	cardContent: {
		height: '118px',
		padding: theme.spacing(1),
		overflow: 'hidden',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center'
	},
	cardActions: {
		flexDirection: 'column',
		padding: theme.spacing(1, 0, 0)
	},
	cardAction: {
		margin: theme.spacing(0, 0, 1)
	},
	paddingButton: {
		padding: theme.spacing(0.75, 1),
		margin: theme.spacing(0, 1, 1, 1)
	},
	quantityRow: {
		margin: theme.spacing(0, 0, 1),
		display: 'flex',
		flexDirection: 'row'
	},
	iconButton: {
		color: 'white',
		margin: theme.spacing(0, -2),
	},
	iconButtonColored: {
		backgroundColor: theme.palette.primary.main,
		"&:hover": {
			backgroundColor: theme.palette.primary.main,
		},
	},
	iconButtonColoredDisabled: {
		backgroundColor: 'rgb(224, 224, 224)',
		"&:hover": {
			backgroundColor: 'rgb(224, 224, 224)',
		},
	},
	quantityRowText: {
		fontSize: '0.875rem',
		textAlign: 'center',
		color: 'white',
		padding: '6px 50px',
		[theme.breakpoints.down('xs')]: {
			padding: '6px 43px',
		}
	},
	quantityRowTextColored: {
		backgroundColor: theme.palette.primary.main,
	},
	quantityRowTextColoredDisabled: {
		backgroundColor: 'rgb(224, 224, 224)',
	},
	pricesContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginBottom: '8px'
	},
	oldPrice: {
		marginLeft: '8px',
		textDecoration : 'line-through',
		paddingTop: '2px',
		opacity: 0.7
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1)
	},
}));


const DialogContent = withStyles((theme) => ({
	root: {
		height: '393px',
		[theme.breakpoints.down('md')]: {
			width: '100%'
		},
		[theme.breakpoints.up('md')]: {
			width: '645px',
		},
		padding: theme.spacing(0),
		"&:first-child": {
			paddingTop: 0,
		}
	}
}))(MuiDialogContent);

function ProductCard(props: {
	[key: string]: any;
	cardColor?: string;
}) {
	const classes = useStyles();
	const dispatch = useDispatch();
	const cart = useSelector(state => state.cart);
	const wallet = useSelector(state => state.wallet);
	const corporateCredit = useSelector(state => state.corporateCredit);
	const sellingAreaStatus = useSelector(state => state.status);
	const diet: DietPreference | null = useSelector((state: SellingAreaState) => state.diet);
	const [showRedBorder, setShowRedBorder] = useState<boolean>(false)
	let params = useParams();
	let id = params['id'];
	let location = useLocation();
	let history = useHistory();

	useEffect(() => {
		if(!diet) {
			setShowRedBorder(false);
		} else {
			if(!props.item.edible) {
				setShowRedBorder(true);
				return;
			}
			let conditionDiet = false;
			let conditionAllergens = false;

			if(diet.diet) {
				conditionDiet = !(diet && diet.diet && {
					'Contiene derivati della carne o pesce': ['Contiene derivati della carne o pesce', 'Pescetariano', 'Vegetariano', 'Vegano'],
					Pescetariano: ['Pescetariano', 'Vegetariano', 'Vegano'],
					Vegetariano: ['Vegetariano', 'Vegano'],
					Vegano: ['Vegano'],
				}[diet.diet].includes(props.item.edible.diet));
			}

			if(diet.allergens) {
				conditionAllergens = Object.keys(AllergensListLabel).map(function(key: string) {
					if(diet.allergens) {
						switch (diet.allergens[key]) {
							case 'Presente':
								return false;
							case 'Tracce':
								return 'Presente' === props.item.edible[key];
							case 'Assente':
								return ['Presente','Tracce'].includes(props.item.edible[key]);
						}
					}
					return false;
				}).includes(true);
			}
			setShowRedBorder(conditionDiet || conditionAllergens);
		}
	}, [diet, id, props.item.edible])

	const mySearchParams = new URLSearchParams(location.search);

	const handleClickOpen = () => {
		mySearchParams.append("description", props.item.id);
		history.push({ pathname: location.pathname, search: mySearchParams.toString() });
	};

	const openUnsellableItemInfoPopup = () => {
		mySearchParams.append("unsellable-item-info-banner", "open");
		history.push({ pathname: location.pathname, search: mySearchParams.toString() });
	};

	const handleClose = () => {
		history.goBack();
	};

	let quantity = 0;

	if(props.showButton && cart) {
		cart.items.forEach(cartItem => {
			if(props.item.id === cartItem.itemSaleableId)
				quantity = cartItem.quantity;
		});
	}
	return (
		<Grid item xs={6} sm={6} md={4} lg={3}>
			<div item-saleable-id={props.item.id} >
			<Card style={props.cardColor ? {backgroundColor: props.cardColor} : {}}>
				<CardActionArea disabled={!props.showButton} onClick={handleClickOpen}>
					{props.item.edible && props.item.edible.diet && <Diet diet={props.item.edible.diet} />}
					{props.label && <LabelsCard color={props.labelColor}  label={props.label}/>}
					<CardMedia
						className={classes.cardMedia}
						image={props.item.image}
						title={props.item.label}
					/>
					{showRedBorder ? <LabelPreference label='escluso dal tuo filtro' /> : null}
					<CardContent className={classes.cardContent}>
						<Typography variant="subtitle1" color="textPrimary">{props.item.label}</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions disableSpacing={true} className={classes.cardActions}>
					{props.showPrice && <div className={classes.pricesContainer}>
						<Typography variant="h6" color="textPrimary">{props.item.price.label}</Typography>
						{props.item.price.listPriceLabel && <Typography className={classes.oldPrice} variant="subtitle1" color="textPrimary">{props.item.price.listPriceLabel}</Typography>}
					</div>}
					{mySearchParams.get('mode') !== 'preview' &&
					 props.showButton &&
					 quantity === 0 &&
					<Button
						disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA}
						endIcon={props.item.expired ? <InfoIcon /> : null}
						onClick={() => {
						if (props.item.expired) {
							openUnsellableItemInfoPopup()
						} else {
							cartAction.updateCartItem(dispatch, {
							lockerId: id,
							itemSaleableId: props.item.id,
							itemSaleablePriceListId: props.item.unique,
							quantity: quantity + 1,
							localPrice: props.item.price.value,
							localLabel: props.item.label,
							availableQuantity: props.item.availableQuantity,
							walletBalance: wallet?.balance || 0,
							corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}
						}}
						className={`${props.item.expired ? classes.paddingButton : classes.cardAction}`}
						color={props.item.expired ? "default": "primary"}
						variant="contained">
						{`${props.item.expired ? 'NON ACQUISTABILE' : 'LO VOGLIO'}`}
					</Button>
					}
					{mySearchParams.get('mode') !== 'preview' && props.showButton && quantity > 0 && <QuantityRow
						quantity={quantity}
						disabled={sellingAreaStatus === SellingAreaStatus.FETCHING_DATA}
						remove={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.id, 
								itemSaleablePriceListId: props.item.unique,
								quantity: quantity - 1,
								localPrice: props.item.price.value,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}}
						add={() => {
							cartAction.updateCartItem(dispatch, {
								lockerId: id,
								itemSaleableId: props.item.id, 
								itemSaleablePriceListId: props.item.unique,
								quantity: quantity + 1,
								localPrice: props.item.price.value,
								localLabel: props.item.label,
								availableQuantity: props.item.availableQuantity,
								walletBalance: wallet?.balance || 0,
								corporateCreditBalance: corporateCredit?.balance || 0,
							})
						}}
					></QuantityRow>}
					{mySearchParams.get('mode') === 'preview' && <Button onClick={() => {
						if(location.search.length > 0) {
							history.push(`${location.search}&leave=need-confirm`)
						}
						else {
							history.push(`?leave=need-confirm`)
						}
					}} className={classes.cardAction} color="primary" variant="contained">{'LO VOGLIO'}</Button>}
					{props.showButton && <Button onClick={handleClickOpen} className={classes.cardAction} color="primary" size="small">{'VEDI INGREDIENTI'}</Button>}
				</CardActions>
			</Card>
			</div>
			<Dialog fullScreen={isWidthDown('xs', props.width)} fullWidth={true} onClose={handleClose} aria-labelledby="customized-dialog-title" open={mySearchParams.get('description') === props.item.id.toString()}>
				<DialogContent style={{width: '100%'}}>
					<ProductCardBig hasDietaryOrAllergenRestrictions={showRedBorder} showButton={props.showButton} showPrice={props.showPrice} onClick={props.onClick} item={props.item}></ProductCardBig>
				</DialogContent>
			</Dialog>
		</Grid>
	);
}

export default withWidth()(ProductCard);

export function QuantityRow(props: any) {
	const classes = useStyles();

	return (
		<div className={props.className ? props.className : classes.quantityRow}>
			<IconButton disableRipple size="small" onClick={props.disabled ? null : props.remove} className={`${classes.iconButton} ${props.disabled ? classes.iconButtonColoredDisabled : classes.iconButtonColored}`}>
				<RemoveIcon fontSize="small" />
			</IconButton>  
			<Typography className={`${classes.quantityRowText} ${props.disabled ? classes.quantityRowTextColoredDisabled : classes.quantityRowTextColored}`} variant="subtitle1" color="textPrimary">{props.quantity}</Typography>
			<IconButton disableRipple size="small" onClick={props.disabled ? null : props.add} className={`${classes.iconButton} ${props.disabled ? classes.iconButtonColoredDisabled : classes.iconButtonColored}`}>
				<AddIcon fontSize="small" />
			</IconButton>
		</div>
	);
}