import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Button, { ButtonProps as MuiButtonProps } from '@material-ui/core/Button';
import ButtonBase from '@material-ui/core/ButtonBase';

import { updateCurrent } from '../actions';
import { Link, LinkAction as LinkActionModel } from '../models/link';
import { CodeType } from '../models/code';


export enum LinkActionType {
    Button = "button",
    ButtonBase = "button-base"
}

interface LinkActionProps {
    className?: string,
    buttonProps?: MuiButtonProps,
    label?: string,
    type: LinkActionType,
    link: Link | LinkActionModel,
    children?: ReactNode,
    fake?: boolean
}

export default function LinkAction(props: LinkActionProps) {
    const history = useHistory();
    const dispatch = useDispatch();

    switch (props.type) {
        case LinkActionType.Button:
            return <Button className={props.className} {...props.buttonProps} onClick={() => {
                if(props.fake) {
                    return null;
                }
                if(props.link.type === CodeType.internal) {
                    if((props.link as Link).id !== undefined) {
                        dispatch(updateCurrent((props.link as Link).id));
                    }
                    history.push(props.link.link);
                } else {
                    window.location.href = props.link.link;
                }
            }}>
                {props.label ? props.label : props.link.label}
            </Button>;
        case LinkActionType.ButtonBase:
            if(props.children) {
                return (
                    <ButtonBase className={props.className} {...props.buttonProps} onClick={() => {
                        if(props.fake) {
                            return null;
                        }
                        if(props.link.type === CodeType.internal) {
                            if((props.link as Link).id !== undefined) {
                                dispatch(updateCurrent((props.link as Link).id));
                            }
                            history.push(props.link.link);
                        } else {
                            window.location.href = props.link.link;
                        }
                    }}>
                        {props.children}
                    </ButtonBase>
                );
            }
            return null;
        default:
            return null;
    }
}