import React from 'react';
import { useSelector } from 'react-redux';
import PaymentInstrumentDefinedAmount from './PaymentInstrument';
import PaymentInstrumentPartialAmount from './PaymentInstrumentPA';
import Wallet from './Wallet';
import CorporateCredit from './CorporateCredit';

export default function PaymentMethods(props: {
    isPreview?: boolean
}) {
    const totalToPayFromCorporateCredit = useSelector(state => state.cart.totalToPayFromCorporateCredit);
    const totalToPayFromWallet = useSelector(state => state.cart.totalToPayFromWallet);
    const totalToPay  = useSelector(state => state.cart.totalToPay);

    return (
        <div style={{margin: '0 0 8px'}}>
            {totalToPayFromCorporateCredit > 0 && <CorporateCredit />}
            {totalToPayFromWallet > 0 && <Wallet />}
            {totalToPay > 0 && <PaymentInstrumentPartialAmount />}
            {totalToPay > 0 && <PaymentInstrumentDefinedAmount isPreview={props.isPreview} />}
        </div>
    );
} 