import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';

const useStylesLabelsCard = (color?: string) => makeStyles((theme: Theme ) => ({
    container: {
        top: '8px',
        maxWidth: '98%',
        whiteSpace: 'nowrap',
        flexDirection: 'column',
        overflow: 'hidden',
        position: 'absolute',
        display: 'flex',
        pointerEvents: 'none'
    },
    chip: {
        fontWeight: 700,
        borderRadius: '0px 4px 4px 0px',
        backgroundColor: color ? color : "#E85414",
        color: 'white',
        fontSize: '1rem',
        [theme.breakpoints.down('xs')]: {
			fontSize: '0.8rem',
		},
   }
}));

export default function LabelsCard(props: {
    label: string;
    color?: string;
}) {
    const classes = useStylesLabelsCard(props.color)();

    return (
        <div className={classes.container}>
            <Chip
                className={classes.chip}
                label={props.label ? props.label.toUpperCase() : ''}
            />
        </div>
    )
}