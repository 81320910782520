import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getAuth, verifyPasswordResetCode, confirmPasswordReset, signInWithEmailAndPassword } from "firebase/auth";
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Loading from '../Loading';
import { Container } from './LoginWithEmailPassword';
import Alert from '@material-ui/lab/Alert';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { pushTrackingEvent } from '../utils/gtmDataLayer';
import { linkCredential } from '../utils/firebase';
import { setCookie } from '../utils/auth';

const useStyles = makeStyles((theme: Theme) => ({
    formContainer: {
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: theme.spacing(2, 0),
    },
    action: {
        margin: theme.spacing(2),
    },
    password: {
        margin: theme.spacing(1, 0),
    },
    alert: {
        margin: theme.spacing(0, 0, 1),
        width: '100%',
    },
    actionLink: {
        margin: theme.spacing(2, 0, 0),
    },
    privateText: {
        margin: theme.spacing(2, 0, 0),
    },
    errorSettingCookie: {
		padding: theme.spacing(0, 0, 4),
	}
}));

export default function LoginWithToken(props: any) {
    const location = useLocation();
    const history = useHistory();
    const [status, setStatus] = useState<
        | 'CHECK_ACTION_CODE'
        | 'CHECKING_ACTION_CODE'
        | 'RESETTING_PASSWORD'
        | 'RESET_PASSWORD'
        | 'DO_LOGIN'
        | 'DOING_LOGIN'
        | 'DO_REDIRECT'
        | 'DOING_REDIRECT'
        | 'ERROR_CHECKING_ACTION_CODE'
        | 'ERROR_RESETTING_PASSWORD'
        | 'ERROR_DOING_LOGIN'
        | 'START_PASSWORD_RESET'
        | 'INVALID_PASSWORD'
        | 'SHOW_SCAN_QRCODE'
        | 'SHOW_USE_NFC'
        | 'ERROR_SETTING_COOKIE'
    >('CHECK_ACTION_CODE')

    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [email, setEmail] = useState("");
    const classes = useStyles();

    const mySearchParams = new URLSearchParams(location.search);
    const oobCode = mySearchParams.get('oobCode') || '';
    const continueURL = mySearchParams.get('continueUrl') || '';
    const mode = mySearchParams.get('mode') || '';
    const auth = getAuth();

    useEffect(() => {
        if (status === 'CHECK_ACTION_CODE' && mode === 'resetPassword' && oobCode) {
            setStatus('CHECKING_ACTION_CODE');
            
            verifyPasswordResetCode(auth, oobCode)
                .then((email) => {
                    setEmail(email);
                    setStatus('START_PASSWORD_RESET');
                })
                .catch((e) => {
                    e && pushTrackingEvent({
                        type: "TrackingEvent",
                        data: {
                            category:"Error", action:"FirebaseAuth", label: JSON.stringify({message: e.message,code: e.code,email: e.customData.email})
                        }
                    });
                    setStatus('ERROR_CHECKING_ACTION_CODE');
                })
        } else if (status === 'CHECK_ACTION_CODE' && mode !== 'resetPassword') {
            setStatus('ERROR_CHECKING_ACTION_CODE');
        }

        if (status === 'RESET_PASSWORD') {
            setStatus('RESETTING_PASSWORD');
            
            confirmPasswordReset(auth, oobCode, password)
                .then(() => {
                    setStatus('DO_LOGIN');
                }).catch((e) => {
                    e && pushTrackingEvent({
                        type: "TrackingEvent",
                        data: {
                            category:"Error", action:"FirebaseAuth", label: JSON.stringify({message: e.message,code: e.code,email: e.customData.email})
                        }
                    });
                    setStatus('ERROR_RESETTING_PASSWORD');
                });
        }

        if (status === 'DO_LOGIN') {
            setStatus('DOING_LOGIN');
            
            signInWithEmailAndPassword(auth, email, password)
                .then((response) => {
                    return linkCredential(response);
                })
                .then(() => {
                    setStatus('DO_REDIRECT');
                })
                .catch((e) => {
                    e && pushTrackingEvent({
                        type: "TrackingEvent",
                        data: {
                            category:"Error", action:"FirebaseAuth", label: JSON.stringify({message: e.message,code: e.code,email: e.customData.email})
                        }
                    });
                    setStatus('ERROR_DOING_LOGIN');
                })
        }

        if (status === 'DO_REDIRECT') {
            setStatus('DOING_REDIRECT');
            const url = new URL(continueURL);
            const searchParams = new URLSearchParams(url.search);
            setCookie()
                .then(() => {
                    if(searchParams.get('foorban_code_landing_medium') === 'nfc') {
                        setStatus('SHOW_USE_NFC')
                    } else if(searchParams.get('foorban_code_landing_medium') === 'qr-code') {
                        setStatus('SHOW_SCAN_QRCODE')
                    } else {
                        window.location.href = continueURL
                    }
                })
                .catch(() => {
                    setStatus('ERROR_SETTING_COOKIE');
                });
        }
        return () => { }
    }, [mode, oobCode, password, status, email, continueURL, props.user, auth])

    if (['SHOW_SCAN_QRCODE','SHOW_USE_NFC'].includes(status)) {
        return (
            <Container maxWidth="xs">
                {status === 'SHOW_SCAN_QRCODE' && <Typography align="center">Per completare il login scannerizza nuovamente il QR code.</Typography>}
                {status === 'SHOW_USE_NFC' && <Typography align="center">Per completare il login appoggia nuovamente il telefono al tag NFC.</Typography>}
                <Typography className={classes.privateText} align="center">Disattiva la navigazione in incognito/privata per restare sempre loggato.</Typography>
            </Container>
        );
    }

    if ([
        'CHECK_ACTION_CODE',
        'CHECKING_ACTION_CODE',
        'RESETTING_PASSWORD',
        'RESET_PASSWORD',
        'DO_LOGIN',
        'DOING_LOGIN',
        'DO_REDIRECT',
        'DOING_REDIRECT',
    ].includes(status)) {
        return <Loading />;
    }

    if(status === 'ERROR_SETTING_COOKIE') {
        return (
            <Container maxWidth="xs">
                <Typography className={classes.errorSettingCookie} align="center">Abbiamo riscontrato un problema durante il processamento della richiesta (022).</Typography>
                <Button
                    fullWidth
                    color="primary"
                    onClick={() => {
                        setStatus('DO_LOGIN');
                    }}
                    size="large"
                    variant="contained"
                >riprova</Button>
            </Container>
        );
    }

    return (
        <Container maxWidth="xs">
            {[
                'ERROR_CHECKING_ACTION_CODE',
                'ERROR_RESETTING_PASSWORD',
                'ERROR_DOING_LOGIN',
            ].includes(status) &&
            <Alert className={classes.alert} severity="warning">
                {status === 'ERROR_CHECKING_ACTION_CODE' ? 'Codice non valido' : ''}
                {status === 'ERROR_RESETTING_PASSWORD'? 'Errore durante il processamento della richiesta' : '' }
                {status === 'ERROR_DOING_LOGIN' ? 'Errore durante il processamento della richiesta' : '' }
            </Alert>}
            {status === 'INVALID_PASSWORD' && <Alert className={classes.alert} severity="error">
                La password inserita non è valida
            </Alert>}
            <div className={classes.formContainer}>
                {status !== 'ERROR_CHECKING_ACTION_CODE' && <TextField
                    disabled
                    inputProps={{
                        autoComplete: 'email',
                        type: 'email',
                    }}
                    fullWidth label="Email" value={email} />}
                {status !== 'ERROR_CHECKING_ACTION_CODE' && <TextField
                    autoFocus
                    helperText={status === 'INVALID_PASSWORD' ? 'Lunghezza minima 6 caratteri': ''}
                    className={classes.password}
                    error={status === 'INVALID_PASSWORD'}
                    InputProps={{
                        autoComplete: 'new-password',
                        type: showPassword ? 'text' : 'password',
                        endAdornment: <InputAdornment position="end">
                            <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {setShowPassword(!showPassword)}}
                            edge="end"
                            >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                        </InputAdornment>
                    }}
                    onChange={(event) => {
                        setPassword(event.target.value);
                        setStatus('START_PASSWORD_RESET')
                    }}
                    fullWidth label="Nuova Password" value={password} 
                />}
                {status !== 'ERROR_CHECKING_ACTION_CODE' && <Button
                    className={classes.action}
                    color="primary"
                    fullWidth
                    onClick={() => {
                        if(password.length < 6) {
                            setStatus('INVALID_PASSWORD')
                        } else {
                            setStatus('RESET_PASSWORD');
                        }
                    }}
                    size="large"
                    variant="contained">Modifica</Button>}
                {status === 'ERROR_CHECKING_ACTION_CODE' && <Button
                    fullWidth
                    onClick={() => {
                        history.replace('/login');
                    }}
                    size="large"
                    variant="contained"
                >Annulla</Button>}
            </div>
        </Container>
    );
}