import DataProvider from '../provider';

import { getAuth } from "firebase/auth";

/**
 * This method is used to get HttpOnly cookie necessary for authentication
 * @returns void
 */
 export async function setCookie(): Promise<{
    redirect: {
        type: 'internal' | 'external';
        link: 'string';
   }
} | undefined> {
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const defaultAuth = getAuth();
	const authorizationF = defaultAuth.currentUser ? await defaultAuth.currentUser.getIdToken() : '';
	return dataProvider(`b2c/auth/login`, {
        method: 'POST',
        body: JSON.stringify({
            authorizationF
        })
    })
}