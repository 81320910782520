
import { linkWithCredential, getAuth, UserCredential, OAuthCredential } from "firebase/auth";

export async function linkCredential(response: UserCredential): Promise<void | undefined> {
    try {
        const errorCredential = window.localStorage.getItem('error.credential');
        const errorCredentialEmail = window.localStorage.getItem('error.credential.email');
        
        if(errorCredential && errorCredentialEmail) {
            if(errorCredentialEmail === response.user?.email) {
                const pendingCredObj = JSON.parse(errorCredential);

                if(!pendingCredObj) {
                    throw new Error();
                }

                const pendingCredObjProv = OAuthCredential.fromJSON(pendingCredObj);
                const auth = getAuth(); 
                if(pendingCredObjProv && auth.currentUser) {
                    await linkWithCredential(auth.currentUser, pendingCredObjProv);
                    window.localStorage.removeItem('error.credential');
                    window.localStorage.removeItem('error.credential.email');
                } else {
                    throw new Error();
                }
            } else {
                throw new Error();
            }
        }
    } catch (error) {
        window.localStorage.removeItem('error.credential');
        window.localStorage.removeItem('error.credential.email');
        return Promise.resolve();
    }
}
