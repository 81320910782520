import React from "react";
import ProductCard from "../product/ProductCard";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) => ({
	categoryTitle: {
		padding: theme.spacing(3, 0),
        textTransform: 'uppercase'
	},
}));

export default function ChefRecommends({ categories, lockerId }) {
    const classes = useStyles();
  
    if(categories) {
        const recommends = categories.flatMap((category) =>
            category.items.filter((item) => item.new)
        );

        if (recommends.length > 0) {
            return (
                <div>
                    <Typography variant="h5" color="inherit" className={classes.categoryTitle}>
                        Lo chef consiglia &nbsp;<span role="img" aria-label='man-cook'>👨‍🍳</span>
                    </Typography>
    
                    <Grid container spacing={2}> 
                        {recommends.map((recommend: any) => 
                        <ProductCard
                            showButton={true}
                            showPrice={true}
                            key={recommend.id}
                            item={recommend}
                            lockerId={lockerId}
                            label={recommend.price.promoLabel ? recommend.price.promoLabel : recommend.tag}
                            labelColor={recommend.price.promoLabel ? '#E85414' : '#086d40'}
                            cardColor={'rgba(8, 109, 65, 0.15)'}
                        />)}
                    </Grid>
                </div>
            );
        }
    }
    
    
    return null;
}
