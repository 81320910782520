import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Funnel from '../../../../../templates/contents/Funnel';
import DataProvider from '../../../../../provider';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { InfoResponse, PaymentAttemptStatusList, PaymentAttemptStatus } from '../interfaces';
import { TicketRestaurant } from '../resources';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(4, 2),
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		flexDirection: 'column'
	},
	edenredContainer: {
        width: '100%',
        padding: '32px',
    },
    row: {
        margin: '32px 0 32px 0',
        color: '#1D204C',
        fontFamily: 'Montserrat',
        fontWeight: 700,
        fontSize: '20px',
    },
    row2: {
        margin: '24px 0 24px 0',
        color: '#8C8C8C',
        fontFamily: 'Montserrat',
        fontWeight: 600,
        fontSize: '14px',
    },
    text3: {
        fontWeight: 500,
        color: '#1D204C',
        fontFamily: 'Montserrat',
    },
	tableRow: {
		padding: '24px 0 24px 0',
		textAlign: 'center',
	},
	tableRowBorder: {
		borderBottom: '1px solid #8C8C8C'
	},
	table: {
		width: '100%',
		margin: '0 0 16px 0'
	},
	edenredLogo: {
		boxShadow: '3px 3px 20px rgba(0, 0, 0, .10)',
		backgroundColor: '#fff',
		display: 'flex',
		flexDirection: 'column',
        padding: '32px 0 32px 0',
	},
	baseButton: {
		margin: '0 0 8px 0'
	},
	baseText: {
		margin: '0 0 8px 0'
	},
	edenredBaseText: {
		margin: '24px 0 0'
	}
}));

type State = 'FETCH_DATA' | 'FETCHING_DATA' | 'DATA_FETCHED' | 'ERROR_DATA_FETCHING' | 'SUBMIT_DATA' | 'SUBMITTING_DATA' | 'ERROR_SUBMITTING_DATA' | 'REQUEST_SKIP' | 'SKIPPING' | 'ERROR_SKIPPING';

const loading_status: State[] = [
	'FETCH_DATA',
	'FETCHING_DATA',
	'SUBMIT_DATA',
	'SUBMITTING_DATA',
	'REQUEST_SKIP',
	'SKIPPING',
];

const error_status: State[] = [
	'ERROR_DATA_FETCHING',
	'ERROR_SUBMITTING_DATA',
	'ERROR_SKIPPING',
];

const show_edenred: PaymentAttemptStatus[] = [
	'NO_VOUCHERS',
	'EMPTY_WALLET',
	'NEED_LOGIN',
	'AWAITING_CONFIRM',
];

export default function Pay(props: any) {
	const classes = useStyles();
	const [status, setStatus] = useState<State>('FETCH_DATA');
	const [infoResponse, setInfoResponse] = useState<InfoResponse>();

	const history = useHistory();
	let params = useParams();
	let uuid = params['uuid'];

    useEffect(() => {
		if(status === 'FETCH_DATA' && uuid) {
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment/integration/edenred/edenred-ticket/${uuid}`)
				.then((data: InfoResponse) => {
					if(!data || !data.status || !PaymentAttemptStatusList.includes(data.status)) {
						throw new Error();
					}
					if(data.status === 'SKIPPED' || data.status === 'COMPLETED') {
						if(data?.redirect?.type === 'internal') {
							history.replace(data.redirect.url);
						} else if(data?.redirect?.type === 'external') {
							window.location.href = data.redirect.url;
						}
					} else {
						setInfoResponse(data);
						setStatus('DATA_FETCHED');
					}
				})
				.catch(e => {
					setStatus('ERROR_DATA_FETCHING');
				})
		}
	}, [status, uuid, history]);

	useEffect(() => {
		if(status === 'SUBMIT_DATA') {
			setStatus('SUBMITTING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment/integration/edenred/edenred-ticket/${uuid}`, {
				method: 'POST',
			})
			.then((data: InfoResponse) => {
				if(!data || !data.status || !PaymentAttemptStatusList.includes(data.status)) {
					throw new Error();
				}
				if(data.status === 'COMPLETED') {
					if(data?.redirect?.type === 'internal') {
						history.replace(data.redirect.url);
					} else if(data?.redirect?.type === 'external') {
						window.location.href = data.redirect.url;
					}
				} else {
					setInfoResponse(data);
					setStatus('DATA_FETCHED');
				}
			})
			.catch(() => {
				setStatus('ERROR_SUBMITTING_DATA');
			})
		}
	}, [status, uuid, history]);

	useEffect(() => {
		if(status === 'REQUEST_SKIP') {
			setStatus('SKIPPING');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment/integration/edenred/edenred-ticket/${uuid}/skip`, {
				method: 'POST',
			})
			.then((data: InfoResponse) => {
				if(!data || !data.status || !PaymentAttemptStatusList.includes(data.status)) {
					throw new Error();
				}
				if(data.status === 'SKIPPED') {
					if(data?.redirect?.type === 'internal') {
						history.replace(data.redirect.url);
					} else if(data?.redirect?.type === 'external') {
						window.location.href = data.redirect.url;
					}
				} else {
					setInfoResponse(data);
					setStatus('DATA_FETCHED');
				}
			})
			.catch(() => {
				setStatus('ERROR_SKIPPING');
			})
		}
	}, [status, uuid, history]);

    return (
		<Funnel title="Ticket Restaurant Edenred" loading={loading_status.includes(status)}>
			<Container className={classes.container} component="main" maxWidth="xs">
			{status === 'DATA_FETCHED' && <>
				{infoResponse?.status === 'LOADING' && <Typography align="center" className={classes.baseText}>La richiesta è più lenta del previsto.</Typography>}
				{infoResponse?.status === 'NOT_FOUND' && <Typography align="center" className={classes.baseText}>Non è stato trovato nessun tentativo do pagamento Edenred da confermare.</Typography>}
				{infoResponse?.status === 'ERROR' && <>
					<Typography align="center" className={classes.baseText}>{infoResponse?.failureReasons || 'Abbiamo riscontrato un problema.'}</Typography>
					{infoResponse?.failureReasons && !infoResponse?.failureReasons.includes('010') && !infoResponse?.failureReasons.includes('011') && <Typography align="center" className={classes.baseText}>Se hai ancora dubbi chiamaci al <Link href='tel:+390289763305'>+39 0289763305</Link>.</Typography>}
					{infoResponse?.failureReasons && (infoResponse?.failureReasons.includes('010') || infoResponse?.failureReasons.includes('011')) && <Typography align="center" className={classes.baseText}>Se hai ancora dubbi <Link onClick={() => history.push(`/wallet`)}>scarica la guida completa</Link> o chiamaci al <Link href='tel:+390289763305'>+39 0289763305</Link>.</Typography>}
				</>}
				{infoResponse?.status !== undefined && show_edenred.includes(infoResponse?.status) && <>
					<div className={classes.edenredContainer}>
						<div className={classes.edenredLogo}>
							<TicketRestaurant height={40}/>
						</div>
						{infoResponse?.status === 'NO_VOUCHERS' && <Typography align="center" className={classes.edenredBaseText}>Il taglio del tuo Ticket Restaurant è superiore della spesa selezionata.</Typography>}
						{infoResponse?.status === 'EMPTY_WALLET' && <Typography align="center" className={classes.edenredBaseText}>Il saldo nel tuo Cloud Ticket Restaurant è insufficiente. Se hai appena attivato il Cloud Edenred, devi aspettare la prossima ricarica mensile di Ticket Restaurant prima di poterli usare online.</Typography>}
						{infoResponse?.status === 'NEED_LOGIN' && <Typography align="center" className={classes.edenredBaseText}>Sessione scaduta effettua il login con l'utente {infoResponse?.username}.</Typography>}
						{infoResponse !== undefined && infoResponse?.vouchers !== undefined && infoResponse?.vouchers.length > 0 && <table className={classes.table} cellSpacing="0">
							<tr>
								<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row2}>BUONO</span></td>
								<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row2}>QUANTITÀ</span></td>
								<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row2}>TOTALE</span></td>
							</tr>
							{infoResponse !== undefined && infoResponse?.vouchers !== undefined && infoResponse.vouchers.map((voucher, index) => {
								return (
									<tr key={index}>
										<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row}>€{voucher.value / 100}</span></td>
										<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row}>{voucher.count}</span></td>
										<td className={`${classes.tableRow} ${classes.tableRowBorder}`}><span className={classes.row}>€{(voucher.value * voucher.count) / 100}</span></td>
									</tr>
								);
							})}
							{infoResponse !== undefined && infoResponse?.vouchers !== undefined && infoResponse?.vouchers.length > 1 && <tr>
								<td className={classes.tableRow}><span className={classes.row}>Totale</span></td>
								<td></td>
								<td className={classes.tableRow}><span className={classes.row}>€{infoResponse?.total}</span></td>
							</tr>}
						</table>}
					</div>
					{infoResponse !== undefined && infoResponse?.vouchers !== undefined && infoResponse?.vouchers.length > 0 && <Button
						className={classes.baseButton}
						fullWidth
						color="primary"
						size="large"
						onClick={() => {
							setStatus('SUBMIT_DATA');
						}}
						variant="contained">
							CONFERMA
					</Button>}
				</>}
				{infoResponse?.status === 'LOADING' && <Button
						className={classes.baseButton}
						fullWidth
						color="primary"
						size="large"
						variant="contained"
						onClick={() => {
							setStatus('FETCH_DATA');
						}}>
							RICARICA
					</Button>}
				{infoResponse?.status === 'NEED_LOGIN' && <Button
					className={classes.baseButton}
					fullWidth
					onClick={() => {
						history.push(`/payment/integration/edenred_ticket/add`);
					}}
					color="primary"
					size="large"
					variant="contained">
						Effettua login
				</Button>}
				{infoResponse?.status !== 'NOT_FOUND' && infoResponse?.status !== 'LOADING' && (!infoResponse?.skipOption || infoResponse?.skipOption === 'CAN-SKIP') && <Button
					className={classes.baseButton}
					fullWidth
					onClick={() => {
						setStatus('REQUEST_SKIP');
					}}
					color="primary"
					size="large"
					variant="outlined">
						Continua senza ticket
				</Button>}
				</>}
			{error_status.includes(status) && <>
				{status === 'ERROR_DATA_FETCHING' && <Typography className={classes.baseText} align="center">Errore recezione dati</Typography>}
				{(status === 'ERROR_SUBMITTING_DATA' || status === 'ERROR_SKIPPING') && <Typography className={classes.baseText} align="center">Errore invio dati</Typography>}
				<Button
					className={classes.baseButton}
					fullWidth
					color="primary"
					size="large"
					onClick={() => {
						switch (status) {
							case 'ERROR_DATA_FETCHING':
								setStatus('FETCH_DATA');
								break;
							case 'ERROR_SUBMITTING_DATA':
								setStatus('SUBMIT_DATA');
								break;
							case 'ERROR_SKIPPING':
								setStatus('REQUEST_SKIP');
								break;
						}				
					}}
					variant="contained">
						{(status === 'ERROR_SUBMITTING_DATA' || status === 'ERROR_SKIPPING') && 'RIPROVA'}
						{status === 'ERROR_DATA_FETCHING' && 'RICARICA'}
				</Button>
			</>}
			<Button
				color="primary"
				fullWidth
				onClick={() => {
					history.goBack();
				}}
				size="large"
				variant={infoResponse?.status !== 'NOT_FOUND' && infoResponse?.status !== 'LOADING' && (!infoResponse?.skipOption || infoResponse?.skipOption === 'CAN-SKIP') ? "text" : "outlined"}>
					ANNULLA
			</Button>
			</Container>
		</Funnel>
	);
}
