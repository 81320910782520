import React, { useEffect, useState, Dispatch, SetStateAction } from 'react';
import Base from '../../templates/Base';
import TopLevel from '../../templates/contents/TopLevel';
import Container from '@material-ui/core/Container';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useHistory } from "react-router-dom";
import DataProvider from '../../provider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import ButtonBase from '@material-ui/core/ButtonBase';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: 'flex',
		width: '100%',
	},
	details: {
		width: '100%',
		display: 'flex',
		flexDirection: 'column',
	},
	content: {
		display: 'flex', justifyContent: 'space-between', alignItems: 'center'
	},
	listSubheader: {
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between'
	},
	cardAction: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: theme.spacing(0, 0, 1),
	},
	otherCard: {
		width: '100%',
		padding: theme.spacing(2),
	},
	edenredCard: {
		width: '100%',
		padding:  theme.spacing(2, 1),
		alignItems: 'center',
		display: 'flex',
	},
	edenredLogoContainer: {
		alignItems: 'center',
		display: 'flex',
		margin: '0 8px 0 0',
	},
	edenredLogo: {
		width: '48px',
	},
	buttonBase: {
		width: '100%',
	},
	labelContainer: {
		maxWidth: '75%',
		width: '100%'
	},
	providerContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flex: 1
	}
}),
);

export default function Instrument(props: any) {
	const classes = useStyles();
	const history = useHistory();
	const [instruments, setInstruments] = useState<Array<any> | null>(null);
	const [instrumentLoading, setInstrumentLoading] = useState<number>(0);

	useEffect(() => {
		if(instruments) {
			return undefined;
		}

		const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
		dataProvider(`b2c/payment-instrument/`)
			.then(instrumentsData => {
				setInstruments(instrumentsData)
			})
			.catch(e => {
				console.log(e)
			})
	})

	return (
		<Base loading={false} navigation={props.navigation} >
			<TopLevel title="Pagamento" loading={false}>
				<Container component="main" maxWidth="xs">
					<List subheader={<ListSubheader disableSticky={true}><div className={classes.listSubheader}>Metodi di Pagamento<Button disabled={instrumentLoading !== 0}onClick={() => {
						const params = new URLSearchParams();
						params.set('key-to-update', 'INSTRUMENTS_ADDED_PI');
						params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));
						params.set('hide', 'payment');
						history.push(`/payment/payment-options/definedAmountMethod?${params.toString()}`, {
							key_to_update: 'INSTRUMENTS_ADDED_PI',
						});
					}}color="primary">Aggiungi nuovo</Button></div></ListSubheader>}>
						{instruments && instruments.map(instrument => {
							return (
								<ListItem key={instrument.id}>
									<InstrumentCard
										instrument={instrument}
										instrumentLoading={instrumentLoading}
										setInstrumentLoading={setInstrumentLoading}
										setInstruments={setInstruments}
									/>
								</ListItem>
							);
						})
						}
					</List>	
					<List subheader={<ListSubheader disableSticky={true}><div className={classes.listSubheader}>Buoni Pasto</div></ListSubheader>}>
						{instruments && !instruments.find(instrument => {
								return instrument.type === "edenred_ticket";
							}) && <ButtonBase onClick={() => history.push(`/payment/integration/edenred_ticket/add`)} className={classes.buttonBase}>
							<ListItem>
								<Card className={classes.edenredCard}>
									<div className={classes.edenredLogoContainer}>
										<img className={classes.edenredLogo} alt={'edenred'}  src={`https://images.foorban.business/payment/edenred.svg`}></img>
									</div>
									<Typography>Ticket Restaurant®</Typography>
								</Card>
							</ListItem>
						</ButtonBase>}
						<ButtonBase onClick={() => history.push(`/wallet`)} className={classes.buttonBase}>
							<ListItem>
								<Card className={classes.otherCard}>
									<Typography>Altri buoni</Typography>
								</Card>
							</ListItem>
						</ButtonBase>
					</List>
				</Container>
			</TopLevel>
		</Base>
	);
}

function InstrumentCard({
	instrument,
	instrumentLoading,
	setInstrumentLoading,
	setInstruments,
}: {
	instrument: any,
	instrumentLoading: number;
	setInstrumentLoading: Dispatch<SetStateAction<number>>;
	setInstruments: Dispatch<SetStateAction<any[] | null>>;
}) {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
		<div className={classes.details}>
			<CardContent style={{ opacity: instrumentLoading === instrument.id ? 0.5 : 1}} className={classes.content}>
				<div className={classes.labelContainer}>
					<Typography variant="h6"  gutterBottom>
						{instrument.label}
					</Typography>
					<Typography color="textSecondary" variant="body2" noWrap>
						{instrument.sublabel}
					</Typography>
				</div>
				<div className={classes.providerContainer}>
					<img alt={instrument.provider} style={{width: '80px'}} src={`https://images.foorban.business/payment/${instrument.provider.toLowerCase()}.svg`}></img>
				</div>
			</CardContent>
			<CardActions style={{opacity: instrumentLoading === instrument.id ? 0.5 : 1}} className={classes.cardAction}>
				<DeleteButton disable={instrumentLoading !== 0} notifyDelete={setInstrumentLoading} setInstruments={setInstruments} instrumentId={instrument.id} loading={instrumentLoading === instrument.id} />
			</CardActions>
			{instrumentLoading === instrument.id && <div style={{
				position: 'absolute',
				top: 0,
				bottom: 0,
				right: 0,
				left: 0,
				width: '100%',
				height: '100%',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center'
			}}>
				<CircularProgress color='primary' />
			</div>}
		</div>
	</Card>
	);
}

function DeleteButton(props: {
	notifyDelete: Dispatch<SetStateAction<number>>;
	setInstruments: Dispatch<SetStateAction<any[] | null>>;
	instrumentId: number;
	loading: boolean;
	disable: boolean;
}) {
	const [message, setMessage] = useState<string | null>(null);

	return (
		<>
		<Button disabled={props.disable || props.loading} size="small" onClick={() => {
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/payment-instrument/${props.instrumentId}/delete`, {
				method: 'PUT',
			})
				.then(result => {
					if(!result || result.message !== 'success') {
						throw new Error();
					}
					props.notifyDelete(0);
					props.setInstruments(null);
				})
				.catch(e => {
					setTimeout(() => {
						setMessage(null);
						props.setInstruments(null);
					}, 1000);
					setMessage("Errore durante l'eliminazione");
					props.notifyDelete(0);
				})
			props.notifyDelete(props.instrumentId);
		}} color="primary">ELIMINA</Button>
		{message && <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={message !== null}>
			<Alert severity="warning">
				{message}
			</Alert>
		</Snackbar>}
		</>
	)
}