import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import { createStore } from 'redux';
import rootReducer from './reducers'
import CssBaseline from '@material-ui/core/CssBaseline';

const store = createStore(rootReducer)

ReactDOM.render(
  <React.StrictMode>
    <React.Fragment>
    <CssBaseline />
    <Provider store={store}>
      <App />
    </Provider>
    </React.Fragment>
  </React.StrictMode>,
  document.getElementById('root')
);

/*
serviceWorker.register({
  onUpdate: async registration => {
    if (registration && registration.waiting) {
      await registration.unregister();
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      window.location.reload();
    }
  },
});
*/

serviceWorker.unregister();