export default function diet(state: DietPreference | null = null, action: DietAction) {
    switch (action.type) {
        case 'UPDATE_DIET_PREFERENCE':
            return action.data;
        case 'DELETE_DIET_PREFERENCE':
            return null;
        default:
            return state
    }
}

interface BaseDietAction {
    type: DietActionType;
    data?: DietPreference;
}

interface DietActionUpdate extends BaseDietAction{
    type: 'UPDATE_DIET_PREFERENCE';
    data: DietPreference;
}

interface DietActionDelete extends BaseDietAction {
    type: 'DELETE_DIET_PREFERENCE';
}

export type DietAction = DietActionUpdate | DietActionDelete;

export const DIET_ACTION_TYPE_LIST = ['UPDATE_DIET_PREFERENCE', 'DELETE_DIET_PREFERENCE'] as const;
export type DietActionType = typeof DIET_ACTION_TYPE_LIST[number];

export interface DietPreference {
    diet?: Diet;
    allergens?: Allergen;
}

export interface Allergen {
    crustaceans?: AllergensType;
    cerealsContainingGluten?: AllergensType;
    eggs?: AllergensType;
    fish?: AllergensType;
    peanuts?: AllergensType;
    soy?: AllergensType;
    milkAndDairyProducts?: AllergensType;
    fruitsInShell?: AllergensType;
    celery?: AllergensType;
    mustard?: AllergensType;
    sesame?: AllergensType;
    sulphur?: AllergensType;
    lupine?: AllergensType;
    molluscs?: AllergensType;
}

export type Allergens = keyof Allergen;

export const AllergensListLabel: { [key in Allergens]: string } = {
    cerealsContainingGluten: "Glutine",
    crustaceans: "Crostacei",
    eggs: "Uova",
    fish: "Pesce",
    peanuts: "Arachidi",
    soy: "Soia",
    milkAndDairyProducts: "Latte e prodotti caseari",
    fruitsInShell: "Frutti in guscio",
    celery: "Sedano",
    mustard: "Mostarda",
    sesame: "Sesamo",
    sulphur: "Zolfo",
    lupine: "Lupino",
    molluscs: "Molluschi"
}

export const DIET_VALUES = [
    'Contiene derivati della carne o pesce',
    'Pescetariano',
    'Vegetariano',
    'Vegano',
] as const;

export type Diet = typeof DIET_VALUES[number];

export const DietLabel: { [key in Diet]: string } = {
    'Contiene derivati della carne o pesce' : 'Mangio tutto :)',
    Pescetariano: 'Pescetariana',
    Vegetariano: 'Vegetariana',
    Vegano: 'Vegana',
}


export const ALLERGEN_TYPE_VALUES = ['Presente' ,'Tracce', 'Assente'] as const;
export type AllergensType = typeof ALLERGEN_TYPE_VALUES[number];

export type AllergensTypeKeys = keyof AllergensType;

export const AllergenTypeLabel: { [key in AllergensType]: string } = {
    Presente: "Si, lo voglio",
    Tracce: "Si, solo se ci sono possibili tracce",
    Assente: "No, non lo voglio",
}
