
import React, { useEffect }  from 'react';
import { CartPreviewComplete } from './CartPreview';
import { useSelector } from 'react-redux';
import Cart from './Cart';
import Dialog from '@material-ui/core/Dialog';
import Modal from '../../../templates/contents/Modal';
import { useLocation, useHistory } from 'react-router-dom'; 
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SellingAreaStatus } from '../state';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		paddingBottom: '240px'
	}
}));

export default () => {
	const classes = useStyles();
	const location = useLocation();
	const cart = useSelector(state => state.cart);
	const status = useSelector(state => state.status);
	let history = useHistory();

	useEffect(() => {
		if(status === SellingAreaStatus.DATA_FETCHED && location.pathname.indexOf('cart') > -1 && !cart) {
			history.goBack();
		}
	});

	return (
		<Dialog disableBackdropClick={true} disableEscapeKeyDown={true} fullScreen={true} fullWidth={true} open={location.pathname.indexOf('cart') > -1}>
			<Modal title="Il tuo ordine" loading={false} action={history.goBack}>
				<div className={classes.root}>
					<Cart title=""></Cart>
					<CartPreviewComplete></CartPreviewComplete>
				</div>
			</Modal>
		</Dialog>
	);
}