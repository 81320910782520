import React, { useState, useEffect } from 'react';
import DataProvider from '../../provider';

import { makeStyles, Theme } from '@material-ui/core/styles';
import Funnel from '../../templates/contents/Funnel';
import { useParams } from 'react-router-dom';
import CartDrawer from './cart/CartDrawer';
import {CartPreview} from './cart/CartPreview';
import CartDialog from './cart/CartDialog';

import { Provider, useSelector, useDispatch } from 'react-redux';
import { createStore } from 'redux';
import sellingAreaApp from './state/reducers';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useHistory, useLocation } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import InternalPayments from './InternalPayments';
import Banner from './Banner';
import UnsellableItemInfoPopup from './UnsellableItemInfoPopup';
import { pushEvent } from '../../utils/gtmDataLayer';

import DialogPreference from './components/DialogPreference';

import {
	cartAction,
	updateCatalog,
	updateActions,
	updateStatus,
	updateData,
	updateWalletBalance,
	updateCorporateCreditBalance,
	emptyCorporateCreditBalance,
	updateBanner,
	DietAction
} from './state/actions';

import Catalog from './Catalog';
import Feedback from './Feedback';

import { SellingAreaStatus } from './state';
import { ActionPaymentPreferences } from './state/interfaces/index';
import { LockerConfig } from './interfaces';

export const drawerWidth = 320;
  
const useStyles = (cart) => makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		display: 'flex'
	},
	container: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			paddingRight: cart ? drawerWidth : 0
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: cart ? 240 : 0
		},
	}
}));

const SellingAreaComponent = (props: any) => {
	let params = useParams();
	let location = useLocation();
	const mySearchParams = new URLSearchParams(location.search);
	let id = params['id'];
	const [ showModal, setShowModal] = useState<boolean>(false);
	const message = useSelector(state => state.message);
	const cart = useSelector(state => state.cart);
	const status = useSelector(state => state.status);
	const sellingAreaData = useSelector(state => state.data);
	const catalog = useSelector(state => state.catalog);
	const dispatch = useDispatch();
	let history = useHistory();
	const classes = useStyles(cart ? true : false)();

	const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

	useEffect(() => {
		if(!id) {
			return;
		}

		if(SellingAreaStatus.FETCH_DATA === status) {
			dispatch(updateStatus(SellingAreaStatus.FETCHING_DATA))
			dataProvider(`b2c/locker/${id}/configs`)
				.then((data: LockerConfig) => {
					dispatch(updateData({
						title: data.title
					}));

					if(data.actions) {
						dispatch(updateActions(data.actions));
					}
					if(data.catalog) {
						pushEvent({
							type: "ViewItemList",
							data: {
								...data.catalog,
								sellingPointImpulseId: id,
							},
							dataLabel: "b2cImpulseCatalog",
						});
						dispatch(updateCatalog(data.catalog));
					}

					if(data.catalog.shopStatus === 'Close') {
						return null;
					}

					if(data.order) {
						setShowModal(data.order.actionType === "awaitingPayment");
						dispatch(cartAction.updateCart(data.order, data.wallet ? data.wallet.balance : 0, data.corporateCreditWallet ? data.corporateCreditWallet.balance : 0));
					} else {
						dispatch(cartAction.emptyCart());
					}

					if(data.wallet) {
						dispatch(updateWalletBalance(data.wallet.balance));
					}
					if(!data.actions && !data.catalog) {
						dispatch(updateStatus(SellingAreaStatus.NOT_ACTIVE))
						return null;
					}

					if(data.corporateCreditWallet && data.corporateCreditWallet.balance > 0) {
						dispatch(updateCorporateCreditBalance(data.corporateCreditWallet.balance));
					} else {
						dispatch(emptyCorporateCreditBalance());
					}

					if(data.paymentPreferences) {
						const updatePreferences: ActionPaymentPreferences = {
							type: 'UPDATE_PAYMENT_PREFERECES',
							preferences: data.paymentPreferences,
						};
						dispatch(updatePreferences);
					}

					if(data.dietPreference) {
						const updateDietPreference: DietAction = {
							type: 'UPDATE_DIET_PREFERENCE',
							data: data.dietPreference,
						};
						dispatch(updateDietPreference);
					}

					dispatch(updateStatus(SellingAreaStatus.DATA_FETCHED))

					if(data.banner) {
						dispatch(updateBanner(data.banner));
					}
				})
				.catch(e => {
					if(e === 'Not Found') {
						dispatch(updateStatus(SellingAreaStatus.NOT_FOUND))
					}
					else {
						dispatch(updateStatus(SellingAreaStatus.NOT_ACTIVE))
					}
				})
			}
		
	}, [id, dataProvider, dispatch, status]);

	return (
		<Funnel rightAction={{
			icon:'person',
			onClick: () => {history.push('/me');}
		}} filter={catalog && catalog.shopStatus === "Open" ? {
			icon:'filter_list', 
			onClick: () => { 
				if(location.search.length > 0) {
					history.push(`${location.search}&diet_preference=show`)
				}
				else {
					history.push(`?diet_preference=show`)
				}
			}
		}: null} title={sellingAreaData ? sellingAreaData.title : ""} loading={SellingAreaStatus.FETCH_DATA === status}>
			<div className={classes.root}>
				<div className={classes.container}>				  
					{mySearchParams.get('mode') !== 'preview' && <InternalPayments />}
					<Banner />
					<UnsellableItemInfoPopup />
					<Catalog></Catalog>
					<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={message}>
						<Alert severity="warning">
							{message}
						</Alert>
					</Snackbar>
				</div>
				{mySearchParams.get('mode') !== 'preview' && <CartDrawer></CartDrawer>}
			</div>
			<Feedback />
			{SellingAreaStatus.DATA_FETCHED === status && <DialogPreference />}
			{mySearchParams.get('mode') !== 'preview' && <CartPreview></CartPreview>}
			{mySearchParams.get('mode') !== 'preview' && <CartDialog></CartDialog>}
			{mySearchParams.get('mode') !== 'preview' && <Dialog
        open={showModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Pagamento in corso"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Hai un pagamento in corso, scegli se continuare.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
			<Button onClick={()=> {
				dispatch(updateStatus(SellingAreaStatus.FETCHING_DATA));	
				dataProvider(`b2c/payment/${cart.paymentUUID}/cancel`)
					.then(info => {
						dispatch(updateStatus(SellingAreaStatus.FETCH_DATA));
					})
					.catch(err => {
						dispatch(updateStatus(SellingAreaStatus.FETCH_DATA));
					});
			}} color="primary">
            Modifica ordine
          </Button>
          <Button onClick={()=> history.push(`/payment/${cart.paymentUUID}`)} variant="contained" size='large' color="primary">
            Continua
          </Button>
        </DialogActions>
      </Dialog>}
		</Funnel>
	);
}

export default function SellingArea(props: any) {
	const store = createStore(sellingAreaApp);

	return <Provider store={store}>
		<SellingAreaComponent {...props}></SellingAreaComponent>
	</Provider>
}