import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import DataProvider from '../../provider';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { getKey } from '../../cross-store';
import Funnel from '../../templates/contents/Funnel';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

export enum PaymentDataStatus {
	FETCH_DATA = "fetch_data",
	FETCHING_DATA = "fetching_data",
	DATA_FETCHED = "data_fetched",
	ERROR_DATA_FETCHING = "error_data_fetching",
	FETCHING_DATA_MAX_ATTEMPT = "fetching_data_max_attempt",
}

export enum PaymentActionType {
	error = "error",
	loading = "loading",
	redirect = "redirect",
	collectPI = "collectPI",
	cancelled = "cancelled"
}

export interface B2CPayment {
	uuid: string
}

export default (props: any) => {
	const dispatch = useDispatch();
	let history = useHistory();
	let params = useParams();
	let uuid = params['uuid'];
	const [status, setStatus] = useState<PaymentDataStatus>(PaymentDataStatus.FETCH_DATA);
	const [paymentStatus, setPaymentStatus] = useState<PaymentActionType>(PaymentActionType.loading);
	const [payment, setPayment] = useState<B2CPayment | null>(null);
	const [message, setMessage] = useState<string | null>(null);
	const [retryCount, setRetryCount] = useState<number>(0);

	const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

	useEffect(() => {
		let timeout: null | NodeJS.Timeout = null;

		if(uuid && PaymentDataStatus.FETCH_DATA === status) {
			if(retryCount < 6) {
				setStatus(PaymentDataStatus.FETCHING_DATA);

				dataProvider(`b2c/payment/${uuid}`)
					.then(info => {

						setStatus(PaymentDataStatus.DATA_FETCHED);
						setPaymentStatus(info.actionType);
						setPayment(info.payment);

						switch (info.actionType) {
							case PaymentActionType.collectPI:
								let lastPIAdded = getKey('PAYMENT_PICKED_PI');
								if(lastPIAdded) {
									setStatus(PaymentDataStatus.FETCHING_DATA);

									dataProvider(`b2c/payment/${uuid}/update-pi`, {
										method: 'POST',
										body: JSON.stringify({uuid: lastPIAdded.uuid})
									})
									.then(() => {
										setStatus(PaymentDataStatus.FETCH_DATA);
									})
									.catch(e => {
										setStatus(PaymentDataStatus.ERROR_DATA_FETCHING);
									});
								}
								break;
							case PaymentActionType.redirect:
								if(info.linkAction.type === 'internal') {
									history.replace(info.linkAction.link);
								} else if(info.linkAction.type === 'external') {
									window.location.href = info.linkAction.link;
								}
								break;
							case PaymentActionType.error:
								let lastPIAddedError = getKey('PAYMENT_PICKED_PI');
								if(lastPIAddedError) {
									setStatus(PaymentDataStatus.FETCHING_DATA);

									dataProvider(`b2c/payment/${uuid}/update-pi`, {
										method: 'POST',
										body: JSON.stringify({uuid: lastPIAddedError.uuid})
									})
									.then(() => {
										setStatus(PaymentDataStatus.FETCH_DATA);
									})
									.catch(e => {
										setStatus(PaymentDataStatus.ERROR_DATA_FETCHING);
									});
								}
								if(info.message) {
									setMessage(info.message);
								}
								break;
							case PaymentActionType.loading:
								if(timeout) {
									clearTimeout(timeout);
								}
								timeout = setTimeout(() => {
									setRetryCount(retryCount + 1);
									setStatus(PaymentDataStatus.FETCH_DATA);
								}, 2000);
								break;
							case PaymentActionType.cancelled:
								break;
							default:
								if(timeout) {
									clearTimeout(timeout);
								}
								timeout = setTimeout(() => {
									setRetryCount(retryCount + 1);
									setStatus(PaymentDataStatus.FETCH_DATA);
								}, 2000);
								break;
						}
					})
					.catch(e => {
						setStatus(PaymentDataStatus.ERROR_DATA_FETCHING);
					})
			}
			else {
				setStatus(PaymentDataStatus.FETCHING_DATA_MAX_ATTEMPT);
			}
		}

		return () => {
			if(timeout) {
				clearTimeout(timeout);
			}
		}
	}, [uuid, status, retryCount, dataProvider, dispatch, history])
	
	let content: any = null;

	if(paymentStatus === PaymentActionType.error && status === PaymentDataStatus.DATA_FETCHED) {
		content = (
			<Content>
				<Text>Pagamento Fallito{message && `: ${message}`}</Text>
				<Button
					style={{marginBottom: '8px'}}
					onClick={() => {
						if(payment) {
							setStatus(PaymentDataStatus.FETCHING_DATA);
							dataProvider(`b2c/payment/${payment.uuid}/cancel`)
							.then(info => {
								setStatus(PaymentDataStatus.DATA_FETCHED);
								history.goBack();
							})
							.catch(err => {
								setStatus(PaymentDataStatus.ERROR_DATA_FETCHING);
							});
						}
						else {
							setStatus(PaymentDataStatus.FETCH_DATA);	
						}
					}}
					fullWidth={true}
					size="small"
					color="primary"
				>
					CANCELLA
				</Button>
				<Button
				onClick={() => {
					const params = new URLSearchParams();
					params.set('key-to-update', 'PAYMENT_PICKED_PI');
					params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));

					history.push(`/payment/payment-options/definedAmountMethod?${params.toString()}`, {
						key_to_update: 'PAYMENT_PICKED_PI'
					});
				}}
				fullWidth={true}
				variant="contained"
				size="large"
				color="primary"
			>
				RIPROVA PAGAMENTO
			</Button>
			</Content>
		);
	}

	if(status === PaymentDataStatus.FETCHING_DATA_MAX_ATTEMPT) {
		content = (
			<Content>
				<Text>Pagamento in corso</Text>
				<Button
					onClick={() => {
						setRetryCount(0);
						setStatus(PaymentDataStatus.FETCH_DATA);
					}}
					fullWidth={true}
					variant="contained"
					size="large"
					color="primary"
				>
					RIPROVA
				</Button>
			</Content>
		);
	}

	if(paymentStatus === PaymentActionType.cancelled && status === PaymentDataStatus.DATA_FETCHED) {
		content = <Text>PAGAMENTO CANCELLATO</Text>;
	}

	if(paymentStatus === PaymentActionType.collectPI && status === PaymentDataStatus.DATA_FETCHED) {
		content = (
			<Content>
				<Text>
					Non hai ancora associato un metodo di pagamento al tuo account.
					Aggiungilo ora!
				</Text>
				<Button
				style={{marginBottom: '16px'}}
				onClick={() => {
					const params = new URLSearchParams();
					params.set('key-to-update', 'PAYMENT_PICKED_PI');
					params.set('return-url', encodeURIComponent(window.location.pathname + window.location.search));

					history.push(`/payment/payment-options/definedAmountMethod?${params.toString()}`, {
						key_to_update: 'PAYMENT_PICKED_PI'
					});
				}}
				fullWidth={true}
				variant="contained"
				size="large"
				color="primary"
			>
				AGGIUNGI
			</Button>
			</Content>
		);
	}

	return (
		<Funnel title={"Pagamento"} loading={status === PaymentDataStatus.FETCHING_DATA || status === PaymentDataStatus.FETCH_DATA || (status === PaymentDataStatus.DATA_FETCHED && paymentStatus === PaymentActionType.loading)}>
			{content && <Container component="main" maxWidth="xs">
                {content}
            </Container>}
		</Funnel>
	);
}

function Content(prosp: any) {
    return <div style={{width: '100%', minHeight: '200px', padding: '16px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        {prosp.children}
    </div>
}

function Text(prosp: any) {
    return (<Typography style={{padding: '16px', width: '100%', textAlign: 'center'}}>
        {prosp.children}
    </Typography>)
}