import React, { useState, useEffect, Fragment } from 'react';
import DataProvider from '../provider';
import Base from '../templates/Base';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';
import TopLevel from '../templates/contents/TopLevel';
import { HomeData, HomeDataSectionType } from '../models/home';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import LinkAction, { LinkActionType } from '../components/LinkAction';

const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		display: 'flex',
		flexDirection: 'column',
		backgroundColor: 'transparent',
		margin: theme.spacing(0, 0, 2),
	},
	errorPaper: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent',
		padding: theme.spacing(2, 0),
	},
	text: {
		textAlign: 'center',
		padding: theme.spacing(16, 0, 6),
	},
	sectionText: {
		padding: theme.spacing(3, 0, 1),
	},
	cardAction: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	linkAction: {
		margin: theme.spacing(3, 0, 0),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center'
	},
	buttonBaseContainer: {
		margin: theme.spacing(0, 0, 2),
	},
	card: {
		width: '100%'
	}
}));

export enum HomeStatus {
	FETCH_DATA = "fetch_data",
	FETCHING_DATA = "fetching_data",
	DATA_FETCHED = "data_fetched",
	ERROR_DATA_FETCHING = "error_data_fetching"
}

export default function Home (props: any) {
	const [status, setStatus] = useState<HomeStatus>(HomeStatus.FETCH_DATA);
	const [homeData, setHomeData] = useState<HomeData | null>(null);
	const classes = useStyles();
	const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

	useEffect(() => {
		if(status === HomeStatus.FETCH_DATA) {	
			dataProvider(`b2c/home`)
				.then(homeData => {
					setStatus(HomeStatus.DATA_FETCHED)
					setHomeData(homeData)
				})
				.catch(e => {
					setStatus(HomeStatus.ERROR_DATA_FETCHING);
				})
		}
	}, [dataProvider, setStatus, status]);

	let content: any = null;

	if(status === HomeStatus.ERROR_DATA_FETCHING || (status === HomeStatus.DATA_FETCHED && !homeData)) {
		content = (
			<Container maxWidth="xs">
				<div className={classes.errorPaper}>
					<Typography className={classes.text}>Errore durante il caricamento</Typography> 
					<Button size='large' onClick={() => {
						setStatus(HomeStatus.FETCH_DATA)
					}} variant="contained" color="primary">
						Ricarica
					</Button>
				</div>
			</Container>
		);
	}
	else if(status === HomeStatus.DATA_FETCHED && homeData !== null) {
		content = (
			<Container component="main" maxWidth="sm">
				<div className={classes.paper}>
				{homeData.sections.map((section, key) => {
				if(section.type === HomeDataSectionType.LINK) {
					return (
						<Fragment key={key}>
							<Typography className={classes.sectionText} variant="h6">{section.data.text}</Typography> 
							<div className={classes.linkAction}>
								<LinkAction
									buttonProps={{
										size: 'large',
										variant: 'contained',
										color: 'primary'
									}}
									type={LinkActionType.Button}
									link={section.data.link}
								/>
							</div>
						</Fragment>
					)
				}
				else if(section.type === HomeDataSectionType.SHOP) {
					return (
						<Fragment key={key}>
							<Typography className={classes.sectionText} variant="h6">{section.data.title}</Typography> 
							{section.data.shops.map((shop, shopKey) => {
								return (
									<LinkAction
									buttonProps={{
										focusRipple: true
									}}
									key={shopKey}
									className={classes.buttonBaseContainer}
									type={LinkActionType.ButtonBase}
									link={shop}
								>
										<Card className={classes.card} >
										<CardContent>
										<Typography align="left" color="textSecondary" gutterBottom>
											{shop.label}
										</Typography>
										</CardContent>
										<CardActions className={classes.cardAction}>
										<LinkAction
											fake={true}
											buttonProps={{
												variant: 'text',
												color: 'primary'
											}}
											type={LinkActionType.Button}
											link={shop}
											label={"ORDINA"}
										/>
										</CardActions>
										</Card>
									</LinkAction>
								)
							})}
						</Fragment>
					)
				} else if(section.type === HomeDataSectionType.SHOP_PREVIEW) {
					return (
						<Fragment key={key}>
							<Typography className={classes.sectionText} variant="h6">{section.data.title}</Typography>
							<LinkAction
								buttonProps={{
									focusRipple: true
								}}
								className={classes.buttonBaseContainer}
								type={LinkActionType.ButtonBase}
								link={section.data.shop}
							>
								<Card className={classes.card} >
									<CardContent>
										<Typography align="left" color="textSecondary" gutterBottom>
											{section.data.shop.label}
										</Typography>
									</CardContent>
									<CardActions className={classes.cardAction}>
										<LinkAction
											fake={true}
											buttonProps={{
												variant: 'text',
												color: 'primary'
											}}
											type={LinkActionType.Button}
											link={section.data.shop}
											label={section.data.cta}
										/>
									</CardActions>
								</Card>
							</LinkAction>
						</Fragment>
					)
				}
				return null;
				})}
				</div>
			</Container>)
	}

	return (
		<Base loading={false} navigation={props.navigation}>
			<TopLevel title="Home" loading={status === HomeStatus.FETCHING_DATA}>
				{content}
			</TopLevel>
		</Base>
	);
}