import fetchJson from './httpClient';
export interface CustomOptions {
  avoidStatusChecks?: boolean;
}
export default (apiUrl: string, httpClient = fetchJson) => {
  return (resource: string, options: RequestInit = {}, customOptions?: CustomOptions) => {
    let url = `${apiUrl}/${resource}`;
    if(!options.credentials) {
      options.credentials = 'include';
    }
    return httpClient(url, options, customOptions);
  }
};