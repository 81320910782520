import { INCREMENT_PENDING_REQUEST_COUNTER, DECREMENT_PENDING_REQUEST_COUNTER } from '../';

const pendingRequestCounter = (state = 0, action) => {
	switch (action.type) {
	case INCREMENT_PENDING_REQUEST_COUNTER:
		return state + 1;
	case DECREMENT_PENDING_REQUEST_COUNTER:
		if(state === 0)
			return state;
		return state - 1;
	default:
		return state
	}
}

export default pendingRequestCounter;