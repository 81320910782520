import { ActionPaymentPreferences, PaymentPreferencesState } from '../interfaces';
import { getKey } from '../../../../cross-store';

const paymentPreferences = (state: PaymentPreferencesState = { status: 'NOT_FETCHED' }, action: ActionPaymentPreferences) => {
	switch (action.type) {
	case 'UPDATE_PAYMENT_PREFERECES':
		let paymentInstrument = getKey('SELLING_AREA_PICKED_PI');

		const state2update: PaymentPreferencesState = {
			status: 'FETCHED',
		};

		action.preferences.forEach(preference => {
			if(
				preference.paymentContext === 'definedAmountMethod' &&
				preference.type === 'pi' &&
				paymentInstrument
			) {
				preference.paymentInstrument = paymentInstrument
				state2update[preference.paymentContext] = preference;
			} else {
				state2update[preference.paymentContext] = preference;
			}
		});

		return state2update;
	default:
		return state;
	}
}

export default paymentPreferences;