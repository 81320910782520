import { SHOW_MESSAGE, REMOVE_MESSAGE } from '../';

export const showMessage = (message: string) => ({
	type: SHOW_MESSAGE,
	message
});

export const removeMessage = () => ({
	type: REMOVE_MESSAGE
});

export function show(dispatch, message: string, expire: number = 4000) {
    dispatch(showMessage(message));

    if(expire > 0) {
        setTimeout(() => {
            dispatch(removeMessage());
        }, expire)
    }
}