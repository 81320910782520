import { UPDATE_CATALOG } from '../';

const catalog = (state = null, action) => {
	switch (action.type) {
	case UPDATE_CATALOG:
		return {
			shopStatus: action.catalog.shopStatus,
			categoriesRaw: action.catalog.categories,
			itemsRaw: action.catalog.items,
			categories: action.catalog.categories.sort((a, b) => {
				if (a.displayOrder < b.displayOrder) {
					return -1;
				}
				if (b.displayOrder < a.displayOrder) {
					return 1;
				}
				return 0;
			}).map(category => {
				return {
					...category,
					items: action.catalog.items
					.filter(item => {
						return item.categoryId === category.id
					})
					.sort((a, b) => {
						if (a.displayOrder < b.displayOrder) {
							return -1;
						}
						if (b.displayOrder < a.displayOrder) {
							return 1;
						}
						return 0;
					})
				}
				
			}),
			closePhrase: action.catalog.closePhrase ? action.catalog.closePhrase : undefined,
		};
	default:
		return state
	}
}

export default catalog;