import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Hidden from '@material-ui/core/Hidden';
import { useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { useHistory, useParams } from "react-router-dom";
import Paper from '@material-ui/core/Paper';
import { drawerWidth } from '../Index';
import Typography from '@material-ui/core/Typography';
import PayButton from '../components/PayButton';
import PaymentMethods from '../components/PaymentMethods';
import { PaymentPreferencesState } from '../state/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
	rootDrawer: {
		maxWidth:`${drawerWidth}px`,
		width: '100%',
		boxShadow: "0px -13px 13px -13px rgba(0,0,0,0.29)"
	},
	rootDefault: {
		left: 0,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: "space-between",
		position: 'fixed',
		bottom: 0,
		right: 0,
		padding: theme.spacing(1, 2, 2),
		backgroundColor: theme.palette.background.default,
		zIndex: 10,
	},
	cartPreviewTitle:{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between'
	},
	cartPreviewTotal:{
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	totalTitle: {
		//marginRight: '8px'
	},
	cartPreviewDrawerTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	cartPreviewCompleteTitle: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center'
	},
	editLabel: {
		backgroundColor: 'red',
		margin: 0,
		padding: 0
	}
}));

export function CartPreviewDrawer(props: any) {
	const classes = useStyles();
	const cart = useSelector(state => state.cart);
	const totalToPayFromCorporateCredit = useSelector(state => state?.cart?.totalToPayFromCorporateCredit);
    const totalToPayFromWallet = useSelector(state => state?.cart?.totalToPayFromWallet);
    const totalToPay  = useSelector(state => state?.cart?.totalToPay);
	const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);

	if(!cart) {
		return null;
	}

	let quantity = 0;

	cart.items.forEach(cartItem => {
		quantity += cartItem.quantity
	});

	let height = 95;

	if(totalToPay > 0) {
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.partialAmountMethod && paymentPreferences.partialAmountMethod.type === 'pi') {
			height += 40;
		}
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.definedAmountMethod && paymentPreferences.definedAmountMethod.type === 'pi') {
			height += 65;
		}
	}
	if(totalToPayFromWallet > 0) {
	   height += 20;
	}
	if(totalToPayFromCorporateCredit > 0) {
		height += 20;
	}

	return (
		<Paper style={{
			height: `${height}px`,
		}} className={`${classes.root} ${classes.rootDrawer}`} square={true} elevation={0}>
			<div>
				<div className={classes.cartPreviewDrawerTitle}>
					<Typography className={classes.totalTitle} component="h6" variant="subtitle1">
						Totale ({quantity} Prodotti):
					</Typography>
					<Typography component="h6" variant="subtitle1">
						{cart.totalWithTax} €
					</Typography>
				</div>
				<PaymentMethods />
			</div>
			<PayButton></PayButton>
		</Paper>
	);
}

export function CartPreview(props: any) {
	const classes = useStyles();
	const cart = useSelector(state => state.cart);
	const totalToPayFromCorporateCredit = useSelector(state => state?.cart?.totalToPayFromCorporateCredit);
    const totalToPayFromWallet = useSelector(state => state?.cart?.totalToPayFromWallet);
    const totalToPay  = useSelector(state => state?.cart?.totalToPay);
	const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);
	const history = useHistory();
	let params = useParams();
	let id = params['id'];

	if(!cart) {
		return null;
	}

	let quantity = 0;

	cart.items.forEach(cartItem => {
		quantity += cartItem.quantity
	});

	let height = 125;

	if(totalToPay > 0) {
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.partialAmountMethod && paymentPreferences.partialAmountMethod.type === 'pi') {
			height += 40;
		}
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.definedAmountMethod && paymentPreferences.definedAmountMethod.type === 'pi') {
			height += 35;
		}
	}
	if(totalToPayFromWallet > 0) {
	   height += 20;
	}
	if(totalToPayFromCorporateCredit > 0) {
		height += 20;
	}

	return (
		<Hidden only={['md', 'lg', 'xl']}>
			<Paper style={{
			height: `${height}px`,
		}} className={`${classes.root} ${classes.rootDefault}`} square={true} elevation={24}>
				<div className={classes.cartPreviewTitle}>
					<Typography component="h5" variant="subtitle1">
						Il tuo ordine
					</Typography>
					<Button color="primary" style={{margin: 0, padding: 0}} size='small' onClick={() => history.push(`/selling-area/${id}/cart`)}>Modifica</Button>   
				</div>
				<div>
					<div className={classes.cartPreviewTotal}>
						<Typography className={classes.totalTitle} component="h6" variant="subtitle1">
							Totale ({quantity} Prodotti):
						</Typography>
						<Typography component="h6" variant="subtitle1">
							{cart.totalWithTax} €
						</Typography>
					</div>
					<PaymentMethods isPreview={true} />
				</div>
				<PayButton></PayButton>
			</Paper>
		</Hidden>
	);
}

export function CartPreviewComplete(props: any) {
	const classes = useStyles();
	const cart = useSelector(state => state.cart);
	const totalToPayFromCorporateCredit = useSelector(state => state?.cart?.totalToPayFromCorporateCredit);
    const totalToPayFromWallet = useSelector(state => state?.cart?.totalToPayFromWallet);
    const totalToPay  = useSelector(state => state?.cart?.totalToPay);
	const paymentPreferences: PaymentPreferencesState = useSelector(state => state.paymentPreferences);

	if(!cart) {
		return null;
	}

	let quantity = 0;

	cart.items.forEach(cartItem => {
		quantity += cartItem.quantity
	});
	let height = 95;

	if(totalToPay > 0) {
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.partialAmountMethod && paymentPreferences.partialAmountMethod.type === 'pi') {
			height += 40;
		}
		if(paymentPreferences.status === 'FETCHED' && paymentPreferences.definedAmountMethod && paymentPreferences.definedAmountMethod.type === 'pi') {
			height += 65;
		}
	}
	if(totalToPayFromWallet > 0) {
	   height += 20;
	}
	if(totalToPayFromCorporateCredit > 0) {
		height += 20;
	}
	return (
		<Paper style={{
			height: `${height}px`,
		}} className={`${classes.root} ${classes.rootDefault}`} square={true} elevation={24}>
			<div>
				<div className={classes.cartPreviewCompleteTitle}>
					<Typography className={classes.totalTitle} component="h6" variant="subtitle1">
						Totale ({quantity} Prodotti):
					</Typography>
					<Typography component="h6" variant="subtitle1">
						{cart.totalWithTax} €
					</Typography>
				</div>
				<PaymentMethods />
			</div>
			<PayButton></PayButton>
		</Paper>
	);
}