import React from 'react';
import AppBarMU from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import { useHistory } from 'react-router-dom';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Icon from '@material-ui/core/Icon';

const useStyles = makeStyles((theme) => ({
	title: {
		flexGrow: 1
	},
	button: {
		margin: theme.spacing(0, 0, 0, 1)
	}
}));

function AppBar(props: any) {
	const classes = useStyles();
	let history = useHistory();

	return (
		<AppBarMU position={props.position}>
			<Toolbar
				disableGutters={props.disableGutters}>
				{props.enableBack && <IconButton className={classes.button} color="inherit" onClick={history.goBack}>
					<ArrowBackIosIcon />
				</IconButton>}
				<Typography className={classes.title} variant="h6" noWrap>{props.title}</Typography>
				{props.rightAction && <IconButton className={classes.button} color="inherit" onClick={props.rightAction.onClick ? props.rightAction.onClick : null}>
					{props.rightAction.icon ? <Icon>{props.rightAction.icon}</Icon> : null}
				</IconButton>}
				{props.filter && <IconButton className={classes.button} color="inherit" onClick={props.filter.onClick ? props.filter.onClick : null}>
					{props.filter.icon ? <Icon>{props.filter.icon}</Icon> : null}
				</IconButton>}
			</Toolbar>
		</AppBarMU>
	);
}

AppBar.defaultProps = {
	disableGutters: false,
	enableBack: false,
	rightAction: null,
	position: 'static'
}

export default AppBar;