import React, { useEffect, useState } from 'react';
import Base from '../templates/Base';

import { makeStyles, Theme } from '@material-ui/core/styles';
import TopLevel from '../templates/contents/TopLevel';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import DataProvider from '../provider';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import QrCode from '../components/QrCode';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CodeIcon from '@material-ui/icons/Code';
import { pushTrackingEvent } from '../utils/gtmDataLayer';

const StatusList = [
	'AWAIT_DATA',
	'AWAIT_CODE',
	'FETCH_DATA',
	'FETCHING_DATA',
	'ERROR_CODE',
	'ERROR_FETCHING_DATA',
	'CODE_OBLIGATORY',
	'CODE_NOT_FOUND',
] as const;

type Status = typeof StatusList[number];

 
const useStyles = makeStyles((theme: Theme) => ({
	paper: {
		width: '100%',
		height: '100%',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: 'transparent',
		'@media (min-width:400px)': {
			padding: theme.spacing(2),
			maxWidth: '400px'
		},
		'@media screen and (max-width: 400px)': {
			padding: theme.spacing(0, 0, 2, 0),
			maxWidth: '400px'
		}
	},
	divider: {
		padding: theme.spacing(2, 0)
	},
	input: {
		maxWidth: '300px',
		padding: theme.spacing(1, 0, 2)
	}
}));

export default function ScanCode (props: any) {
	const params = useParams<{
		code?: string
	}>();
	const [status, setStatus] = useState<Status>("AWAIT_DATA");
	const classes = useStyles();
	const history = useHistory();
	const [code, setCode] = useState<string>(params['code'] || '');
	const location = useLocation();

	useEffect(() => {
		if (status === 'FETCH_DATA') {
			setStatus('FETCHING_DATA');
			const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');

			dataProvider(`b2c/code/${code}`)
				.then((actionData: {
					id?: string;
					label?: string;
					short_label?: string;
					link: string;
					type: "internal" | "external";
				}) => {
					if (!actionData) {
						throw new Error('invalid action data 001');
					}

					const locationParams = new URLSearchParams(location.search);
					const utm_medium = locationParams.get('utm_medium');
					const codeSearchParam = new URLSearchParams();

					if(utm_medium) {
						codeSearchParam.append('foorban_code_landing_medium', utm_medium === 'nfc' ? 'nfc' : 'qr-code');
					}

					if(actionData.type === 'external') {
						if(actionData.link.indexOf("foorban.business/code-landing") !== -1 || actionData.link.indexOf("foorban.business/code") !== -1){
							throw new Error('invalid code 002');
						}
						let actionDataUrl: null | URL = null;
						try {
							actionDataUrl = new URL(actionData.link);
						} catch {}

						if(actionDataUrl && Array.from(actionDataUrl.searchParams).length > 0) {
							window.location.href = `${actionData.link}${Array.from(codeSearchParam).length > 0 ? `&${codeSearchParam.toString()}` : ''}`
						} else {
							window.location.href = `${actionData.link}${Array.from(codeSearchParam).length > 0 ? `?${codeSearchParam.toString()}` : ''}`
						}
					} else if(actionData.type === "internal") {
						if(actionData.link.indexOf("code-landing") !== -1 || actionData.link.charAt(0) !== "/"){
							throw new Error('invalid code 003');
						}
						const internalUrl = `${actionData.link}${Array.from(codeSearchParam).length > 0 ? `?${codeSearchParam.toString()}` : ''}`;
						if(params.code) {
							history.replace(internalUrl);
						} else {
							history.push(internalUrl);
						}		
					} else {
						throw new Error('invalid action data 004');
					}
				}).catch((e) => {
					if(e && e.error === 'Not Found') {
						setStatus("CODE_NOT_FOUND")
					} else {
						e && pushTrackingEvent({
							type: "TrackingEvent",
							data: {
								category:"Error", action: props.private ? "Code" : "CodeLanding", label: e.toString(),
							}
						});
						if(e && e.message && ['invalid code 002', 'invalid code 003'].includes(e.message)) {
							setStatus("ERROR_CODE");
						} else {
							setStatus("ERROR_FETCHING_DATA")
						}
					}
				})
		}
		if (status === "AWAIT_DATA") {
			if(code) {
				setStatus("FETCH_DATA")
			} else  {
				setStatus("AWAIT_CODE")
			} 
		}
	}, [code, history, location.search, params, props.private, status]);

	const content = (
		<TopLevel title="Lettore QR-Code" loading={["AWAIT_DATA", "FETCH_DATA", "FETCHING_DATA"].includes(status)}>
			<div className={classes.paper}>
				<QrCode goTo={(link: string) => {
					setCode(link);
					setStatus("FETCH_DATA")
				}}/>
				<Typography className={classes.divider}>Inserisci il codice manualmente</Typography>
				<TextField
					className={classes.input}
					fullWidth
					value={code}
					error={["ERROR_CODE", "CODE_OBLIGATORY", "ERROR_FETCHING_DATA", "CODE_NOT_FOUND"].includes(status)}
					helperText={{
						ERROR_CODE: "Codice non valido",
						CODE_OBLIGATORY: "Codice obbligatorio",
						ERROR_FETCHING_DATA: "Errore durante la richiesta",
						CODE_NOT_FOUND: "Codice non trovato",
					}[status]}
					variant="outlined"
					placeholder="Codice"
					onChange={(event) => {
						setCode(event.target.value);
						setStatus("AWAIT_CODE")
					}}
					InputProps={{
						startAdornment: (
							<InputAdornment position="start">
								<CodeIcon />
							</InputAdornment>
						)
					}}
				/>
				<Button
					color="primary"
					variant="outlined"
					onClick={() => {
						if(!code) {
							setStatus("CODE_OBLIGATORY")
						} else {
							setStatus("FETCH_DATA")
						}
				}}>Conferma</Button>
			</div>
		</TopLevel>
	);

	if(!props.private) {
		return content;
	}

	return (
		<Base loading={false} navigation={props.navigation} >
			{content}
		</Base>
	);
}