import React  from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import ProductCardCart from  '../product/ProductCardCart';

const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(1)
	}
}));

const Cart = (props: CartProps) => {
	const classes = useStyles();
	const cart = useSelector(state => state.cart);

	if(!cart)
		return null;

	return (
		<div className={classes.container}>
			{props.title && <Typography color="primary" variant="h5">{props.title}</Typography>}
			{cart.items.map((cartItem) => {
				return <ProductCardCart item={cartItem} key={`cart-item-${cartItem.id}`}></ProductCardCart>
			})}
		</div>
	);
}

Cart.defaultProps = {
	title: 'Il tuo ordine'
} as CartProps;

export interface CartProps {
	title?: string
}

export default Cart;