import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import Base from '../templates/Base';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Icon from '@material-ui/core/Icon';
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";

import { getAuth, signOut } from "firebase/auth";
import DataProvider from '../provider';

import FoorbaSubheader from '../components/FoorbaSubheader';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			[theme.breakpoints.down('xs')]: {
				paddingLeft: 0,
			}
		},
		version: {
			position: 'absolute',
			margin: theme.spacing(1, 1, 0),
			bottom: theme.spacing(1)
		}
	})
);

function Me(props: any) {
	const classes = useStyles();
	const history = useHistory();
	if (isWidthUp('sm', props.width)) {
		history.replace('/');
		return null;
	}

	return (
		<Base loading={false} navigation={props.navigation} >
			<List className={classes.root} subheader={<FoorbaSubheader mobile={true} subTitle={props.navigation.user.email}></FoorbaSubheader>}>
			{false && <ListItem button onClick={() => {
				  console.log('here');
			  }}>
                <ListItemIcon><Icon>get_app</Icon></ListItemIcon>
                <ListItemText primary={"Scarica l’app"} />
            </ListItem>}
            {props.navigation.links.map((link, index) => (
              <ListItem button key={link.id} onClick={() => {
				if(link.type === 'internal') {
					history.push(link.link);
				}
				else {
					window.location.href = link.link;
				}
			  }}>
                <ListItemIcon><Icon>{link.icon}</Icon></ListItemIcon>
                <ListItemText primary={link.label} />
              </ListItem>
            ))}
			<ListItem button onClick={() => {
				const auth = getAuth();
				signOut(auth)
					.then(() => {
						const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
						return dataProvider(`b2c/auth/logout`);
					})
					.then(() => {
						localStorage.removeItem('token');
						history.replace('/login');
					})
					.catch(() => {})
			  }}>
                <ListItemIcon><Icon>exit_to_app</Icon></ListItemIcon>
                <ListItemText primary={"Logout"} />
            </ListItem>
			<ListItem>
			</ListItem>
          </List>
		  <small className={classes.version}>version 0.0.1<span style={{color: process.env.REACT_APP_ENV === 'production' ? 'white': 'green'}}> {process.env.REACT_APP_ENV}</span></small>
		</Base>
	);
}
export default withWidth()(Me);