import React from 'react';
//import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		flex: 1,
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'center',
		height: '100vh'
	},
	paper: {
		paddingTop: theme.spacing(8),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		backgroundColor: 'transparent'
	},
	loading: {
		color: theme.palette.primary.main
	},

}));

export default function FullPageLoading(props: any) {
    const classes = useStyles();

	return (
		<div className={classes.root}>
			<Container component="main" maxWidth="xs" >
				<div className={classes.paper}>
                    <CircularProgress className={classes.loading}/>
				</div>
			</Container>
		</div>
	);
}