import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DataProvider from '../../provider';
import { CoffeeSellingOrder } from './interfaces';
import Funnel from '../../templates/contents/Funnel';
import Container from '@material-ui/core/Container';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Link from '@material-ui/core/Link';

const StatusList = [
    'FETCH_DATA',
    'FETCHING_DATA',
    'ERROR_FETCHING_DATA',
    'FETCHED',
] as const;

type Status = typeof StatusList[number];

const LoadingStatus: Status[] = [
    'FETCH_DATA',
    'FETCHING_DATA',
];

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        margin: theme.spacing(2, 0, 0),
    },
    textError: {
        margin: theme.spacing(2, 0, 0),
    },
    row2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    row2Button: {
        width: '100%',
        margin: theme.spacing(2),
    },

    cardContainer: {
        padding: theme.spacing(2, 2),
    },
	card: {
		width: '100%'
	},
    cardRow: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
        width: '100%',
    },
    orderHeader: {
        margin: theme.spacing(0, 0, 2),
    },
    feedBack: {
        margin: theme.spacing(2, 0),
    }
}));

export default function SellingArea(props: any) {
    const history = useHistory();
    const [status, setStatus] = useState<Status>('FETCH_DATA');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [sellingOrder, setSellingOrder] = useState<CoffeeSellingOrder>();
    const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
    const classes = useStyles();

    const { id } = useParams<{
        id: string;
    }>();
    let location = useLocation();
    const mySearchParams = new URLSearchParams(location.search);
    
    useEffect(() => {
        switch (status) {
            case 'FETCH_DATA':
                setStatus('FETCHING_DATA');
                dataProvider(`b2c/coffee/selling-order/${id}/info`)
                    .then((response: {
                        error: true;
                        message: string;
                    }
                        | {
                            error: false;
                            order: CoffeeSellingOrder;
                        }) => {
                        if (response.error === true) {
                            setStatus('ERROR_FETCHING_DATA');
                            setErrorMessage(response.message || '');
                            return;
                        }
                        setSellingOrder(response.order)
                        setStatus('FETCHED');
                    })
                    .catch(e => {
                        setStatus('ERROR_FETCHING_DATA')
                    })
                break;
        }
        return () => { }
    }, [dataProvider, id, status, history])

    let title = 'Caricamento dati...';
    let feedBack1 = "Qualcosa è andato storto, ti abbiamo rimborsato il tuo pagamento.";
    let fakeStatus = '';

    if (status === 'FETCHED' && sellingOrder) {
        if(['draft', 'dispensing'].includes(sellingOrder.status || '') && mySearchParams.get('mode') !== 'feedback') {
            fakeStatus = 'confirmed'
        } else {
            fakeStatus = sellingOrder.status || '';
        }
        switch (fakeStatus) {
            case 'draft':
            case 'dispensing':
                title = 'Ordine in corso';
                feedBack1 = 'La macchina del caffè sta impiegando più tempo del previsto a ricevere il tuo ordine.';
                break;
            case 'completed':
            case 'confirmed':
                title = 'Ordine completato';
                feedBack1 = "Ordine inviato con il tuo nome alla macchina del caffé. Aggiungi la tazza e quando è il tuo turno clicca il bottone \"Play\" per continuare l'erogazione."
                break;
            case 'error':
            case 'failed':
                title = 'Ordine in errore';
                break;
            case 'expired':
                title = 'Ordine scaduto';
                break;
            case 'cancelled':
                title = 'Ordine cancellato';
                break;
            default:
                title = 'Ordine in errore';
                break;
        }
    }  else if (status === 'ERROR_FETCHING_DATA') {
        title = 'Errore caricamento dati';
    }

    return (
        <Funnel title={title} loading={LoadingStatus.includes(status)}>
            <Container className={classes.container} maxWidth="xs">
                {status === 'ERROR_FETCHING_DATA' &&
                    <div>
                        <Typography align="center" className={classes.textError} variant="subtitle1" noWrap>{errorMessage || "Errore durante il caricamento dei dati."}</Typography>
                        <div className={classes.row2}>
                            <Button className={classes.row2Button} variant="outlined" onClick={history.goBack}>indietro</Button>
                            <Button className={classes.row2Button} variant="contained" color="primary" onClick={() => {
                                setStatus('FETCH_DATA');
                                setErrorMessage('');
                            }}>ricarica</Button>
                        </div>
                    </div>
                }

                {status === 'FETCHED' && <Typography align="center" className={classes.feedBack} variant="subtitle1">{feedBack1}</Typography>}

                {status === 'FETCHED' && mySearchParams.get('mode') !== 'feedback' && ['completed', 'confirmed'].includes(fakeStatus) && <Card className={classes.card} >
                    <CardContent>
                        <Typography className={classes.orderHeader} color='primary' align='center' gutterBottom>
                        Dettaglio ordine
                        </Typography>
                        <div className={classes.cardRow}>
                            <Typography style={{flex: 1}} variant="subtitle1" gutterBottom>
                                {sellingOrder?.label}    
                            </Typography>
                        </div>
                    </CardContent>
                </Card>}
                {status === 'FETCHED' && <div style={{marginTop: '32px', width: '100%', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    {['completed',
                        'confirmed',
                        'error',
                        'failed',
                        'expired',
                        'cancelled',
                    ].includes(fakeStatus) && <Button variant="contained" color="primary" onClick={() => {
                        history.push(`/coffee/selling-area/${sellingOrder?.coffeeSellingPointId}`);
                    }}>{['completed',
                        'confirmed'
                    ].includes(fakeStatus) ? "Acquista un altro caffè" : "Riprova l'acquisto"}</Button>}
                    {[
                        'draft',
                        'dispensing'
                    ].includes(fakeStatus) && <Button variant="contained" color="primary" onClick={() => {
                        setStatus('FETCH_DATA');
                    }}>RICARICA PAGINA</Button>}
                </div>}
    
                {mySearchParams.get('mode') !== 'feedback' && <div style={{marginTop: '32px', width: '100%', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Button variant="text" color="primary" onClick={() => {
                        setStatus('FETCH_DATA');
                        history.push('?mode=feedback');
                    }}>Ho bisogno di aiuto</Button>
                </div>}

                {mySearchParams.get('mode') === 'feedback' && <div style={{marginTop: '32px', width: '100%', display: 'flex', flex: 1, alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <Typography variant="body2"> <Link underline='always' href="https://www.foorban.com/supporto" color="inherit">Hai ancora problemi? Contattaci</Link></Typography>
                </div>}

            </Container>
        </Funnel>
    );
}