import React from 'react';
import Typography from '@material-ui/core/Typography';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ProductCard from './product/ProductCard';
import { useSelector } from 'react-redux';
import withWidth, { isWidthDown } from '@material-ui/core/withWidth';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { SellingAreaState, DietPreference } from './state';
import ChefRecommends from './components/ChefRecommends';
const useStyles = makeStyles((theme: Theme) => ({
	categoryTitle: {
		padding: theme.spacing(3, 0),
	},
	container: {
		padding: theme.spacing(0, 3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 2),
		}
	},
	categoriesContainer: {
		width: '100%'
	},
	closeButton: {
		position: 'absolute',
		right: theme.spacing(0),
		top: theme.spacing(0),
		padding: theme.spacing(1)
	},
}));

function Catalog(props: any) {
	let params = useParams();
	let id = params['id'];
	const classes = useStyles();
	const catalog = useSelector(state => state.catalog);
	let location = useLocation();
	const history = useHistory();
	const mySearchParams = new URLSearchParams(location.search);
	const diet: DietPreference | null = useSelector((state: SellingAreaState) => state.diet);

	return (
		<div className={classes.container}>
			<ChefRecommends categories={catalog?.categories} lockerId={id}/>
			{catalog && catalog.categories.map(category => {
				return (
					<div className={classes.categoriesContainer} key={category.id}>
						<Typography variant="h5" color="inherit" className={classes.categoryTitle}>
							{category.label.toUpperCase()}
						</Typography>

						<Grid container spacing={2}>
							{category.items
								.map((item) => (
									<ProductCard
										showButton={true}
										showPrice={true}
										key={item.id}
										item={item}
										lockerId={id}
										label={item.price.promoLabel ? item.price.promoLabel : item.tag}
  										labelColor={item.price.promoLabel ? '#E85414' : '#086d40'} 
										/>
								))
							}
						</Grid>
					</div>
				)
			})}
			{catalog && catalog.shopStatus === "Open" && !diet && <Box marginTop={2} marginBottom={2} textAlign={'center'}>
				<Typography gutterBottom>Evidenzia i prodotti in base alle tue preferenze!</Typography>
				<Button onClick={() => {
					if(location.search.length > 0) {
						history.push(`${location.search}&diet_preference=show`)
					}
					else {
						history.push(`?diet_preference=show`)
					}
					}} color="primary" variant="outlined">
							Scegli le tue preferenze
					</Button>
			</Box>}
			<Dialog
				fullScreen={isWidthDown('xs', props.width)}
				open={mySearchParams.get('mode') === 'preview' && mySearchParams.get('leave') === 'need-confirm'}
				onClose={history.goBack}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<IconButton aria-label="close" color="primary" className={classes.closeButton} onClick={history.goBack}>
					<CloseIcon />
				</IconButton>
				<DialogTitle id="alert-dialog-title">
					{"Sei davanti un Foorban Fridge?"}
				</DialogTitle>
				<MuiDialogContent>
					<DialogContentText id="alert-dialog-description">
						Per acquistare vai davanti al fridge e scannerizza il qr code.
					</DialogContentText>
				</MuiDialogContent>
				<DialogActions>
					<Button color="primary" variant="contained" onClick={() => history.replace('/code')}>Sono davanti il Foorban Fridge</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

export default withWidth()(Catalog);