import React, { useState, useEffect, useRef } from 'react';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import ProductCard from './product/ProductCard';
import DataProvider from '../../provider';
import { CatalogItemsResponse, CatalogCategory, CatalogItem, DietType } from '../menu/interfaces';

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: '100%',
		display: 'flex'
	},
	categoryTitle: {
		padding: theme.spacing(3, 0),
	},
	container: {
		padding: theme.spacing(0, 3),
		[theme.breakpoints.down('xs')]: {
			padding: theme.spacing(0, 2),
		}
	},
	categoriesContainer: {
		width: '100%'
	},
	container2: {
		width: '100%',
		[theme.breakpoints.up('md')]: {
			paddingRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			paddingRight: 0
		},
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 0
		},
	}
}));

export default function Catalog(props: any) {
	const classes = useStyles();
	let { UUID } = useParams<any>();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(false);
	const [menuData, setMenuData] = useState<null | CatalogItemsResponse>(null);
	const ref = useRef(null);

	useEffect(() => {
		if (!UUID) {
			setError(true);
			return;
		}
		const dataProvider = DataProvider(process.env.REACT_APP_WAREHOUSE_API || '');
			dataProvider(`b2c/menu/uuid/${UUID}`)
				.then(menuData => {
					setLoading(false)
					setMenuData(menuData)
					setTimeout(() => {
						if (window.self !== window.top && ref !== null && ref.current && window.top && process.env.REACT_APP_B2C_SITE) {
							const current = ref.current;
							window.top.postMessage && window.top.postMessage(
								JSON.stringify({
								  error: false,
								  height: current && current['clientHeight']
								}),
								process.env.REACT_APP_B2C_SITE
							  );
						}
					}, 0)
				})
				.catch(e => {
					setError(false);
					setError(true);
				})
	}, [setLoading, UUID]);

	return (
		<div ref={ref} className={classes.root}>
			<div className={classes.container2}>
			{error && <Container maxWidth="sm">
				<Typography style={{margin: '16px 0'}}align="center">Errore di caricamento, riprova. Se sei in modalità incognito, esci da questa modalità per visualizzare il menu.</Typography>
			</Container>}
				<div className={classes.container}>
					{!loading && menuData && menuData.categories
						.sort((a, b) => {
							if (a.displayOrder < b.displayOrder) {
								return -1;
							}
							if (b.displayOrder < a.displayOrder) {
								return 1;
							}
							return 0;
						}
						).map((category: CatalogCategory) => {
							const _items = menuData.items
								.filter((item: CatalogItem) => {
									return item.categoryId === category.id
								})
								.filter((item: CatalogItem) => {
									return item.menuDisplayType !== 'NO'
								})
								.sort((a: any, b: CatalogItem) => {
									if (a.displayOrder < b.displayOrder) {
										return -1;
									}
									if (b.displayOrder < a.displayOrder) {
										return 1;
									}
									return 0;
								});
							if (_items.length === 0)
								return null;

							return (
								<div className={classes.categoriesContainer} key={category.id}>
									<Typography variant="h5" color="inherit" className={classes.categoryTitle}>
										{category.label.toUpperCase()}
									</Typography>

									<Grid container spacing={2}>
										{_items.map((item: CatalogItem) => {
											return <ProductCard
												label={item.edible && item.edible.diet !== DietType["Contiene derivati della carne o pesce"] ? `${item.edible.diet.toUpperCase()}` : ''}
												showButton={false}
												showPrice={false}
												key={item.id}
												item={item}
												lockerId={UUID} />
										})
										}
									</Grid>
								</div>
							)
						})}
				</div>
			</div>
		</div>
	);
}