import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {Typography} from '@material-ui/core';


const useStylesLabelsCard = makeStyles((theme: Theme) => ({
    container: {
        bottom: '118px',
        width: "100%",
        flexDirection: 'column',
        justifyContent: "center",
        alignItems: "center",
        position: 'absolute',
        display: 'flex',
        pointerEvents: 'none',
        boxShadow: "inset 0 -4px 0 #ff0000"
    },
    chip: {
        fontWeight: 700,
        borderRadius: '4px 4px 0px 0px',
        backgroundColor: '#ff0000',
        color: 'white',
        fontSize: '0.9rem',
        textAlign: "center",
        padding: "0px 4px 0px 4px",
        [theme.breakpoints.down('xs')]: {
			fontSize: '0.5rem',
		},
   }
}));

interface IProps {
    label: string;
}

export default function LabelPreference(props : IProps) {
    const classes = useStylesLabelsCard();

    return (
        <div className={classes.container}>
            <Typography className={classes.chip}
                >{props.label.toUpperCase()}
            </Typography>
        </div>
    )
}